<template>
  <div class="stream-chat">
    <ul v-if="handUp.length" class="list-unstyled handup-messages">
      <li v-for="item in handUp" :key="item.uid">
        <div>{{ item.fullName }}</div>
        <ul class="list-inline list-unstyled buttons-wrapper">
          <li>
            <b-button class="ignore" @click="handUpIgnore(item)">Ignore</b-button>
          </li>
          <li>
            <b-button
              v-if="acceptHandUpAllowed"
              class="accept"
              @click="handUpAccepted(item)"
            >
              Bring to Stage
            </b-button>
          </li>
        </ul>
      </li>
    </ul>
    <div class="messages-wrapper" ref="messagesContainer">
      <ul
        v-if="isChatAvailable && chat.length"
        class="list-unstyled chat-messages"
      >
        <li v-for="item in chat" :key="item.time + item.uid">
          <div
            class="name-badge"
            :class="
              user.nid === item.uid
                ? 'my'
                : item.uid === session.artistUserId
                ? 'artist'
                : ''
            "
          >
            {{ item.fullName | nameToChars }}
          </div>
          <div class="message">
            <p class="name">
              <span class="username">{{ item.fullName }}</span>
              <span v-if="item.uid === session.artistUserNid" class="artist-badge">Artist</span>
            </p>
            <p class="text">{{ item.text }}</p>
          </div>
        </li>
      </ul>
      <p class="chat-empty-state" v-if="isChatAvailable && !chat.length">
        Jump in and start chatting!
      </p>
    </div>
    <div class="input-wrapper">
      <b-textarea
        v-if="isChatAvailable"
        :disabled="!canSendMessages"
        maxlenght="300"
        placeholder="Message"
        no-resize
        rows="1"
        v-model="message"
        v-maxchars="300"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="sendChatMessage"
        @keydown.enter.shift.exact="newMessageLine"
      />
      <b-button
        class="submit"
        v-if="isChatAvailable"
        :disabled="!canSendMessages"
        @click="sendChatMessage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {ChannelEventType} from "@/shared/enums";
import {permissions} from "@/mixins/permissions";
export default {
  name: "StreamChat",
  mixins: [permissions],
  props: {
    isChatAvailable: {
      type: Boolean,
    },
    canSendMessages: {
      type: Boolean,
    },
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "chat",
      "handUp",
      "session",
      "isStreamLive",
      "streams",
    ]),
    acceptHandUpAllowed() {
      return this.$agora.maxLearnersAllowed() - this.streams.length > 0;
    },
  },
  watch: {
    chat() {
      this.$nextTick(() => this.scrollToEnd());
    },
  },
  methods: {
    ...mapActions(["removeHandUp"]),
    scrollToEnd() {
      const content = this.$refs.messagesContainer;
      content.scrollTop = content.scrollHeight;
    },
    handUpIgnore(item) {
      if (item.uid != this.user.nid) {
        this.$agora.sendInstantMessage(item.uid, { type: 'HAND_UP_IGNORED' });
      } else {
        this.$agora.$eventBus.$emit('set-hand-down');
      }
      this.$agora.sendChannelMessage({ type: ChannelEventType.HAND_UP_IGNORED_CHANNEL, payload: item });
      this.removeHandUp(item);
    },
    handUpAccepted(item) {
      if (item.uid == this.user.nid) {
        this.$agora.publishStream();
      } else {
        this.$agora.sendInstantMessage(item.uid, { type: "HAND_UP_ACCEPTED" });
      }
      this.$agora.sendChannelMessage({ type: ChannelEventType.HAND_UP_IGNORED_CHANNEL, payload: item })
      this.removeHandUp(item);
    },
    sendChatMessage() {
      if (!this.message.trim() || !this.canSendMessages) {
        return;
      }
      this.$agora.sendChannelMessage({
        type: ChannelEventType.CHAT_MESSAGE,
        text: this.message.substr(0, 300),
      });
      this.message = "";
    },
  },
};
</script>
