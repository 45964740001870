<template>
  <div
      class="subscription-banner"
      :class="{ active: isActive, current: isCurrent }"
  >
    <div v-if="isCurrent" class="current-plan">
      Active
    </div>
    <h4><strong>{{ planName }}</strong></h4>
    <h5>{{ price }}</h5>
    <p v-if="discount">
      You are currently receiving a
      <strong>discount of {{ discount }}</strong> on the above price
    </p>
    <p>One month free trial before you pay anything!</p>
  </div>
</template>

<script>
  export default {
    name: 'Plan',
    props: {
      isActive: {
        type: Boolean,
      },
      isCurrent: {
        type: Boolean
      },
      discount: String,
      planName: String,
      price: String,
    },
  }
</script>
