import {allowAuthenticated, allowEmailVerified} from "./base/accesses";
import {api} from "../services/api";
import {security} from "../services";

export default () => {
    const authFailed = allowAuthenticated();
    if (authFailed) {
        return authFailed;
    }
    const emailVerificationFailed = allowEmailVerified();
    if (emailVerificationFailed) {
        return api.auth.me().then(({data}) => {
            const user = security.setUserResponse(data);
            if (user && user.isEmailVerified) {
                return {name: 'upcoming'};
            } else {
                return {name: 'verification-email'};
            }
        });
    }
};
