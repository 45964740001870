<template>
    <div class="auth-view auth-register">
        <div class="auth-page-bg">
            <div class="auth-container">
                <div class="auth-wrapper">
                    <validation-observer ref="validationObserver">
                        <b-form @submit.prevent="submitForm">
                            <b-form-group>
                                <label for="form-input-fullName">Full Name</label>
                                <validation-provider vid="fullName"  rules="required|max:256" v-slot="{classes, errors}" mode="eager" name="Full Name">
                                    <b-input
                                        v-model.trim="form.fullName"
                                        id="form-input-fullName"
                                        class="mb-4 mr-sm-2"
                                        :class="classes"
                                    ></b-input>
                                    <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group>
                                <label for="form-input-password">Password</label>
                                <validation-provider vid="password" rules="required" v-slot="{classes, errors}" mode="eager" name="Password">
                                    <b-input
                                        type="password"
                                        v-model="form.password"
                                        id="form-input-password"
                                        class="mb-4 mr-sm-2"
                                        :class="classes"
                                    ></b-input>
                                    <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                                </validation-provider>
                            </b-form-group>
                            <p class="text-danger" v-show="error">{{ error }}</p>
                            <b-button size="lg" variant="blue" type="submit" class="semibold" :disabled="apiRequesting">Create Account</b-button>
                            <validation-provider vid="accepted" v-slot="{classes, errors}" mode="eager" name="Password">
                                <p class="error-msg relative-error-mgs" :state="errors.length == 0">{{ errors[0] }}</p>
                                <b-form-group>
                                    <b-form-checkbox
                                        class="mt-30"
                                        v-model="form.accepted"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        By signing up, I agree the 2GTHR <router-link target='_blank' :to="{name: 'privacy-policy'}">Privacy Policy</router-link> and <router-link target='_blank' :to="{name: 'terms-of-service'}">Terms of Service</router-link>
                                    </b-form-checkbox>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </validation-observer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {api} from '@/services'
import {mapGetters} from 'vuex'
import firebase from 'firebase/app';
import 'firebase/auth';

import {security} from "@/services";

export default {
    name: 'EmailRegister',
    props: ['email'],
    data () {
        return {
            form: {
                fullName: "",
                email: "",
                password: "",
                accepted: false,
            },
            tab: "",
            error: null
        };
    },
    computed: {
        ...mapGetters([
            'apiRequesting'
        ]),
    },
    methods: {
        async submitForm() {
            this.error = null;
            const isValid = await this.$refs.validationObserver.validate();
            if (!isValid) {
                return;
            }
          api.auth.register(this.form).then((response) => {
            return firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                  return firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
                })
                .then(() => {
                  security.setUserResponse(response.data);
                  this.$bag.setItem('registered', 1);
                  this.$analytics.dataLayerPush({
                    event: 'Create Account',
                    category: 'Subscribe-Music',
                    action: 'Create Account',
                    label: window.location.href,
                  });
                  this.$analytics.redditPush('SignUp');
                  if (typeof gtag != 'undefined') {
                    // eslint-disable-next-line no-undef
                    gtag('event', 'conversion', {'send_to': 'AW-346885036/bH3vCPa_nsgCEKyXtKUB'});
                  }
                  return this.$router.replace({name: 'get-started'});
                })
            }).catch((err) => {
                const data = err.response.data;
                if (data.validation) {
                    let beValidation = {};
                    for (const item of data.validation) {
                        beValidation[item.field] = [item.message];
                    }
                    this.$refs.validationObserver.setErrors(beValidation);
                } else if (data.code && data.code === `USERNAME_TAKEN`) {
                    this.$refs.validationObserver.setErrors({
                        fullName: [data.message]
                    });
                } else {
                    this.signupError = data.message;
                }
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.form.email = to.params.email;
            if (!vm.form.email) {
                return vm.$route.redirect(`/`)
            }
            if (typeof gtag != 'undefined') {
                // eslint-disable-next-line no-undef
                gtag('event', 'conversion', {'send_to': 'AW-346885036/uXcPCIzAnsgCEKyXtKUB'});
            }
        });
    },
}
</script>
