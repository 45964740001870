<template>
  <div>
    <div v-if="isLoading">
      <div class="main-image-wrapper">
        <div class="main-image" :style="{backgroundImage: 'url(' + post.mainImageUrl + ')' + ',' + 'url(' + notFoundImageUrl + ')'}" />
        <div class="overflow" />
        <div class="btn-back-container">
          <router-link :to="{name: 'blog'}">
            <b-button variant="dark">Back</b-button>
          </router-link>
        </div>
      </div>
      <section ref="content" class="text-block pt-80 pb-80">
        <b-container>
          <b-row>
            <b-col>
              <h1 class="text-stroke">{{ post.title }}</h1>
              <div class="author-block d-flex mb-60">
                <p>Written by {{ post.author }}</p>
                <p>{{ post.createdAt | moment("MMMM D, YYYY") }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-html="content" class="text-content" :class="{'mb-60': images.length}" />
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <div class="carousel-wrapper">
            <vueper-slides
                autoplay
                id="post-carousel"
                :bullets="false"
                :pauseOnHover="false"
                :touchable="false"
                :duration="8000"
            >
              <vueper-slide
                  v-for="image in images"
                  v-bind:key="image.id"
              >
                <template v-slot:content>
                  <div class="position-relative full-size">
                    <div class="position-relative slide-size mb-80" ref="slide">
                      <div
                          class="post-image-wrapper"
                          v-bind:style="{ backgroundImage: 'url(' + image.url + ')' + ',' + 'url(' + notFoundImageUrl + ')'}"
                      />
                    </div>
                    <div class="position-relative text-center slide-text-container">
                      <p>{{ image.description }}</p>
                    </div>
                  </div>
                </template>
              </vueper-slide>
              <template v-slot:arrow-left>
                <img src="/images/icons/caret.svg" />
              </template>
              <template v-slot:arrow-right>
                <img src="/images/icons/caret.svg" />
              </template>
            </vueper-slides>
          </div>
        </b-container>
      </section>
    </div>
    <div v-else>
      <div class="loading-container">
        <b-spinner />
      </div>
    </div>
  </div>
</template>
<script>
import {api} from '@/services/api'
import {marked} from 'marked';
import { VueperSlides, VueperSlide } from "vueperslides";
import {notFoundImageUrl} from "@/shared/constants";

export default {
  name: 'Post',
  data() {
    return {
      notFoundImageUrl: notFoundImageUrl,
      post: null,
      images: [],
      isLoading: false,
    }
  },
  computed: {
    content() {
      return marked.parse(this.post.content);
    },
  },
  methods: {
    async fetchPost(postId) {
      this.isLoading = false;
      const {data} = await api.posts.getOne(postId);
      this.post = data;
      this.isLoading = true;
    },
    async fetchPostImages(postId) {
      const {data} = await api.posts.getListImages(postId);
      this.images = data;
    }
  },
  updated: function () {
    const allLinkInPost = this.$refs.content.getElementsByTagName('a');
    if (allLinkInPost.length === 0) return;
    for (let  i = 0; i < allLinkInPost.length; i++){
      allLinkInPost[i].setAttribute('target', '_blank');
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchPost(to.params.postId);
      vm.fetchPostImages(to.params.postId);
    });
  },
  components: {
    VueperSlides,
    VueperSlide,
  }
}
</script>
<style lang="scss" scoped>
  @import "~@/assets/styles/_mixins.scss/";

  .pt-80, pb80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-image-wrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - 300px);

    .overflow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.3);
    }
    .main-image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .btn-back-container {
      position: absolute;
      bottom: -17px;
      left: 100px;
      z-index: 5;
    }
  }
  h1 {
    @media only screen and (max-width: 768px) {
      font-size: 35px;
      line-height: 56px;
      &.text-stroke {
        padding-top: 10px;
      }
    }
  }
  .text-block {
    .text-content::v-deep {
      p {
        opacity: .75;
        line-height: 28px;
        font-size: 20px;
        @media only screen and (max-width: 768px) {
          line-height: 26px;
          font-size: 16px;
        }
      }
      img {
        width: 100%;
        margin-bottom: 25px;
        margin-top: 25px;
      }
    }
  }
  .full-size {
    width: 100%;
    height: 100%;
  }
  .author-block {
    p {
      opacity: .4;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  .slide-text-container {
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  #post-carousel::v-deep {
    .vueperslides__parallax-wrapper {
      padding-bottom: 0px!important;

      .vueperslides__track {
        position: relative;
        top: auto;
        height: auto;
        left: auto;
        right: auto;
        overflow: hidden;
      }

      .slide-size {
        width: 100%;
        padding-bottom: 56.25%;
      }
    }
  .vueperslides__arrows {
    .vueperslides__arrow {
      z-index: 100;

      &.vueperslides__arrow--prev {
        left: -30px;
        padding: 15px;
        background: #191B1F;
        opacity: 0.75;
        border-radius: 4px;

        img:first-child {
          transform: rotate(-180deg);
        }
        img {
          height: 30px;
          width: 30px;
        }
        &:hover {
          opacity: 1;
        }
      }
      &.vueperslides__arrow--next {
        right: -30px;
        padding: 15px;
        background: #191B1F;
        opacity: 0.75;
        border-radius: 4px;
        img {
          height: 30px;
          width: 30px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .vueperslide {
    position: relative;
  }
  .post-image-wrapper {
    @include beforeAfter;
  }
}
.carousel-wrapper {
  position: relative;
}
</style>
