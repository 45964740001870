<template>
  <div class="page subscription-page">
    <section>
      <b-container>
        <b-row class="mb-100">
          <b-col>
            <h1 class="text-stroke">Get 2GTHR</h1>
            <h3>
              Hang out with your favorite artists at 2GTHR.co - one month free
              trial, cancel any time
            </h3>
            <ul class="list-unstyled subscription-features-list">
              <li>
                <img src="/images/icons/check-bg.svg" /> Watch performances
                streamed LIVE in high def video and audio to any connected
                device
              </li>
              <li>
                <img src="/images/icons/check-bg.svg" /> Jump ”onstage” and ask
                your questions LIVE on 2-way video
              </li>
              <li>
                <img src="/images/icons/check-bg.svg" /> Chat in real time with
                the artist and others in the audience
              </li>
              <li>
                <img src="/images/icons/check-bg.svg" /> Catch up with on-demand
                session replays
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-container>
        <b-row>
          <b-col>
            <form @submit.prevent="createCheckoutSession" class="subscriptions-wrapper mb-30">
              <div v-if="!trialStarted" class="position-relative">
                <h3 class="mb-0">Sign up now for your 30-day trial.</h3>
                <p>
                  Choose the plan you’d like to start after your free month.
                  Cancel anytime.
                </p>
              </div>
              <div v-if="trialStarted" class="position-relative">
                <h3 class="mb-0">Subscribe now to keep enjoying 2GTHR.</h3>
                <p>Choose your plan. Cancel anytime.</p>
              </div>
              <b-row>
                <b-col
                  v-for="(plan, index) in plans"
                  :key="index"
                  cols="12"
                  md="4"
                >
                  <plan
                    :plan-name="plan.planName"
                    :price="plan.price"
                    :is-active="selectedSub === plan.name"
                    @click.native="setSubscription(plan.name)"
                  />
                </b-col>
              </b-row>
              <input type="hidden" v-model="selectedSub" />
              <b-row class="mt-2">
                <b-col>
                  <a class="link" @click="openSecurityNotesModal">
                    <strong>2GTHR cares about the security of your data</strong>
                  </a>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="mt-20">
                    <loading-button
                      variant="outline-primary"
                      :is-loading="isLoading"
                      :disabled="isLoading"
                    >
                      <template #content><span class="btn-submit-text">Subscribe</span></template>
                    </loading-button>
                  </div>
                </b-col>
              </b-row>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <promo-subscription classes="form-wrapper" variant="get-started" />
    <section v-if="showPromotionCode">
      <b-container>
        <b-row>
          <b-col>
            <div class="form-wrapper mb-30">
              <b-row>
                <b-col>
                  <h3 class="mb-30">If you have a 20-digit 2GTHR Promotion code, enter it here</h3>
                  <internal-promotion-code-form @submittedCallback="submittedCallback"/>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <security-notes-modal></security-notes-modal>
    <internal-promo-code-modal></internal-promo-code-modal>
  </div>
</template>

<script>
import {api, security} from "@/services";
import { mapGetters, mapActions } from "vuex";
import env from "../../../configuration/env";
import { loadStripe } from "@stripe/stripe-js";
import SecurityNotesModal from "../../shared/Modals/SecurityNotes";
import { format } from "date-fns";
import store from "@/store";
import { formatCurrency } from "@/utils/formatters";
import {Role} from "@/shared/enums";
import InternalPromotionCodeForm from "@/components/shared/Components/Forms/InternalPromotionCodeForm";
import {regExpInternalPromo} from "@/shared/regexp";
import {regexPromoCodeErrorMessage} from "@/shared/constants";
import PromoSubscription from "@/components/shared/Account/PromoSubscription";
import LoadingButton from "@/components/shared/Common/LoadingButton";
import Plan from '@/components/shared/Plans/Plan'
import {plans} from '@/mixins/plans';

export default {
  name: "GetStarted",
  mixins: [plans],
  data() {
    return {
      selectedSub: "quarterly",
      promoCodeInfo: null,
      promoCodeForm: {
        promoCode: "",
      },
      promoCodeFormIsSumbiting: false,
      promoCodeErrorMessage: "",
      isLoading: false,
      billing: null,
      env: env,
      hadEverSubscription: !!store.getters.billing,
    };
  },
  computed: {
    ...mapGetters(["apiRequesting", "user", "trialStarted"]),
    showPromotionCode() {
      return this.user &&
          !this.user.vip &&
          this.user.role === Role.LEARNER &&
          store.getters.subscriptions.length == 0
    },
    showForm() {
      return this.user && this.user.role === Role.LEARNER && !this.user.vip;
    },
  },
  methods: {
    ...mapActions([
      "setApiRequesting",
      "setAuthBilling",
      "setAuthSubscriptions",
      "setAuthTrialStarted",
    ]),
    setSubscription(subName) {
      this.selectedSub = subName;
    },
    subscribe(planId) {
      api.accounts
        .subscribe({ planId: planId, promotionCode: this.promoCodeInfo?.id })
        .then(async ({ data }) => {
          await security.forceRequestUser();
          this.$analytics.dataLayerPush({
            event: "Payment Completed",
            category: "Subscribe-Music",
            action: "Payment Completed",
            label: this.selectedSub,
          });
          this.$analytics.redditPush("Purchase");
          if (typeof gtag != "undefined") {
            // eslint-disable-next-line no-undef
            gtag("event", "conversion", {
              send_to: "AW-346885036/-r_JCICU-ccCEKyXtKUB",
              transaction_id: data.items[0] ? data.items[0].id : null,
            });
          }
          if (this.$bag.getItem("registered")) {
            return this.$router.replace({ name: "subscription-success" });
          }
          this.$alert.info({
            text: `You’re in! Thanks for subscribing.`,
            timeout: 5000,
          });
          return this.$router.replace({ name: "upcoming" });
        })
        .catch((err) => {
          this.cardError = err.response.data.message;
        });
    },
    openSecurityNotesModal() {
      this.$root.$emit("bv::show::modal", "security-notes-modal");
    },
    async validatePromoCode() {
      if (regExpInternalPromo.test(this.promoCodeForm.promoCode)) {
        this.promoCodeErrorMessage = regexPromoCodeErrorMessage;
        return;
      }

      this.promoCodeFormIsSumbiting = true;
      api.accounts
        .getPromoCodeInfo(this.promoCodeForm.promoCode)
        .then(({ data }) => {
          this.promoCodeInfo = data;
        })
        .catch((err) => {
          this.promoCodeErrorMessage = err?.response?.data?.message;
        })
        .finally(() => {
          this.promoCodeFormIsSumbiting = false;
        });
    },
    isDisabledPromoCodeFormSubmit() {
      return (
        !this.promoCodeForm.promoCode.trim() || this.promoCodeFormIsSumbiting
      );
    },
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      return format(date, "MMM dd, p");
    },
    formatDiscount(number) {
      return formatCurrency(number);
    },
    submittedCallback() {
      if (this.$bag.getItem("registered")) {
        return this.$router.replace({ name: "subscription-success" });
      }
      this.$alert.info({
        text: `You’re in! Thanks for subscribing.`,
        timeout: 5000,
      });
      return this.$router.replace({ name: "upcoming" });
    },
    async createCheckoutSession() {
      this.isLoading = true
      try {
        const { data } = await api.accounts.createCheckoutSession({
          planId: env.stripe.plans[this.selectedSub],
        });
        if (data) {
          this.stripe.redirectToCheckout({ sessionId: data.id });
        }
      } catch (e) {
        console.log(e);
        this.$notify({type: 'error', text: 'Try again' });
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    if (!this.showForm) {
      return;
    }
    this.stripe = await loadStripe(env.stripe.publishableKey, {
      locale: "en",
      apiVersion: "2020-08-27",
    });
  },
  components: {
    LoadingButton,
    PromoSubscription,
    InternalPromotionCodeForm,
    SecurityNotesModal,
    Plan,
  },
};
</script>
