export default {
  appTitle: process.env.VUE_APP_TITLE || '2GTHR',
  apiBaseUrl: process.env.VUE_APP_API_URL || 'https://us-central1-gthr-2c4b2.cloudfunctions.net/app/api/',
  appEnv: process.env.VUE_APP_ENV,
  intercom: {
    appId: process.env.VUE_APP_INTERCOM_KEY
  },
  stripe: {
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    plans: {
      monthly: process.env.VUE_APP_STRIPE_PLAN_BASE_MONTHLY,
      quarterly: process.env.VUE_APP_STRIPE_PLAN_BASE_QUARTERLY,
      annual: process.env.VUE_APP_STRIPE_PLAN_BASE_ANNUAL,
    },
  },
  agora: {
    appId: process.env.VUE_APP_AGORA_APP_ID,
  },
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  },
  unbounce: {
    url: process.env.VUE_APP_UNBOUNCE_URL,
  }
};
