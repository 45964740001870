<template>
  <router-link :to="{name: 'post', params: {postId: postId}}">
    <div class="container">
      <div class="image-block" :style="{backgroundImage: 'url(' + thumbUrl + ')' + ',' + 'url(' + notFoundImageUrl + ')'}" />
      <div class="block-overflow"/>
      <div class="text-block text-stroke">
        <h4>{{ title }}</h4>
      </div>
    </div>
  </router-link>
</template>

<script>
  import {notFoundImageUrl} from "@/shared/constants";

  export default {
    name: "BlogCard",
    data() {
      return {
        notFoundImageUrl: notFoundImageUrl,
      }
    },
    props: {
      thumbUrl: {
        type: String,
      },
      title: {
        type: String,
      },
      isLoading: {
        type: Boolean,
      },
      postId: String,
    }
  }
</script>
<style scoped lang="scss">
  @import "~@/assets/styles/_color-variables.scss";

  .container {
    position: relative;
    aspect-ratio: 1 / 1;
  }

  .block-overflow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.1);
  }

  .image-block {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .text-block {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    padding: 16px;
    background: rgba(0,0,0,0.6);
    color: $c-white;

    &.text-stroke {
      &::before {
        left: 16px;
        top: -2px;
      }
    }

    h4 {
      margin-bottom: 0;
    }
  }
</style>
