<template>
    <b-modal id="security-notes-modal"
             @hide="onModalHide"
             @shown="shownModal"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div class="modal-body-wrapper">
            <p class="h4">2GTHR cares about the security of your data, and we never store your credit card data on 2GTHR servers.  Payment information is encrypted and stored by Stripe, our payment processor partner.  Stripe has been audited by an independent PCI (Payment Card Industry) Qualified Security Assessor and is certified as a PCI Level 1 Service Provider. This is the most stringent level of certification available in the payments industry.  Stripe is also the provider to thousands of merchants we use every day, like Postmates, Instacart, Zoom, Uber, Lyft, and more</p>
        </div>
    </b-modal>
</template>

<script>

    export default {
        name: 'SecurityNotesModal',
        data() {
            return {
                shown: false,
            }
        },
        methods: {
            shownModal() {
                setTimeout(() => {
                    this.shown = true;
                }, 400);
            },
            onModalHide() {
                this.shown = false;
            },
            closeModal() {
                this.$root.$emit('bv::hide::modal', 'security-notes-modal')
            },
        },
    }
</script>