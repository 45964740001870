<template>
  <div class="learner-stream-wrapper">
    <div :id="'learner-' + streamId" class="stream"></div>
    <div v-if="canManageOnStage" class="description">
      {{ fullName }}
      <div class="stop-stream" @click="stopLearnerStream"></div>
    </div>
    <b-button
      v-if="showToggleMainViewButton"
      variant="black"
      @click="changeStreamPosition"
      class="btn-change-stream-position"
      v-html="toggleMessage"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {permissions} from "@/mixins/permissions";
import { Role } from '@/shared/enums'

export default {
  name: "LearnerStreamView",
  mixins: [permissions],
  props: {
    stream: {
      type: Object,
      required: true,
    },
    isHostHere: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["session", "audience", "streamsOnMainView", "user"]),
    streamId() {
      return this.stream.getId();
    },
    fullName() {
      const user = this.$store.getters.audienceByUid(this.streamId);
      if (user) {
        return user.fullName;
      }
      return null;
    },
    isInstructorStream() {
      const user = this.$store.getters.audienceByUid(this.streamId);
      if (user) {
        return user.role === Role.INSTRUCTOR || user.role === Role.MANAGER;
      }
      return null;
    },
    isExpanded() {
      return this.$store.getters.isStreamOnMainView(this.streamId);
    },
    toggleMessage() {
      return this.isExpanded ? `Reduce to Small Tile` : `Make Co-Host`;
    },
    showToggleMainViewButton() {
      if (!this.session.coHostsAllowed) {
        return false;
      }
      if (this.canManageOnStage && this.isInstructorStream) {
        if (this.isExpanded) {
          return true;
        }
        return this.streamsOnMainView.length < 3;
      }
      return false;
    },
  },
  methods: {
    stopLearnerStream() {
      if (this.canManageOnStage) {
        this.$agora._stopRemoteStreamLocally(this.streamId);
        if (this.streamId == this.user.nid) {
          this.$agora.unPublishStream();
          this.$agora.emit('set-hand-down');
        }
        this.$agora.stopRemoteStream(this.streamId);
      }
    },
    changeStreamPosition() {
      this.isExpanded
        ? this.$agora.removeFromMainView(this.streamId)
        : this.$agora.addToMainView(this.streamId);
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.$agora.displayLearnerStream(this.stream);
    });
  },
  beforeDestroy() {
    this.stream && this.stream.stop();
    this.$agora.clearLearnerStreamView(this.streamId);
  },
};
</script>
