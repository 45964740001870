<template>
    <div class="center-control text-center">
        <ul class="list-inline list-unstyled">
            <li>
                <b-button :disabled="!canUseControl" variant="round" class="icon-camera" :class="videoState ? '' : 'active'" @click="toggleCamera">
                    <div class="on">Disable<br/>Camera</div>
                    <div class="off">Enable<br/>Camera</div>
                </b-button>
            </li>
            <li>
                <b-button :disabled="!canUseControl" variant="round" class="icon-microphone" :class="audioState ? '' : 'active'" @click="toggleMicrophone">
                    <div class="on">Mute Me</div>
                    <div class="off">Unmute<br/>Me</div>
                </b-button>
            </li>
            <li v-if="!isHostHere">
                <b-button :disabled="!canUseControl" variant="round" class="icon-hand" :class="handUpState ? '' : 'active'" @click="toggleHandUp">
                    <div class="off">Raise<br/>Hand</div>
                    <div class="on">Lower<br/>Hand</div>
                </b-button>
            </li>
            <li v-if="isHostHere">
                <b-button :disabled="!canUseControl" variant="round" class="icon-mute" :class="muteState ? 'active' : ''" @click="toggleMuteAll">
                    <div class="on">Mute<br/>Everyone</div>
                    <div class="off">Unmute<br/>Everyone</div>
                </b-button>
            </li>
        </ul>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {ChannelEventType} from "@/shared/enums";
    import {permissions} from "@/mixins/permissions";

    export default {
        name: 'StreamControl',
        mixins: [permissions],
        props: {
            isHostHere: {
                type: Boolean
            },
            canUseControl: {
                type: Boolean
            },
        },
        data() {
            return {
                muteState: false,
                audioState: false,
                videoState: false,
                handUpState: false,
                dataURI: null
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'session',
            ]),
        },
        methods: {
            init() {
                this.$agora.on('audio-state-changed', (state) => {
                    this.audioState = state;
                });
                this.$agora.on('video-state-changed', (state) => {
                    this.videoState = state;
                });
                this.$agora.on('set-hand-down', () => {
                    this.handUpState = false;
                });
            },
            toggleMuteAll() {
                this.muteState = this.$agora.toggleMuteAll();
            },
            toggleMicrophone() {
                this.$agora.toggleAudioState();
            },
            toggleCamera() {
                this.$agora.toggleVideoState();
            },
            toggleHandUp() {
                if (this.handUpState) {
                  this.$agora.sendChannelMessage({type: ChannelEventType.HAND_DOWN});
                } else {
                  this.$agora.sendChannelMessage({type: ChannelEventType.HAND_UP});
                  if (this.canManageOnStage) {
                    this.$store.dispatch('addHandUp', {
                      time: + new Date(),
                      serverTime: + new Date(),
                      uid: this.user.nid,
                      fullName: this.user.fullName,
                      role: this.user.role,
                      type: ChannelEventType.HAND_UP,
                    })
                    this.$store.dispatch('addAudience', {
                      uid: this.user.nid,
                      fullName: this.user.fullName,
                      role: this.user.role,
                    });
                  }
                }
                this.handUpState = !this.handUpState;
            },
        },
        mounted() {
            this.init()
        }
    }
</script>
