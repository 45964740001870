<template>
    <b-modal id="artist-modal"
             @show="requestArtist"
             @hidden="artistInfo = null"
             @shown="onModalShown"
             @hide="onModalHide"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
             size="custom"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div v-if="artistInfo" class="modal-body-wrapper">
            <div class="image-wrapper" v-bind:style="{backgroundImage: 'url(' + artistInfo.imageUrl + ')'}">
                <a v-if="false && artistInfo.category" v-on:click="categorySelected(artistInfo.category)" class="category-badge">
                    {{ artistInfo.category.title }}
                </a>
            </div>
            <div class="artist-info">
                <h4 class="semibold">{{ artistInfo.firstName }} {{ artistInfo.lastName }}</h4>
                <vue-clamp :max-height="450" tag="div" class="description" v-html="artistInfo.bio"></vue-clamp>
                <a v-if="artistInfo.websiteUrl" :href="artistInfo.websiteUrl" target="_blank">Website <img src="/images/icons/subtract.svg"/></a>
                <div v-if="false" class="badge-footer">
                    <ul class="inline ">
                        <li class="list-inline-item" v-if="artistInfo.coursesCount">{{ artistInfo.coursesCount }} Upcoming Class{{ artistInfo.coursesCount > 1 ? 'es' : ''}}</li>
                        <li class="list-inline-item" v-if="artistInfo.pastCoursesCount">{{ artistInfo.pastCoursesCount }} Past Class{{ artistInfo.pastCoursesCount > 1 ? 'es' : ''}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import { mapGetters } from "vuex";
    import {api} from "../../../services/api";

    export default {
        name: 'ArtistModal',
        props: {
            selectCategoryCb: {
                type: Function,
                required: false
            }
        },
        data() {
            return {
                artistInfo: null,
                shown: false,
                prevState: {
                    path: null,
                    title: null
                }
            }
        },
        computed: {
            ...mapGetters({
                artist: "artist"
            })
        },
        methods: {
            onModalHide() {
                this.shown = false;
                window.history.replaceState({}, this.prevState.title, this.prevState.path);
            },
            onModalShown() {
                this.prevState.path = window.location.pathname;
                this.prevState.title = document.title;
            },
            requestArtist() {
                if (!this.artist) {
                    this.closeModal()
                }
                api.artists.getOne(this.artist.id).then((response) => {
                    this.artistInfo = response.data.item;
                    setTimeout(() => {
                        this.shown = true;
                        window.history.replaceState({}, "2GTHR | " + this.artistInfo.firstName + ' ' + this.artistInfo.lastName, "/artists/" + this.artistInfo.slug);
                    }, 400)
                })
            },
            categorySelected(category) {
                if (this.selectCategoryCb) {
                    this.selectCategoryCb(category);
                    this.closeModal();
                }
            },
            closeModal() {
                this.$root.$emit('bv::hide::modal', 'artist-modal')
            }
        },
    }
</script>