<template>
  <b-button
    class=""
    :size="size"
    :variant="variant"
    :disabled="disabled"
    :type="type"
    @click="$emit('click-handler')"
  >
    <slot name="content"></slot>
    <b-spinner
      v-if="isLoading"
      class="ml-2"
      small
    />
  </b-button>
</template>
<script>
  export default {
    name: 'LoadingButton',
    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      content: {
        type: String,
        required: false,
        default: 'Apply',
      },
      type: {
        type: String,
        required: false,
        default: 'submit',
      },
      variant: {
        type: String,
        required: false,
        default: 'variant',
      },
      size: {
        type: String,
        required: false,
        default: 'default',
      },
      clickHandler: {
        type: Function,
        required: false,
      }
    },
  }
</script>
