<template>
  <div>
    <div class="d-flex justify-content-between">
      <validation-observer ref="form" tag="div" class="promo-form-wrapper">
        <form @submit.prevent="submit">
          <validation-provider name="code" rules="required|internalPromoCode" v-slot="{ errors, reset }">
            <div class="position-relative">
              <b-input
                class=""
                type="text"
                placeholder="2GTHR-XXXXХ-XXXXХ-XXXXХ"
                v-uppercase
                v-mask="mask"
                v-model.trim="code"
                :disabled="isSubmittedApply"
                @focus="() => focus(reset)"
              />
              <p v-if="showError" class="error-msg input-error">{{ errors[0] }}</p>
            </div>
          </validation-provider>
          <b-button
            size="lg"
            variant="blue"
            class="d-flex align-items-center mt-4 semibold btn-submit"
            type="submit"
            :disabled="isSubmittedApply"
          >
            <span class="btn-submit-text">Apply</span>
            <b-spinner v-if="isSubmittedApply" small/>
          </b-button>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import {extend} from 'vee-validate'
  import {required} from 'vee-validate/dist/rules'
  import {api, security} from "@/services";
  import {regExpInternalPromo} from "@/shared/regexp";

  extend('internalPromoCode', {
    message: 'Invalid code',
    validate: value => regExpInternalPromo.test(value)
  });
  extend('required', {
    ...required,
    message: field => `The ${field} value is required`,
  });

  const regexArray = new Array(5).fill(/[A-Z0-9]/);
  const separate = '-';

  export default {
    props: {
      submittedCallback: {
        type: Function
      }
    },
    name: 'InternalPromotionCodeForm',
    data() {
      return {
        mask: [...regexArray, separate, ...regexArray, separate, ...regexArray, separate, ...regexArray],
        code: "",
        isSubmittedApply: false,
        showError: false,
      };
    },
    methods: {
      focus(reset) {
        reset();
        this.showError = false;
      },
      async submit() {
        this.isSubmittedApply = true;
        const isValid = await this.$refs.form.validate();
        if (!isValid) {
          this.showError = true;
          this.$refs.form.setErrors({
            code: [this.$refs.form.errors.code[0]],
          });
          this.isSubmittedApply = false;
          return;
        }

        try {
          await api.accounts.subscribePromo(this.code);
          const user = await api.auth.me();
          security.setUserResponse(user.data);
          this.$emit('submittedCallback');
        } catch(err) {
          this.showError = true;
          this.$refs.form.setErrors({
            code: [err?.response.data?.message],
          });
        }
        this.isSubmittedApply = false;
      }
    },
  }
</script>
<style scoped>
  .promo-form-wrapper {
    width: 100%;
  }
  .input-error {
    position: relative;
    bottom: -5px;
    margin-bottom: 0px;
  }
  .btn-submit-text {
    display: block;
    margin-right: 10px;
  }
</style>
