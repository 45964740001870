<template>
  <div class="auth-view auth-login">
    <div class="auth-page-bg col-md-8 offset-md-2">
      <div class="auth-container">
        <div class="auth-wrapper">
          <h3 class="semibold mb-30">Welcome Back</h3>
          <validation-observer ref="validationObserver">
            <b-form @submit.prevent="submitForm">
              <b-form-group>
                <label for="form-input-email">Email Address</label>
                <validation-provider rules="required|email" v-slot="{classes, errors}" mode="eager" name="Email">
                  <b-input
                          v-model.trim="form.email"
                          id="form-input-email"
                          class="mb-4 mr-sm-2"
                  ></b-input>
                  <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="form-input-password">Password</label>
                <validation-provider rules="required" v-slot="{classes, errors}" mode="eager" name="Password">
                  <b-input
                          type="password"
                          v-model="form.password"
                          id="form-input-password"
                          class="mb-4 mr-sm-2"
                  ></b-input>
                  <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                </validation-provider>
              </b-form-group>
              <p class="text-danger" v-show="error">{{ error }}</p>
              <b-row class="mb-20">
                <b-col cols="12" sm="4">
                  <b-button size="lg" variant="blue" type="submit" class="semibold" :disabled="apiRequesting">Login</b-button>
                </b-col>
                <b-col cols="12" sm="8" class="text-right">
                  <b-link :to="{name: 'password-forgot'}">Reset Password / Forgot Password</b-link>
                </b-col>
              </b-row>
              <div>
                <p class="d-inline-block font-weight-bold mr-1">New to 2GTHR?</p>
                <p class="d-inline-block font-weight-bold"><b-link :to="{ name: 'register' }">Create an account</b-link></p>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {api} from '@/services'
import {mapActions, mapGetters} from 'vuex'
import {security} from "@/services";
import firebase from 'firebase/app';
import 'firebase/auth';

  export default {
    name: 'Login',
    data() {
      return {
        form: {
          email: "",
          password: ""
        },
        error: null
      };
    },
    computed: {
      ...mapGetters([
        'apiRequesting'
      ]),
    },
    methods: {
      ...mapActions([
        'setApiRequesting',
      ]),
      async submitForm() {
        this.error = null;
        const isValid = await this.$refs.validationObserver.validate();
        if (!isValid) {
          return;
        }

        this.setApiRequesting(true);
        firebase.auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
              return firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
            })
            .then(() => {
              this.$bag.removeItem('registered');
              api.auth.me().then(({data}) => {
                security.setUserResponse(data);
                const initUri = security.getInitUri();
                security.setInitUri(null);
                if (initUri && initUri !== '/login' && initUri !== '/verification/email') {
                  this.$router.push(initUri).catch(()=>{});
                } else {
                  this.$router.replace({name: 'upcoming'});
                }
                this.setApiRequesting(false);
              }).catch((err) => {
                console.error(err);
                const data = err.response.data;
                this.error = data.message;
                this.setApiRequesting(false);
              })
            })
            .catch(err => {
              this.setApiRequesting(false);
              this.error = err.message;
            });
      }
    }
  }
</script>
