import Vue from "vue";

Vue.directive("maxchars", {
    bind: function(el, binding) {
        const max_chars = binding.expression;
        const handler = (e) => {
            if (e.target.value.length > max_chars) {
                e.target.value = e.target.value.substr(0, max_chars);
            }
        };
        el.addEventListener("input", handler);
    }
});