<template>
    <div class="page my-schedule-page">
        <section>
            <b-container>
                <b-row class="mb-100">
                    <b-col md>
                        <h1 class="text-stroke">My Schedule</h1>
                    </b-col>
                   </b-row>
                <b-row>
                    <b-col>
                        <div v-if="schedulesToday && schedulesToday.length" class="schedules-sections-wrapper mb-5">
                            <h2>Today</h2>
                            <div class="schedules-wrapper">
                                <div v-for="schedule in schedulesToday" v-bind:key="schedule.id">
                                    <schedule :schedule="schedule" :cta="true" :isToday="true" :un-schedule-cb="unSchedule"></schedule>
                                </div>
                            </div>
                        </div>
                        <div v-if="schedulesComingUp && schedulesComingUp.length" class="schedules-sections-wrapper">
                            <h2>Coming Up</h2>
                            <div class="schedules-wrapper">
                                <div v-for="schedule in schedulesComingUp" v-bind:key="schedule.id">
                                    <schedule :schedule="schedule" :cta="false" :isToday="false" :un-schedule-cb="unSchedule"></schedule>
                                </div>
                            </div>
                        </div>
                        <infinite-loading :identifier="infiniteId" @infinite="requestSchedules">
                            <div slot="spinner">Loading...</div>
                            <div slot="no-more" class="hide"></div>
                            <div slot="no-results">
                                <div class="empty-state empty-list text-center">
                                    <h3>Nothing to show. Please, add new <b-link :to="{name: 'upcoming'}">Upcoming Session</b-link> to your Schedule</h3>
                                </div>
                            </div>
                        </infinite-loading>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <artist-modal></artist-modal>
    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import {api} from "../../../services/api";
    import ArtistModal from "../../shared/Modals/Artist";
    import Schedule from "../../shared/Content/Schedule";
    import {browserStorage} from "../../../services";

    export default {
        name: 'MySchedulePage',
        data () {
            return {
                page: 0,
                lastIndex: null,
                infiniteId: +new Date(),
                schedules: [],
                hideAlert: false,
            }
        },
        computed: {
            showAlert() {
                return !this.$store.getters.alertState('alert-my-schedule-banner');
            },
            schedulesToday: function () {
                const list = [];
                if (!this.schedules || !this.schedules.length) {
                    return list;
                }
                for (let schedule of this.schedules) {
                    if (this.$moment(schedule.course.utc).isSame(new Date(), "day")) {
                        list.push(schedule);
                    }
                }
                return list;
            },
            schedulesComingUp: function () {
                const list = [];
                if (!this.schedules || !this.schedules.length) {
                    return list;
                }
                for (let schedule of this.schedules) {
                    if (!this.$moment(schedule.course.utc).isSame(new Date(), "day")) {
                        list.push(schedule);
                    }
                }
                return list;
            }
        },
        methods: {
            ...mapActions([
                'setArtist',
            ]),
            requestSchedules($state) {
                api.schedules.list({params: {
                        page: this.page,
                        lastIndex: this.lastIndex,
                    }
                }).then(({data}) => {
                    if (data.items.length) {
                        this.page += 1;
                        this.schedules.push(...data.items);
                        this.lastIndex = this.schedules[this.schedules.length - 1].utc
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
            },
            unSchedule(schedule) {
                this.schedules.splice(this.schedules.indexOf(schedule), 1);
                if (!this.schedules.length) {
                    this.infiniteId += 1;
                }
            },
            dismissAlert() {
                browserStorage.addItem('alert-my-schedule-banner');
                this.$forceUpdate();
            }
        },
        components: {
            ArtistModal,
            Schedule,
        }
    }
</script>
