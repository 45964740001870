import {accounts} from './accounts';
import {articles} from './articles';
import {artists} from './artists';
import {auth} from './auth';
import {categories} from './categories';
import {courses} from './courses';
import {emails} from './emails';
import {files} from './files';
import {schedules} from './schedules';
import {settings} from './settings';
import {users} from './users';
import {verification} from './verification';
import {posts} from './posts';

export const api = {
  articles,
  accounts,
  artists,
  auth,
  categories,
  courses,
  emails,
  files,
  schedules,
  settings,
  users,
  verification,
  posts,
};
