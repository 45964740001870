<template>
  <div class="page password-reset-page">
    <div class="text-center mt-120 mb-120">
      <section v-if="passwordUpdated">
        <b-container>
          <b-row>
            <b-col>
              <div class="form-wrapper mb-30">
                <h4 class="mt-80 mb-80">Password updated successfully. Use your new password to login</h4>
                <b-button size="lg" variant="blue" class="mt-3 semibold" :to="{name: 'login'}">Login</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <validation-observer ref="validationObserver" v-if="!passwordUpdated">
        <form @submit.prevent="submitForm" novalidate>
          <section>
            <b-container>
              <b-row>
                <b-col>
                  <div class="form-wrapper mb-30">
                    <h4 class="mb-30">Provide your new password</h4>
                    <b-row>
                      <b-col cols="10" offset="1" lg="6" offset-lg="3" class="text-left">
                        <validation-provider rules="required" v-slot="{classes, errors }" name="Password" vid="password">
                          <label for="billing-form-input-password">New Password</label>
                          <b-input
                                  type="password"
                                  v-model="password"
                                  id="billing-form-input-password"
                                  class="mb-4 mr-sm-2"
                                  :class="classes"
                          ></b-input>
                          <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="10" offset="1" lg="6" offset-lg="3" class="text-left">
                        <validation-provider rules="required|confirmed:password" v-slot="{classes, errors }" name="Password Confirmation">
                          <label for="billing-form-input-password-confirmation">Password Confirmation</label>
                          <b-input
                                  type="password"
                                  v-model="passwordConfirmation"
                                  id="billing-form-input-password-confirmation"
                                  class="mb-4 mr-sm-2"
                                  :class="classes"
                          ></b-input>
                          <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-button size="lg" variant="blue" type="submit" class="mt-3 semibold" :disabled="isRequesting">Change password</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </section>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>

  import {api} from '@/services'

  export default {
    name: 'PasswordReset',
    data() {
      return {
        isRequesting: false,
        email: null,
        token: null,
        password: null,
        passwordConfirmation: null,
        passwordUpdated: false
      };
    },
    methods: {
      verifyRequirements(email, token) {
        if (!email || !token) {
          return this.$router.replace({name: 'login'});
        }
        this.email = decodeURI(email);
        this.token = token;
      },
      async submitForm() {
        const self = this;
        const isValid = await this.$refs.validationObserver.validate();
        if (!isValid) {
          this.cardError = `Please, check input fields`;
          return;
        }
        self.isRequesting = true;
        api.auth.passwordReset({
          email: self.email,
          token: self.token,
          password: self.password,
        }).then(() => {
          self.passwordUpdated = true;
        }).finally(() => {
          self.isRequesting = false;
        });
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.verifyRequirements(to.query.email, to.query.token);
      });
    }
  }
</script>
