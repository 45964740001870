import {Permissions} from "@/shared/enums";
import {checkPermissions} from "@/shared/acl";

const getters = {
    isManagedSession(state, getters, rootState) {
        return rootState.session.session?.isManaged ?? false;
    },
    isHostHere(state, getters, rootState) {
        return rootState.session.session?.artistUserId === rootState.auth?.user?.id ?? false;
    },
    canBeArtist(state, getters, rootState) {
        return rootState.auth.permissions.includes(Permissions.CAN_BE_ARTIST);
    },
    canManageSessionFlow(state, getters, rootState) {
        return getters.isManagedSession && rootState.auth.permissions.includes(Permissions.CAN_MANAGE_SESSION_FLOW);
    },
    canManageOnStage(state, getters, rootState) {
        return getters.isHostHere || (getters.isManagedSession && rootState.auth.permissions.includes(Permissions.CAN_MANAGE_ON_STAGE));
    },
    canFinishSession(state, getters, rootState) {
        return getters.isHostHere ||
            (getters.isManagedSession && rootState.auth.permissions.includes(Permissions.CAN_MANAGE_SESSION_FLOW));
    },
    freeFromSubscription(state, getters, rootState) {
        return checkPermissions([Permissions.FREE_FROM_SUBSCRIPTION], rootState.auth.permissions) || rootState.auth.user.vip;
    },
    isGreenRoomAllowed(state, getters, rootState) {
        return getters.isHostHere ||
            (checkPermissions([Permissions.IS_GREEN_ROOM_ALLOWED], rootState.auth.permissions) && rootState.session.session?.coHostsAllowed);
    },
}

export default {
    getters,
}
