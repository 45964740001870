<template>
    <div class="page contact-us-page">
        <section>
            <b-container>
                <b-col>
                    <h1 class="text-stroke mb-120" id="faq">Contact Us</h1>

                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col cols="12" sm="6">
                                    <div class="contact-banner float-sm-right float-none red">
                                        <img src="/images/public/headphones.svg"/>
                                        <h3 class="mb-0">Support</h3>
                                        <p>Our support team is here to give you answers about how to use 2GTHR.</p>
                                        <b-button class="semibold" variant="blue" size="sm" href="mailto:support@2gthr.co">Email</b-button>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6">
                                    <div class="contact-banner blue">
                                        <img src="/images/public/press.svg"/>
                                        <h3 class="mb-0">Press</h3>
                                        <p>Get in touch if you’re working on a story about 2GTHR or have a media inquiry.</p>
                                        <b-button class="semibold" variant="blue" size="sm" href="mailto:press@2gthr.co">Email</b-button>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" sm="6">
                                    <div class="contact-banner float-sm-right float-none yellow">
                                        <img src="/images/public/guitar.svg"/>
                                        <h3 class="mb-0">Artists</h3>
                                        <p>If you’re an artist interested in performing on 2GTHR, give us a shout.</p>
                                        <b-button class="semibold" variant="blue" size="sm" href="mailto:artists@2gthr.co">Email</b-button>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6">
                                    <div class="contact-banner cyan">
                                        <img src="/images/public/general.svg"/>
                                        <h3 class="mb-0">General Inquiries</h3>
                                        <p>Any other general questions, please reach out to us here.</p>
                                        <b-button class="semibold" variant="blue" size="sm" href="mailto:info@2gthr.co">Email</b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-container>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ContactUs',
}
</script>
