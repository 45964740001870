<template>
  <div class="page artists-page">
    <section>
      <b-container>
        <b-row class="mb-100">
          <b-col cols="12" sm="6">
            <h1 class="text-stroke">Artists</h1>
            <p>
              2GTHR has assembled an all-star roster just for you. Meet up with
              your favorite artists as they broadcast live directly from their
              home to yours. Let’s get 2GTHR!
            </p>
          </b-col>
          <b-col cols="12" sm="6">
            <div class="filters-wrapper float-right">
              <b-input-group>
                <b-form-input
                  placeholder="Search Artists"
                  v-on:keydown.enter="searchBy"
                  v-model="search"
                ></b-form-input>
                <button
                  v-bind:class="{
                    'not-empty': search && search === lastSearch,
                  }"
                  v-on:click="startSearch"
                ></button>
              </b-input-group>
              <b-input-group v-if="false">
                <b-form-select
                  v-on:change="resetPagination"
                  :options="categoriesList"
                  text-field="title"
                  value-field="id"
                  v-model="category"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div
              v-if="artists && artists.length"
              class="artists-wrapper text-center"
            >
              <div
                class="artist-badge text-left"
                v-for="artist in artists"
                v-bind:key="artist.id"
                v-show="!artist.isHidden"
              >
                <div
                  class="image-wrapper"
                  v-bind:style="{
                    backgroundImage: 'url(' + artist.imageUrl + ')',
                  }"
                >
                  <span v-if="false" class="category-badge">
                    {{ artist.category.title }}
                  </span>
                </div>
                <div class="artist-info">
                  <h4 class="semibold">
                    {{ artist.firstName }} {{ artist.lastName }}
                  </h4>
                  <vue-clamp
                    :max-height="450"
                    tag="p"
                    v-html="artist.bio"
                  ></vue-clamp>
                  <a
                    v-if="artist.websiteUrl"
                    :href="artist.websiteUrl"
                    target="_blank"
                    >Website <img src="/images/icons/subtract.svg"
                  /></a>
                  <div v-if="false" class="badge-footer">
                    <ul class="inline">
                      <li class="list-inline-item" v-if="artist.coursesCount">
                        {{ artist.coursesCount }} Upcoming Class{{
                          artist.coursesCount > 1 ? "es" : ""
                        }}
                      </li>
                      <li
                        class="list-inline-item"
                        v-if="artist.pastCoursesCount"
                      >
                        {{ artist.pastCoursesCount }} Past Class{{
                          artist.pastCoursesCount > 1 ? "es" : ""
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading
              :identifier="infiniteId"
              @infinite="requestArtists"
            >
              <div slot="spinner">Loading...</div>
              <div slot="no-more" class="hide"></div>
              <div slot="no-results">
                <div class="empty-state empty-list text-center">
                  <h3>No results, try another category</h3>
                </div>
              </div>
            </infinite-loading>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <artist-modal :select-category-cb="selectCategory"></artist-modal>
  </div>
</template>

<script>
import { api } from "../../../services";
import { mapActions } from "vuex";
import ArtistModal from "../../../components/shared/Modals/Artist";

export default {
  name: "ArtistsPage",
  metaInfo: {
    title: "Artist Roster | 2GTHR",
    meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: "Artist Roster | 2GTHR",
      },
      {
        vmid: "description",
        property: "description",
        content:
          "Discover all of the artists performing via live stream from their home directly into yours.",
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Discover all of the artists performing via live stream from their home directly into yours.",
      },
    ],
  },
  data() {
    return {
      pageIndex: 1,
      category: null,
      search: null,
      lastSearch: null,
      infiniteId: +new Date(),
      categories: [],
      artists: [],
    };
  },
  computed: {
    categoriesList() {
      return [...[{ id: null, title: "All" }]];
    },
  },
  methods: {
    ...mapActions(["setArtist"]),
    initSearch(query) {
      if (query.category) {
        this.category = query.category;
      }
      if (query.search) {
        this.search = query.search;
      }
    },
    selectCategory(category) {
      this.search = "";
      this.category = category.id;
      this.resetPagination();
    },
    startSearch() {
      if (this.search === this.lastSearch) {
        this.clearSearch();
      } else {
        this.searchBy();
      }
    },
    searchBy() {
      if (this.search.length >= 3 || !this.search.length) {
        this.resetPagination();
      }
    },
    requestArtists($state) {
      api.artists
        .list({
          params: {
            pageIndex: this.pageIndex,
            search: this.search,
            category: this.category,
          },
        })
        .then(({ data }) => {
          this.lastSearch = this.search;
          if (data.items.length) {
            this.pageIndex += 1;
            this.artists.push(...data.items);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    clearSearch() {
      this.search = "";
      this.resetPagination();
    },
    resetPagination() {
      this.pageIndex = 1;
      this.artists = [];
      this.infiniteId += 1;
      const data = {};
      if (this.category) {
        data.category = this.category;
      }
      if (this.search) {
        data.search = this.search;
      }
      const queryString = new URLSearchParams(data).toString();
      const query = Object.keys(data).length ? `?${queryString}` : ``;
      window.history.replaceState(
        {},
        "Artist Roster | 2GTHR",
        `/artists${query}`
      );
    },
    requestCategories() {
      api.categories.list().then((response) => {
        this.categories = response.data.items;
      });
    },
    showArtistModal(artist) {
      this.setArtist(artist).then(() => {
        this.$root.$emit("bv::show::modal", "artist-modal");
      });
    },
    requestArtist(slug) {
      api.artists
        .getOneBySlug(slug)
        .then((response) => {
          this.showArtistModal(response.data.item);
        })
        .catch(() => {
          window.location.href = "/404";
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const slug = to.params.slug;
      if (slug) {
        vm.requestArtist(slug);
      } else {
        vm.initSearch(to.query);
      }
      vm.requestCategories();
    });
  },
  components: {
    ArtistModal,
  },
};
</script>
