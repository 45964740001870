import {allowAuthenticated, allowByTopRolesOrSubscribed, allowEmailVerified} from "./base/accesses";

export default () => {
    const authedCheck = allowAuthenticated();
    if (authedCheck) {
        return authedCheck;
    }
    const emailVerifiedCheck = allowEmailVerified();
    if (emailVerifiedCheck) {
        return authedCheck;
    }
    const roleCheck = allowByTopRolesOrSubscribed();
    if (roleCheck) {
        return roleCheck;
    }
    return null;
};

