<template>
  <div class="select-devices-wrapper">
    <div v-if="menuShown" class="settings-wrapper">
      <div
        class="settings-inner"
        :class="isGreenRoomAllowed ? 'instructor' : ''"
        v-click-outside="closeMenu"
      >
        <div v-if="isGreenRoomAllowed" class="quality-settings">
          <ul class="list-unstyled">
            <li>
              <strong>Resolution</strong><br />
              <div class="select-wrapper">
                <b-select
                  v-model="streamProfile.resolution"
                  :options="options.resolutions"
                ></b-select>
              </div>
            </li>
            <li>
              <strong>Audio Profile</strong><br />
              <div class="select-wrapper">
                <b-select
                  v-model="streamProfile.audioProfile"
                  :options="options.audioProfiles"
                ></b-select>
              </div>
            </li>
            <li>
              <strong>Video profile</strong><br />
              <div class="select-wrapper">
                <b-select
                  v-model="streamProfile.bitrate"
                  :options="options.bitrates"
                ></b-select>
              </div>
            </li>
            <li>
              <b-form-checkbox
                v-model="streamProfile.agc"
                :value="true"
                :unchecked-value="false"
              >
                Automatic Gain Control
              </b-form-checkbox>
              <b-form-checkbox
                v-model="streamProfile.aec"
                :value="true"
                :unchecked-value="false"
              >
                Audio Echo Cancellation
              </b-form-checkbox>
              <b-form-checkbox
                v-model="streamProfile.ans"
                :value="true"
                :unchecked-value="false"
              >
                Automatic Noise Suppression
              </b-form-checkbox>
            </li>
            <li>
              <b-button
                variant="blue"
                class="btn-w-100"
                @click="saveDefaultProfile"
              >
                <transition mode="out-in" name="fade-in">
                  <span :key="saveAsDefaultBtnText">{{
                    saveAsDefaultBtnText
                  }}</span>
                </transition>
              </b-button>
            </li>
          </ul>
        </div>
        <div class="devices-settings">
          <strong>Audio</strong>
          <ul class="list-unstyled">
            <li v-for="item in audio" :key="item.id">
              <b-form-radio
                v-model="streamProfile.audioSource"
                name="audio-source"
                :value="item"
              >
                {{ item.label }}
              </b-form-radio>
            </li>
          </ul>
          <hr />
          <strong>Video</strong>
          <ul class="list-unstyled">
            <li v-for="item in video" :key="item.id">
              <b-form-radio
                v-model="streamProfile.videoSource"
                name="video-source"
                :value="item"
              >
                {{ item.label }}
              </b-form-radio>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <b-button
      :disabled="!canUseSettings"
      :title="canUseSettings ? '' : 'Not available'"
      class="btn-stream-settings"
      variant="gray"
      size="sm"
      @click="toggleMenu"
    >
      <img src="/images/icons/gear.svg" />
      <span>Settings</span>
    </b-button>
    <b-button
      :disabled="!canUseSettings"
      variant="cyan-rounded"
      @click="showSessionTipsModal"
      >Session Tips</b-button
    >
    <session-tips-modal></session-tips-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bag } from "../../../services/bag";
import SessionTipsModal from "../Modals/SessionTips";
export default {
  name: "StreamSettings",
  components: { SessionTipsModal },
  props: {
    isGreenRoomAllowed: {
      type: Boolean,
    },
    canUseSettings: {
      type: Boolean,
    },
    streamProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuShown: false,
      audio: [],
      video: [],
      menuShowInit: false,
      defaultSaving: false,
      options: {
        audioProfiles: [
          { text: "Mono / 16 kHz / 24 Kbps", value: "speech_low_quality" },
          { text: "Mono / 32 kHz / 24 Kbps", value: "speech_standard" },
          { text: "Mono / 48 kHz / 40 Kbps", value: "music_standard" },
          { text: "Stereo / 48 kHz / 64 Kbps", value: "standard_stereo" },
          { text: "Mono / 48 kHz / 128 Kbps", value: "high_quality" },
          { text: "Stereo / 48 kHz / 192 Kbps", value: "high_quality_stereo" },
        ],
        // https://docs.agora.io/en/faq/API%20Reference/web/interfaces/agorartc.stream.html#setvideoprofile
        videoProfiles: [
          { text: "1920x1080 / 30 fps", value: "1080p_3" },
          { text: "1920x1080 / 15 fps", value: "1080p_1" },
          { text: "1280x720 / 30 fps", value: "720p_2" },
          { text: "1280x720 / 15 fps", value: "720p_1" },
          { text: "848x480 / 30 fps", value: "480p_9" },
          { text: "848x480 / 15 fps", value: "480p_8" },
        ],
        bitrates: [
          { text: "1,000-2,000", value: { min: 100, max: 2000 } },
          { text: "2,000-3,000", value: { min: 2000, max: 3000 } },
          { text: "3,000-4,000", value: { min: 3000, max: 4000 } },
          { text: "4,000-5,000", value: { min: 4000, max: 5000 } },
        ],
        resolutions: [
          {
            text: "1920x1080 / 30 fps",
            value: {
              width: 1920,
              height: 1080,
              fps: { min: 30, max: 30 },
              recBitrate: 3000,
            },
          },
          {
            text: "1920x1080 / 15 fps",
            value: {
              width: 1920,
              height: 1080,
              fps: { min: 15, max: 15 },
              recBitrate: 2080,
            },
          },
          {
            text: "1280x720 / 30 fps",
            value: {
              width: 1280,
              height: 720,
              fps: { min: 30, max: 30 },
              recBitrate: 2000,
            },
          },
          {
            text: "1280x720 / 15 fps",
            value: {
              width: 1280,
              height: 720,
              fps: { min: 15, max: 15 },
              recBitrate: 1130,
            },
          },
          {
            text: "848x480 / 30 fps",
            value: {
              width: 848,
              height: 480,
              fps: { min: 30, max: 30 },
              recBitrate: 930,
            },
          },
          {
            text: "848x480 / 15 fps",
            value: {
              width: 848,
              height: 480,
              fps: { min: 15, max: 15 },
              recBitrate: 610,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user", "session"]),
    audioSource() {
      return this.streamProfile.audioSource;
    },
    videoSource() {
      return this.streamProfile.videoSource;
    },
    audioProfile() {
      return this.streamProfile.audioProfile;
    },
    videoProfile() {
      return this.streamProfile.videoProfile;
    },
    bitrate() {
      return this.streamProfile.bitrate;
    },
    resolution() {
      return this.streamProfile.resolution;
    },
    agc() {
      return this.streamProfile.agc;
    },
    aec() {
      return this.streamProfile.aec;
    },
    ans() {
      return this.streamProfile.ans;
    },
    saveAsDefaultBtnText() {
      return this.defaultSaving ? "Saved" : "Save as Default";
    },
  },
  watch: {
    audioSource: function (val) {
      if (val) {
        this.$agora.setAudioSource(val.deviceId, this.streamProfile);
      }
    },
    videoSource: function (val) {
      if (val) {
        this.$agora.setVideoSource(val.deviceId, this.streamProfile);
      }
    },
    audioProfile: function (val) {
      if (this.isGreenRoomAllowed) {
        this.$agora.setAudioProfile(val, this.streamProfile);
      }
    },
    videoProfile: function (val) {
      if (this.isGreenRoomAllowed) {
        this.$agora.setVideoProfile(val, this.streamProfile);
      }
    },
    bitrate: function (val) {
      if (this.isGreenRoomAllowed) {
        this.$agora.setVideoEncoderConfiguration({
          ...this.streamProfile,
          ...{ bitrate: val },
        });
      }
    },
    resolution: function (val) {
      if (this.isGreenRoomAllowed) {
        this.$agora.setVideoEncoderConfiguration({
          ...this.streamProfile,
          ...{ resolution: val },
        });
      }
    },
    agc: function (newValue) {
      if (this.isGreenRoomAllowed) {
        this.$agora.applyAudioEffects(newValue, this.aec, this.ans);
      }
    },
    aec: function (newValue) {
      if (this.isGreenRoomAllowed) {
        this.$agora.applyAudioEffects(this.agc, newValue, this.ans);
      }
    },
    ans: function (newValue) {
      if (this.isGreenRoomAllowed) {
        this.$agora.applyAudioEffects(this.agc, this.aec, newValue);
      }
    },
  },
  methods: {
    showSessionTipsModal() {
      this.$root.$emit("bv::show::modal", "session-tips-modal");
    },
    saveDefaultProfile() {
      if (!this.defaultSaving) {
        setTimeout(() => {
          this.defaultSaving = false;
        }, 2000);
      }
      this.defaultSaving = true;
      bag.setSessionProfile({
        resolution: this.streamProfile.resolution,
        audioProfile: this.streamProfile.audioProfile,
        bitrate: this.streamProfile.bitrate,
        agc: this.streamProfile.agc,
        aec: this.streamProfile.aec,
        ans: this.streamProfile.ans,
      });
      bag.setDevicesProfile({
        audioId: this.streamProfile?.audioSource.deviceId,
        videoId: this.streamProfile?.videoSource.deviceId,
      });
    },
    toggleMenu() {
      if (!this.menuShown) {
        this.menuShowInit = true;
      }
      this.menuShown = !this.menuShown;
    },
    closeMenu() {
      if (this.menuShowInit) {
        return (this.menuShowInit = false);
      }
      this.menuShown = false;
    },
    init() {
      if (
        this.session.status === "ENDED" ||
        this.session.status === "EXPIRED"
      ) {
        return;
      }
      this.subscribeEvents();
    },
    subscribeEvents() {
      this.$agora.on(
        "devices-list",
        ({ audio, video, devicesInitialization }) => {
          this.audio = audio;
          this.video = video;
          const storedSources = bag.getDevicesProfile();
          if (devicesInitialization && storedSources) {
            if (storedSources.audioId) {
              const source = this.audio.find(
                (x) => x.deviceId === storedSources.audioId
              );
              if (source) {
                this.streamProfile.audioSource = source;
                this.$agora.setAudioSource(source.deviceId, this.streamProfile);
              }
            }
            if (storedSources.videoId) {
              const source = this.video.find(
                (x) => x.deviceId === storedSources.videoId
              );
              if (source) {
                this.streamProfile.videoSource = source;
                this.$agora.setVideoSource(source.deviceId, this.streamProfile);
              }
            }
          }
          if (this.streamProfile.audioSource) {
            const source = this.audio.find(
              (x) => x.deviceId === this.streamProfile.audioSource.deviceId
            );
            if (!source) {
              this.streamProfile.audioSource = null;
              this.$agora.error(`Stream. No Audio devices found`);
            }
          }
          if (this.streamProfile.videoSource) {
            const source = this.video.find(
              (x) => x.deviceId === this.streamProfile.videoSource.deviceId
            );
            if (!source) {
              this.streamProfile.videoSource = null;
              this.$agora.error(`Stream. No Video devices found`);
            }
          }
          if (!this.streamProfile.audioSource && audio.length) {
            this.streamProfile.audioSource = this.audio[0];
            this.$agora.setAudioSource(
              this.streamProfile.audioSource.deviceId,
              this.streamProfile
            );
          }
          if (!this.streamProfile.videoSource && video.length) {
            this.streamProfile.videoSource = this.video[0];
            this.$agora.setVideoSource(
              this.streamProfile.videoSource.deviceId,
              this.streamProfile
            );
          }
        }
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
