 <template>
    <b-navbar id="navbar" toggleable="md" :class="headerType">
        <b-container v-if="headerType === 'strict'">
            <div class="navbar-header">
                <b-navbar-brand href="/">
                    <img src="/images/logo-white.svg" alt="2GTHR Logo">
<!--                    <span class="beta">BETA</span>-->
                </b-navbar-brand>
            </div>
        </b-container>
        <b-container v-if="headerType !== 'strict'">
            <div class="navbar-header">
                <b-navbar-brand :to="{name: 'home'}">
                    <img src="/images/logo-white.svg" alt="2GTHR Logo">
<!--                    <span class="beta">BETA</span>-->
                </b-navbar-brand>
            </div>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item :class="{active: setActiveLink('upcoming')}" @click="clickRouteLink('upcoming')">Upcoming</b-nav-item>
                    <b-nav-item :class="{active: setActiveLink('catch-up')}" @click="clickRouteLink('catch-up')">Catch Up</b-nav-item>
                    <b-nav-item :class="{active: setActiveLink('artists')}" @click="clickRouteLink('artists')">Artists</b-nav-item>
                    <b-nav-item :href="shopLink" target="_blank">Shop</b-nav-item>
<!--                    <b-nav-item :class="{active: setActiveLink('about')}" @click="clickRouteLink('about')">About</b-nav-item>-->
                    <b-nav-item :class="{active: setActiveLink('blog')}" @click="clickRouteLink('blog')">Blog</b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">

                    <b-navbar-nav v-if="user" class="right-nav">
                        <b-nav-item  @click="clickRouteLink('my-schedule')">My Schedule</b-nav-item>
                    </b-navbar-nav>

                    <b-nav-item-dropdown v-if="user" class="navbar-user">
                        <template v-slot:button-content>
                            <div class="user-badge"></div>
                        </template>
                        <b-dropdown-item :to="{name: 'account-settings'}">Account</b-dropdown-item>
                        <b-dropdown-item @click="signOut">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-navbar v-if="!user">
                        <b-button variant="black" :to="{name: 'login'}">Login</b-button>
                        <b-button variant="blue" :to="{name: 'register'}" class="semibold">Get Started</b-button>
                    </b-navbar>

                </b-navbar-nav>
            </b-collapse>
        </b-container>
    </b-navbar>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";
    import { security } from "@/services";
    import {SHOP_URL} from "@/shared/constants";

    export default {
        name: 'Navbar',
        data() {
          return {
            shopLink: SHOP_URL,
          }
        },
        computed: {
            ...mapGetters({
                user: "user"
            }),
            headerType() {
                if (['session'].includes(this.$route.name)) {
                    return 'strict';
                }
                return 'full'
            },
        },
        methods: {
            ...mapActions([
                'setUser',
                'setAuthToken',
            ]),
            clickRouteLink(routeName) {
                if (this.$route.name === routeName) {
                    return location.reload();
                }
                return this.$router.push({name: routeName})
            },
            signOut() {
                security.signOut();
                if (this.$route.name !== 'home') {
                    this.$router.replace({name: 'home'})
                }
            },
            setActiveLink(routeName) {
              return this.$route.name === routeName;
            }
        },
    }
</script>
<style lang="scss" scoped>
  @import "~@/assets/styles/_color-variables.scss/";
  .nav-item {
    &.active {
      a {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 4px;
          right: 4px;
          height: 3px;
          background-color: $c-cyan;
        }
        @media (max-width: 768px) {
          display: inline-block;
          &::before {
            left: 0px;
            right: 0px;
          }
        }
      }
    }
  }
</style>



