<template>
  <div class="page billing-page">
    <validation-observer ref="validationObserver" >
      <section>
        <b-container>
          <b-row>
            <b-col>
              <div class="form-wrapper mb-30">
                <b-table
                  :items="billings"
                  :fields="fields"
                  :busy="isBusy"
                  show-empty
                  table-variant="dark"
                  no
                >
                  <template #cell(actions)="">
                    <loading-button
                      size="sm"
                      variant="secondary"
                      :is-loading="isLoading"
                      @click-handler="changePaymentMethod"
                    >
                      <template #content>Change card</template>
                    </loading-button>
                  </template>
                  <template #table-busy>
                    <div class="text-center text-white my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </validation-observer>
    <modal is-show="modalShow"/>
  </div>
</template>

<script>

import {api, security} from "@/services";
import {mapGetters, mapActions} from "vuex";
import env from '../../../configuration/env'
import {loadStripe} from "@stripe/stripe-js";
import LoadingButton from "@/components/shared/Common/LoadingButton";
import Modal from "@/components/shared/Common/Modal";

  export default {
    name: 'BillingPage',
    components: {Modal, LoadingButton},
    data () {
      return {
        fields: [
          {
            key: 'cardBrand',
            label: 'brand',
            class: 'text-center'
          },
          {
            key: 'cardLast4',
            label: 'last 4',
            class: 'text-center'
          },
          {
            key: 'actions',
            label: 'actions',
            class: 'text-center'
          }
        ],
        billings: [],
        isLoading: false,
        isBusy: true,
        modalShow: false,
        env: env
      }
    },
    computed: {
      ...mapGetters([
        'user',
        'billing',
      ]),
    },
    methods: {
      ...mapActions([
        'setAuthBilling',
      ]),
      getBilling() {
        api.accounts.getBilling()
          .then(({ data }) => {
            if (data && data.item) {
              this.billings = [data.item];
            }
            this.isBusy = false;
          })
      },
      async changePaymentMethod() {
        this.isLoading = true;
        try {
          const { data } = await api.accounts.changePaymentMethod();
          if (data) {
            this.stripe.redirectToCheckout({ sessionId: data.id });
          }
        } catch (e) {
          console.log(e);
          this.$notify({type: 'error', text: 'Try again' })
        } finally {
          this.isLoading = false;
        }
      },
      async applyPaymentMethod(sessionId) {
        await this.$router.replace({ query: {} });
        this.modalShow = true;
        try {
          await api.accounts.applyPaymentMethod({sessionId});
          await this.getBilling();
          await security.forceRequestUser();
        } finally {
          this.modalShow = false;
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.getBilling();
        if (to.query.session_id) vm.applyPaymentMethod(to.query.session_id);
      });
    },
    async mounted() {
      this.stripe = await loadStripe(env.stripe.publishableKey, {
        locale: "en",
        apiVersion: "2020-08-27",
      });
    }
  }
</script>
