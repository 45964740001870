import * as types from '../types'

const state = {
  user: null,
  billing: null,
  subscriptions: [],
  trialStarted: false,
  internalPromotionCodeInfo: null,
  permissions: [],
};

const getters = {
  user: state => state.user,
  billing: state => state.billing,
  subscriptions: state => state.subscriptions,
  subscriptionType: state => name => state.subscriptions.find(item => item.name === name),
  trialStarted: state => state.trialStarted,
  internalPromotionCodeInfo: state => state.internalPromotionCodeInfo,
  permissions: state => state.permissions,
};

const mutations = {
  [types.SET_AUTH_USER] (state, user) {
    state.user = user;
  },
  [types.SET_AUTH_BILLING] (state, billing) {
    state.billing = billing;
  },
  [types.SET_AUTH_SUBSCRIPTIONS] (state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  [types.SET_TRIAL_STARTED] (state, hasStarted) {
    state.trialStarted = hasStarted;
  },
  [types.SET_INTERNAL_PROMO_INFO] (state, internalPromotionCodeInfo) {
    state.internalPromotionCodeInfo = internalPromotionCodeInfo;
  },
  [types.SET_PERMISSIONS] (state, permissions) {
    state.permissions = permissions;
  }
};

const actions = {
  setAuthUser: ({commit}, user) => {
    commit(types.SET_AUTH_USER, user);
  },
  setAuthBilling: ({commit}, billing) => {
    commit(types.SET_AUTH_BILLING, billing);
  },
  setAuthSubscriptions: ({commit}, subscriptions) => {
    commit(types.SET_AUTH_SUBSCRIPTIONS, subscriptions);
  },
  setAuthTrialStarted: ({commit}, hasStarted) => {
    commit(types.SET_TRIAL_STARTED, hasStarted);
  },
  setInternalPromotionCodeInfo: ({commit}, internalPromotionCodeInfo) => {
    commit(types.SET_INTERNAL_PROMO_INFO, internalPromotionCodeInfo);
  },
  setPermissions: ({commit}, permissions) => {
    commit(types.SET_PERMISSIONS, permissions);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
}
