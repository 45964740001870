<template>
    <div class="page about-page">
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <h1 class="text-stroke mb-120">About</h1>

                        <b-row class="mb-80">
                            <b-col cols="12">
                                <div class="about-banner-wrapper">
                                    <b-row>
                                        <b-col lg="5" md="7" sm="12" cols="12">
                                            <img src="/images/public/about/Marc.jpg"/>
                                        </b-col>
                                        <b-col lg="6" md="5" sm="12" cols="12" class="col-l">
                                            <h2>Marc DiLorenzo</h2>
                                            <p><strong>Founder</strong></p>
                                            <p>As a lifelong guitar player and music enthusiast, Marc DiLorenzo founded 2GTHR to create the experience he couldn’t find anywhere.</p>
                                            <p>Frustrated by how “not live” most livestreams felt, Marc developed a new format by combining high-def live-stream music performance, with direct on-screen conversation between artists and fans.</p>
                                            <p>For most of his career, Marc’s day gig has been to oversee strategy and corporate development for leading media companies including Fox Television, 20th Century Fox, and Disney, helping to bring their video businesses into the streaming age. That is, except for a three-year sabbatical in the 2000’s, during which Marc worked full-time as a music composer for film and television.</p>
                                            <p>Whenever he gets the chance, he still enjoys playing LA clubs with his band, and looks forward to getting back to that. He left the corporate world to found 2GTHR, based on his vision of a music platform that dissolves the barriers between artists and fans. He could not be more excited to combine his digital experience and love for music, to create a community where like-minded fans get closer to their favorite artists.</p>
                                            <p>Marc holds a BA from Columbia University and an MBA from The Wharton School, and lives in Los Angeles with his wife and two children.</p>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="mb-80" v-if="featuredArticles && featuredArticles.length">
                            <b-col lg="4" cols="12" class="mb-60">
                                <h2 class="text-stroke">Press</h2>
                            </b-col>
                            <b-col lg="8" cols="12">
                                <a class="press" :href="article.linkUrl" target="_blank" v-for="article in featuredArticles" v-bind:key="article.id">
                                    <div class="press-banner" >
                                        <div>
                                            <img v-if="article.imageUrl" :src="article.imageUrl"/>
                                        </div>
                                        <div class="content-wrapper">
                                            <p class="h3 mb-2" v-if="article.title">{{ article.title }}</p>
                                            <p class="h4" v-if="article.description">{{ article.description }}</p>
                                            <p>
                                                <a :href="article.linkUrl" target="_blank" class="link">
                                                    <small v-if="article.linkTitle">{{ article.linkTitle }}</small>
                                                    <small v-if="!article.linkTitle">Link</small>
                                                </a>
                                                <span v-if="article.postDate" class="date"><small>{{ article.postDate | moment('MMMM D, YYYY')}}</small></span>
                                            </p>
                                        </div>

                                    </div>
                                </a>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'About',
    computed: {
        ...mapGetters([
            'featuredArticles',
        ]),
    },
    methods: {
        ...mapActions([
            'initFeaturedArticles'
        ]),
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.initFeaturedArticles();
        });
    },
}
</script>
