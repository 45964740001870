import Home from '@/components/pages/Home';
import Login from '@/components/pages/Auth/Login';
import Register from '@/components/pages/Auth/Register';
import EmailRegister from '@/components/pages/Auth/EmailRegister';
import PasswordForgot from '@/components/pages/Auth/PasswordForgot';
import PasswordReset from '@/components/pages/Auth/PasswordReset';
import GetStarted from '@/components/pages/Account/GetStarted';
import Account from '@/components/pages/Account/Account';
import AccountSubscription from '@/components/pages/Account/Subscription';
import AccountBilling from '@/components/pages/Account/Billing';
import AccountSettings from '@/components/pages/Account/Settings';
import OptOut from '@/components/pages/Account/OptOut';
import Artists from '@/components/pages/Content/Artists';
import CatchUp from '@/components/pages/Content/CatchUp';
import MySchedule from '@/components/pages/Content/MySchedule';
import Session from '@/components/pages/Session/Session';
import Upcoming from '@/components/pages/Content/Upcoming';
import PrivacyPolicy from '@/components/pages/Public/PrivacyPolicy';
import TermsOfService from '@/components/pages/Public/TermsOfService';
import FAQ from '@/components/pages/Public/FAQ';
import AccessDenied from '@/components/pages/Static/AccessDenied';
import SubscriptionSuccess from '@/components/pages/Static/SubscriptionSuccess';
import VerificationEmail from '@/components/pages/Verification/Email';
import { anonymousMiddleware, authedAndEmailVerifiedMiddleware } from '@/middlewares';
import VueRouter from "vue-router";
import getStartedMiddleware from "../middlewares/getStartedMiddleware";
import { denyForInstructors } from "../middlewares/base/accesses";
import ContactUs from "@/components/pages/Public/ContactUs";
import About from "@/components/pages/Public/About";
import Blog from "@/components/pages/Public/Blog";
import Post from "@/components/pages/Public/Post";
import CheckoutError from "@/components/pages/Static/CheckoutError";
/*eslint no-undef: 0*/

const routes = [
    { path: '/', name: 'home', component: Home },
    { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy },
    { path: '/terms-of-service', name: 'terms-of-service', component: TermsOfService },
    { path: '/faq', name: 'faq', component: FAQ },
    { path: '/contact-us', name: 'contact-us', component: ContactUs },
    { path: '/about', name: 'about', component: About },
    { path: '/artists', name: 'artists', component: Artists },
    { path: '/artists/:slug', name: 'artist', component: Artists },
    { path: '/catch-up', name: 'catch-up', component: CatchUp },
    { path: '/catch-up/:courseId', name: 'catch-up-play', component: CatchUp },
    { path: '/upcoming', name: 'upcoming', component: Upcoming },
    { path: '/blog', name: 'blog', component: Blog },
    { path: '/blog/:postId', name: 'post', component: Post },
    {
        path: '/session/:courseId', name: 'session', component: Session, meta: {
            middleware: authedAndEmailVerifiedMiddleware
        }
    },
    {
        path: '/my-schedule', name: 'my-schedule', component: MySchedule, meta: {
            middleware: authedAndEmailVerifiedMiddleware,
        }
    },
    {
        path: '/login', name: 'login', component: Login, meta: {
            middleware: anonymousMiddleware,
        }
    },
    {
        path: '/register', name: 'register', component: Register, meta: {
            middleware: anonymousMiddleware,
        }
    },
    {
        path: '/register/:email', name: 'register-email', component: EmailRegister, meta: {
            middleware: anonymousMiddleware,
        }
    },
    { path: '/verification/email', name: 'verification-email', component: VerificationEmail },
    {
        path: '/password/forgot', name: 'password-forgot', component: PasswordForgot, meta: {
            middleware: anonymousMiddleware,
        }
    },
    { path: '/password/reset', name: 'password-reset', component: PasswordReset },
    { path: '/get-started', name: 'get-started', component: GetStarted },
    {
        path: '/account',
        component: Account,
        children: [
            {
                path: 'billing',
                name: 'account-billing',
                component: AccountBilling,
                meta: {
                    middleware: [
                        authedAndEmailVerifiedMiddleware,
                        denyForInstructors,
                        getStartedMiddleware,
                    ]
                },
            },
            {
                path: 'subscription',
                name: 'account-subscription',
                component: AccountSubscription,
                meta: {
                    middleware: [
                        authedAndEmailVerifiedMiddleware,
                        denyForInstructors,
                        getStartedMiddleware,
                    ]
                },
            },
            {
                path: 'settings',
                name: 'account-settings',
                component: AccountSettings,
                meta: {
                    middleware: authedAndEmailVerifiedMiddleware,
                },
            },
        ],
    },
    { path: '/account/opt-out/:token', name: 'opt-out', component: OptOut },
    {
        path: '/subscription/success', name: 'subscription-success', component: SubscriptionSuccess, meta: {
            middleware: authedAndEmailVerifiedMiddleware,
        }
    },
    {
        path: '/checkout/error', name: 'checkout-error', component: CheckoutError, meta: {
            middleware: authedAndEmailVerifiedMiddleware,
        }
    },
    { path: '/access-denied', name: 'access-denied', component: AccessDenied },
    { path: '*', redirect: '/404' },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: () => {
        return { y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.middleware) {
        if (!Array.isArray(to.meta.middleware)) {
            to.meta.middleware = [to.meta.middleware];
        }
        for (let middleware of to.meta.middleware) {
            const redirectTo = middleware();
            if (redirectTo) {
                if (typeof redirectTo.then === 'function') {
                    redirectTo.then((res) => {
                        if (res) {
                            return next(res);
                        }
                    })
                } else {
                    return next(redirectTo);
                }
            }
        }
    }
    next();
});

export default router;
