<template>
  <div class="page access-denied-page">
    <b-container>
      <section>
        <b-row class="mb-100">
          <b-col>
            <h1 class="text-stroke">Oops</h1>
          </b-col>
        </b-row>
      </section>
      <section>
        <b-row>
          <b-col>
            <div class="empty-state text-center">
              <div>
                <b-img class="mb-30" width="74px" src="/images/icons/x.svg" alt="Access denied"/>
              </div>
              <h3 class="mb-30 bold">Please check that your email is verified and billing information is current and try again.</h3>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'AccessDenied',
  }
</script>
