export default (data) => {
    if (!data) {
        return null;
    }
    return {
        id: data.id,
        nid: data.nid,
        email: data.email,
        fullName: data.fullName,
        isEmailVerified: data.isEmailVerified,
        vip: data.vip,
        role: data.role,
    }
}