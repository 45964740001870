<template>
  <div class="autoplay-video">
    <img
      class="w-100"
      :class="imgVideoClass"
      v-show="posterUrl && !loaded"
      :src="posterUrl"
      alt="Video Poster"
    />
    <video
      class="w-100"
      :class="imgVideoClass"
      v-show="loaded"
      :muted="muted"
      playsinline
      controlsList="nodownload"
      disablePictureInPicture
      :src="videoUrl"
      ref="videoRef"
    ></video>
    <b-button
      v-show="playing"
      variant="round"
      class="icon-mute"
      :class="{ muted }"
      @click="toggleMute"
    ></b-button>
    <b-button
      v-show="!playing && loaded"
      variant="round"
      class="icon-play"
      @click="playVideo"
    ></b-button>
  </div>
</template>


<script>
export default {
  name: "AutoplayVideo",
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    posterUrl: {
      type: String,
    },
    roundedTop: {
      type: Boolean,
    },
    roundedBottom: {
      type: Boolean,
    },
  },
  computed: {
    imgVideoClass: function () {
      return {
        "rounded-top": this.roundedTop,
        "rounded-bottom": this.roundedBottom,
      };
    },
  },
  data() {
    return {
      loaded: false,
      playing: false,
      muted: true,
    };
  },
  methods: {
    playVideo() {
      this.playing = true;
      this.$refs.videoRef.play();
    },
    toggleMute() {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.muted = this.$refs.videoRef.muted;
    },
    blockDownload() {
      this.$refs.videoRef.addEventListener("contextmenu", (e) => {
        e.preventDefault();
        return false;
      });
    },
  },
  mounted() {
    this.$refs.videoRef.addEventListener("loadedmetadata", () => {
      this.loaded = true;
      this.playVideo();
      this.blockDownload();
    });
    this.$refs.videoRef.addEventListener("ended", () => {
      this.playing = false;
    });
  },
};
</script>
