<template>
    <div id="upcoming-sessions-carousel">
        <div v-show="activeIndex > 0" class="button-prev" @click="movePrev">
            <img style="transform: scaleX(-1);" src="/images/icons/caret.svg">
        </div>
        <div v-show="nextBtnAvailable" class="button-next" @click="moveNext">
            <img src="/images/icons/caret.svg">
        </div>
        <div class="carousel-wrapper">
            <div :style="'margin-left:' + listOffset + 'px;'" class="sessions-list-wrapper">
                <course :class="shadowClass(index)" v-for="(course, index) in courses" v-bind:key="course.id" :course="course" :is-upcoming="true"></course>
            </div>
        </div>
    </div>
</template>
<script>

    import Course from "../Content/Course";

    export default {
        name: 'UpcomingSessions',
        props: {
            courses: {
                type: Array,
                required: true
            }
        },
        data () {
            return {
                itemWidth: 318,
                activeIndex: 0
            }
        },
        computed: {
            listOffset() {
                return -(this.activeIndex * this.itemWidth)
            },
            nextBtnAvailable() {
                return (this.activeIndex + this.activeItemsCount()) < this.courses.length;
            }
        },
        methods: {
            activeItemsCount() {
                const width = window.innerWidth;
                let activeItems = 4;
                if (width <= 768) {
                    activeItems = 1;
                } else if (width <= 991) {
                    activeItems = 2;
                } else if (width <= 1300) {
                    activeItems = 3;
                }
                return activeItems;
            },
            shadowClass(index) {
                const activeItems = this.activeItemsCount();
                if (index >= this.activeIndex && index < (this.activeIndex + activeItems)) {
                    return '';
                }
                return 'shadow';
            },
            moveNext() {
                const activeItems = this.activeItemsCount();
                if (this.activeIndex + activeItems < this.courses.length) {
                    this.activeIndex++;
                }
            },
            movePrev() {
                if (this.activeIndex > 0) {
                    this.activeIndex--;
                }
            },
            onResize() {
                this.$forceUpdate();
            }
        },
        created() {
            window.addEventListener("resize", this.onResize);
        },
        destroyed() {
            window.removeEventListener("resize", this.onResize);
        },
        components: {
            Course
        }
    }
</script>