<template>
    <div class="page privacy-policy-page">
        <section>
            <b-container>
                <b-col>
                    <h1 class="text-stroke mb-120">Privacy Policy</h1>

                    <p class="h4">This Privacy Policy was last revised on June 22, 2020.</p>
                    <p class="h4">Welcome to 2GTHR, a California LLC, (“2GTHR,” “2GTHR,” “we,” or “us”), streaming platform and digital performance platform consisting of web sites, services, and software applications, (the “2GTHR Service”). 2GTHR values the privacy of the users, subscribers, publishers, members, and others who visit and use the 2GTHR Service (collectively or individually, “you,” “You” or “Users”).</p>
                    <p class="h4">We know that you care how information about you is used and shared. This Privacy Policy explains what information of yours will be collected by 2GTHR when you use the 2GTHR Service, how the information will be used, and how you can control the collection, correction and/or deletion of information. We will not use or share your information with anyone except as described in this Privacy Policy. This Privacy Policy does not apply to information we collect by other means (including offline) or from other sources. Capitalized terms that are not defined in this Privacy Policy are defined in our Terms of Service.</p>
                    <p class="h4">This Privacy Policy is incorporated into and is subject to 2GTHR’s Terms of Service.</p>
                    <p class="h4">By using the 2GTHR Service, you expressly consent to the information handling practices described in this Privacy Policy, and to the Terms of Service.</p>

                    <h2 class="mt-70">Information We Collect User-Provided Information.</h2>
                    <p class="h4">You provide us information about yourself, such as your name and e-mail address, if you register for a User account with the 2GTHR Service. If you correspond with us by email, we may retain the content of your email messages, your email address and our responses. We may also retain any messages you send through the 2GTHR Service. You may provide us information in Viewer Comments you post, stream or include in the 2GTHR Service (collectively “Viewer Comments”). We may collect payment information, such as your credit card number and expiration date, where appropriate to process a financial transaction you have requested. We may also collect information from publicly available sources or through third parties.  You may also provide customer content, which is information you upload, provide, create or transmit while using 2GTHR, and which is transmitted live through the 2GTHR service and also recorded and stored for later replay through the 2GTHR service.</p>

                    <h2 class="mt-70">Information About Your Use of the 2GTHR Service.</h2>
                    <p class="h4">In addition to the information you provide to us directly, we may collect information about your use of the 2GTHR Service. For example, we may collect:</p>
                    <p class="h4"><u>Device information</u> - such as your hardware model, IP address, other unique device identifiers, operating system version, and settings of the device you use to access the 2GTHR Service.</p>
                    <p class="h4"><u>Usage information</u> - such as information about the 2GTHR Service you use, the time and duration of your use of the 2GTHR Service and other information about your interaction with content offered through a Service, and any information stored in cookies and similar technologies on your device.</p>
                    <p class="h4"><u>Location information</u> - such as your computer’s IP address, your mobile device’s GPS signal or information about nearby WiFi access points and cell towers that may be transmitted to us when you use certain 2GTHR Service.</p>

                    <h2 class="mt-70">Information From Third-Party Sources.</h2>
                    <p class="h4">We may receive information about you from publicly and commercially available sources, as permitted by law, which we may combine with other information we receive from or about you. For example, we may receive information about you from a social media site if you connect to the 2GTHR Service through that site.</p>

                    <h2 class="mt-70">Other Information We Collect.</h2>
                    <p class="h4">We also may collect other information about you, your device, or your use of the services in ways that we describe to you at the point of collection or otherwise with your consent. You may choose not to provide us with certain types of information, but doing so may affect your ability to use some of the 2GTHR Service.</p>

                    <h2 class="mt-70">Cookies.</h2>
                    <p class="h4">Cookies are small files that are placed on your computer when you visit a website. Cookies may be used to store a unique identification number tied to your computer or device so that you can be recognized as the same user across one or more browsing sessions, and across one or more sites. Cookies serve many useful purposes. For example:</p>
                    <p class="h4">Cookies can remember your sign-in credentials so you do not have to enter those credentials each time you visit a Service.</p>
                    <p class="h4">Cookies can help us and third parties understand which parts of our Services are the most popular because they help us see which pages and features visitors access and how much time they spend on the pages. By studying this kind of information, we are able to adapt our 2GTHR Service and provide you with a better experience.</p>
                    <p class="h4">Most browsers accept cookies automatically, but can be configured not to do so or to notify the user when a cookie is being sent. If you wish to disable cookies, refer to your browser help menu to learn how to disable cookies. If you disable browser cookies or flash cookies, it may interfere with the proper functioning of the 2GTHR Service.</p>

                    <h2 class="mt-70">Beacons.</h2>
                    <p class="h4">We, along with third parties, also may use technologies called beacons (or “pixels”) that communicate information from your device to a server. Beacons can be embedded in online content, videos, and emails, and can allow a server to read certain types of information from your device, know when you have viewed particular content or a particular email message, determine the time and date on which you viewed the beacon, and the IP address of your device. We and third parties use beacons for a variety of purposes, including to analyze the use of our 2GTHR Service and (in conjunction with cookies) to provide content that is more relevant to you.</p>

                    <h2 class="mt-70">Local Storage & Other Tracking Technologies.</h2>
                    <p class="h4">We, along with third parties, may use other kinds of technologies, such as Local Shared Objects (also referred to as “Flash cookies”) and HTML5 local storage, in connection with our 2GTHR Service. These technologies are similar to the cookies discussed above in that they are stored on your device and can be used to store certain information about your activities and preferences. However, these technologies may make use of different parts of your device from standard cookies, and so you might not be able to control them using standard browser tools and settings. For HTML5 local storage, the method for disabling will vary depending on your browser.</p>

                    <h2 class="mt-70">Log File Information.</h2>
                    <p class="h4">Log file information is automatically reported by your browser each time you access a web page. When you use the 2GTHR Service, our servers automatically record certain information that your web browser sends whenever you visit any website. These server logs may include information such as your web request, Internet Protocol (“IP”) address, browser type, referring / exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information.</p>

                    <h2 class="mt-70">Clear Gifs Information.</h2>
                    <p class="h4">When you use the 2GTHR Service, we may employ clear gifs (also known as web beacons) which are used to track the online usage patterns of our users anonymously. No personally identifiable information from your 2GTHR account is collected using these clear gifs. In addition, we may also use clear gifs in HTML-based emails sent to our users to track which emails are opened by recipients. The information is used to enable more accurate reporting, improve the effectiveness of our marketing, and make 2GTHR better for our users.</p>

                    <h2 class="mt-70">How We Use Your Information.</h2>
                    <p class="h4">We use the personal information you submit to operate, maintain, and provide to you the features and functionality of the 2GTHR Service.</p>
                    <p class="h4">By providing 2GTHR your email address you consent to our using the email address to send you 2GTHR Service-related notices, including any notices required by law, in lieu of communication by postal mail. You also agree that we may send you notifications of activity on the 2GTHR Service to the email address you give us, in accordance with any applicable privacy settings. We may use your email address to send you other messages, such as newsletters, changes to features of the 2GTHR Service, or special offers. If you do not want to receive such email messages, you may opt out or change your preferences in your account page. Opting out may prevent you from receiving email messages regarding updates, improvements, or offers. You may not opt out of 2GTHR Service-related e-mails.</p>
                    <p class="h4">Following termination or deactivation of your account, 2GTHR may retain your profile information and Viewer Comments for a commercially reasonable time for backup, archival, or audit purposes. Furthermore, 2GTHR may retain and continue to use indefinitely all information contained in your communications to other Users posted to public or semi-public areas of the 2GTHR Service after termination or deactivation of your account.</p>
                    <p class="h4">2GTHR may use certain information about you and/or your Viewer Comments internally for purposes such as analyzing how the 2GTHR Service is used, diagnosing service or technical problems, maintaining security, and personalizing content.</p>
                    <p class="h4">2GTHR reserves the right, but has no obligation, to monitor the Viewer Comments you post or transmit on the 2GTHR Service. We reserve the right to remove any such information or material for any reason or no reason, including without limitation if in our sole opinion such information or material violates, or may violate, any applicable law or our Terms of Service, or to protect or defend our rights or property or those of any third party. 2GTHR also reserves the right to remove information upon the request of any third party.</p>
                    <p class="h4">We use cookies, clear gifs, and log file information to: (a) remember information so that you will not have to re-enter it during your visit or the next time you visit the site; (b) provide custom, personalized content and information; (c) monitor the effectiveness of our 2GTHR Service; (d) monitor aggregate metrics such as total number of visitors, traffic, and demographic patterns; (e) diagnose or fix technology problems reported by our Users or engineers that are associated with certain IP addresses; (f) help you efficiently access your information after you sign in; (g) track Viewer Comments and Users to the extent necessary to comply as a service provider with the Digital Millennium Copyright Act; and (h) automatically update any 2GTHR widgets or applications.</p>

                    <h2 class="mt-70">How We Share Your Information.</h2>
                    <p class="h4">The following provides information about entities with which we may share information. Our practices vary depending on the type of information.</p>

                    <h2 class="mt-70">Business Partners.</h2>
                    <p class="h4">We may share your information with business partners to provide you with services that you request. For example, if you sign up for a promotion that runs on our 2GTHR Service but that is sponsored or co-sponsored by another company, your information may be shared with that sponsor. 2GTHR is not responsible for the privacy practices of these entities.</p>

                    <h2 class="mt-70">Service Providers.</h2>
                    <p class="h4">We may share information with vendors providing contractual services to us, such as hosting vendors, service providers, and list managers. We also may share your information, including your payment information, as appropriate to process your payments for the 2GTHR Service or complete a transaction.</p>

                    <h2 class="mt-70">Other Parties When Required by Law or As Necessary to Protect Our Users and Services.</h2>
                    <p class="h4">We may share your personal information as we believe is necessary or appropriate to protect, enforce, or defend the legal rights, privacy, safety, or property of the 2GTHR Service, our employees or agents or users or to comply with applicable law or legal process, including responding to requests from public and government authorities.</p>

                    <h2 class="mt-70">Logging In Through Social Media Services.</h2>
                    <p class="h4">If you log into the 2GTHR Service with a social media service or if you connect a social media account with the 2GTHR Service, we may share your information with that social media service. The social media services’ use of the shared information will be governed by the social media services’ privacy policy and your social media account settings. If you do not want your information shared in this way, do not connect your social media service account with your account.</p>

                    <h2 class="mt-70">Third Parties that Provide Content and Functionality.</h2>
                    <p class="h4">Some of the content and functionality on our 2GTHR Service may be provided by third parties. These third-parties may collect or receive certain information about your use of the 2GTHR Service, including through the use of cookies, beacons, and similar technologies, and this information may be combined with information collected across different websites and online services.</p>

                    <h2 class="mt-70">Other Parties in Connection With a Corporate Transaction.</h2>
                    <p class="h4">We reserve the right to transfer any information we have about you in the event that we sell or transfer all or a portion of our business or assets to a third party, such as in the event of a merger, acquisition, or in connection with a bankruptcy reorganization.</p>

                    <h2 class="mt-70">Otherwise With Your Consent or At Your Direction.</h2>
                    <p class="h4">In addition to the sharing described in this Privacy Policy, we may share information about you with third parties whenever you consent to or direct such sharing.</p>

                    <h2 class="mt-70">Aggregated Data.</h2>
                    <p class="h4">We also may provide aggregated data about how users are using our 2GTHR Service to third parties.</p>

                    <h2 class="mt-70">Links to Third-Party Sites.</h2>
                    <p class="h4">2GTHR Service may link to third-party websites and services that are outside our control. We are not responsible for the security or privacy of any information collected by other websites or other services. You should exercise caution, and review the privacy statements applicable to the third-party websites and services you use.</p>
                    <p class="h4">Except as otherwise described in this Privacy Policy, 2GTHR will not disclose personal information to any third party unless required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law, comply with legal process served on us or our affiliates, or investigate, prevent, or take action regarding suspected or actual illegal activities; (b) to enforce our Terms of Service, take precautions against liability, to investigate and defend ourselves against any third-party claims or allegations, to assist government enforcement agencies, or to protect the security or integrity of our site; and (c) to exercise or protect the rights, property, or personal safety of 2GTHR, our Users or others.</p>

                    <h2 class="mt-70">Non-Personally Identifiable Information.</h2>
                    <p class="h4">We may share non-personally identifiable information (such as anonymous usage data, referring/exit pages and URLs, platform types, number of clicks, etc.) with interested third parties to help them understand the usage patterns for certain 2GTHR services.</p>

                    <h2 class="mt-70">How We Protect Your Information.</h2>
                    <p class="h4">2GTHR uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your personal information and implement your privacy settings. We cannot, however, ensure or warrant the security of any information you transmit to 2GTHR and you do so at your own risk. Once we receive your transmission of information, 2GTHR makes commercially reasonable efforts to ensure the security of our systems. However, this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. Your privacy settings may also be affected by changes to the functionality of 2GTHR’s distributors, such as providers of video transmission and recording. 2GTHR is not responsible for the functionality or security measures of any third party.</p>
                    <p class="h4">To protect your privacy and security, we take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to your email communications from 2GTHR, at all times.</p>

                    <h2 class="mt-70">Compromise of Personal Information.</h2>
                    <p class="h4">In the event that personal information is compromised as a result of a breach of security, 2GTHR will promptly notify those persons whose personal information has been compromised, in accordance with the notification procedures set forth in this Privacy Policy, or as otherwise required by applicable law.</p>

                    <h2 class="mt-70">Your Choices About Your Information.</h2>
                    <p class="h4">You may decline to submit personally identifiable information through the 2GTHR Service, in which case 2GTHR may not be able to provide certain services to you. You may update or correct your account information and email preferences at any time by logging in to your account. You can review and correct your information that 2GTHR keeps on file by contacting us directly.</p>

                    <h2 class="mt-70">Children's Privacy.</h2>
                    <p class="h4">2GTHR does not knowingly collect or solicit personal information from anyone under the age of 13 or knowingly allow such persons to register as users. If you are under 13, please do not send any information about yourself to us, including your name, address, telephone number, or email address. No one under age 13 is allowed to provide any personal information to or on 2GTHR. In the event that we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, you may contact us directly. </p>

                    <h2 class="mt-70">Links to Other Web Sites.</h2>
                    <p class="h4">We are not responsible for the practices employed by websites linked to or from the 2GTHR Service, nor the information or content contained therein. Please remember that when you use a link to go from the 2GTHR Service to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our website, is subject to that website's own rules and policies. Please read those rules and policies before proceeding.</p>

                    <h2 class="mt-70">Notification Procedures.</h2>
                    <p class="h4">It is our policy to provide notifications, whether such notifications are required by law or are for marketing or other business related purposes, to you via email notice, written or hard copy notice, or through conspicuous posting of such notice on the 2GTHR Service, as determined by 2GTHR in its sole discretion. We reserve the right to determine the form and means of providing notifications to you, provided that you may opt out of certain means of notification as described in this Privacy Policy.</p>

                    <h2 class="mt-70">Changes to Our Privacy Policy.</h2>
                    <p class="h4">If we change our privacy policies and procedures, we will post those changes on the 2GTHR Service to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    <p class="h4">If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this website, please contact us.</p>
                </b-col>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicyPage',
    }
</script>
