<template>
  <div id="app">
    <navbar/>
    <router-view/>
    <website-footer/>
    <notifications width="100%" classes="default-notifications" position="bottom center"/>
    <!--<intercom/>-->
    <alert/>
  </div>
</template>

<script>
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'
  import 'vueperslides/dist/vueperslides.css'
  import './assets/styles/styles.scss'
  import Navbar from '@/components/shared/Navbar';
  import WebsiteFooter from '@/components/shared/WebsiteFooter';
  import {mapGetters} from "vuex";

export default {
  name: 'App',
  metaInfo: {
    meta: [
      {vmid: 'og:type', property: 'og:type', content: 'website'},
      {vmid: 'og:locale', property: 'og:locale', content: 'en_us'},
      {vmid: 'og:site_name', property: 'og:site_name', content: '2GTHR'},
      {vmid: 'og:image', property: 'og:image', content: document.location.origin + '/images/content/home-3-category-music.jpg'},
    ]
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  components: {
    Navbar,
    WebsiteFooter,
  }
}

</script>

