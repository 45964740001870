<template>
  <b-modal id="global-message-modal"
           @hide="onModalHide"
           @shown="shownModal"
           :hide-header="true"
           :hide-footer="true"
           :hide-header-close="false"
           :centered="true"
           size="lg"
  >
    <div class="close-modal-btn"  @click="closeModal"></div>
    <div class="modal-body-wrapper ml-4 mr-5">
      <b-row>
        <b-col>
          <p class="h2 mt-40 mb-40">A message to the 2GTHR community</p>
        </b-col>
      </b-row>
      <div class="body-content">
        <b-col>
          <b-row class="paragraph">
             One year ago I had an idea - to create a space where artists and fans could interact, while generating a new
              revenue stream for musicians. As a huge music fan, this idea became a passion and over the past twelve months,
              I worked with a great team to make it a reality.
          </b-row>
          <b-row class="paragraph">
            At launch 2GTHR was free to users, while we added performances and features. With the beta period complete,
            we’re announcing monthly subscription pricing. You’ll now need a subscription to keep enjoying 2GTHR (but we’re including an additional free trial).
          </b-row>
          <b-row class="paragraph">
            I’ve tried to make the price accessible for as many fans as possible, at $9.99 per month when billed
            quarterly. For less than the cost of a ticket to one live-stream concert, you’ll get access to all the
            performances we offer each month – live and replay - and you’ll be supporting our incredible roster of
            artists. We’re excited for more great music in 2021, and on behalf of the artists, thanks for keeping the
            music playing!
          </b-row>
          <b-row class="paragraph">
            With appreciation,<br>
            Marc
          </b-row>
        </b-col>
      </div>
    </div>
  </b-modal>
</template>
<script>

export default {
  name: "GlobalMessageModal",
  data() {
    return {
      shown: false,
    }
  },
  methods: {
    shownModal() {
      this.shown = true;
    },
    onModalHide() {
      this.shown = false;
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'global-message-modal')
    },
  },
}
</script>