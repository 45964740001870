import Vue from 'vue'
import { security } from '@/services'
import router from '@/configuration/router'

export const apiErrorHandler = (error) => {
    let message;
    if (error.response) {
        const data = error.response.data;
        if (error.response.status === 422) {
            return;
        }
        if (data.code) {
            switch (data.code) {
                case 'UNAUTHORIZED':
                    security.signOut();
                    return router.push({ name: 'login' });
                case 'TOKEN_EXPIRED':
                    break;
                case 'USERNAME_TAKEN':
                case 'EMAIL_TAKEN':
                    return;
                case 'EMAIL_VERIFICATION':
                    return router.push({ name: 'verification-email' });
                case 'SUBSCRIPTION':
                    return router.push({ name: 'get-started' });
                default:
                    return;
            }
        }
        if (data && data.message && !(error.response.status === 403 && data.message.includes("finished"))) {
            message = data.message;
        }
    } else {
        message = error.message || error;
    }
    if (message) {
        Vue.notify({
            type: 'error',
            text: message
        });
    }
};
