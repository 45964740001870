export const plans = {
    computed: {
        allPlans() {
          return [
              {
                  name: 'annual',
                  planName: 'Annual',
                  price: '$89.99 billed annually ($7.50 per month, a 37% savings)',
                  isHidden: true,
                  costPerMonth: '7.50',
              },
              {
                  name: 'quarterly',
                  planName: 'Quarterly',
                  price: '$29.97 billed quarterly ($9.99 per month, a 16% savings)',
                  isHidden: false,
                  costPerMonth: '9.99',
              },
              {
                  name: 'monthly',
                  planName: 'Monthly',
                  price: '$11.99 billed monthly',
                  isHidden: false,
                  costPerMonth: '11.99',
              }
          ]
        },
        plans() {
           return this.allPlans.filter(p => !p.isHidden);
        },
        homePrice() {
          return this.plans
              .sort((a, b) => Number(a.costPerMonth) < Number(b.costPerMonth) ? -1 : 1)[0].costPerMonth;
        },
    },
}
