<template>
    <div v-if="session" class="right-control text-right">
        <ul class="list-inline list-unstyled list-buttons-inline">
            <li v-if="isGreenRoomAllowed && (session.status === 'UPCOMING' || session.status === 'GREEN_ROOM')">
                <b-button :disabled="true" variant="transparent" :class="{'highlight-disabled': session.status === 'GREEN_ROOM'}">Green Room</b-button>
            </li>
            <li v-if="isGreenRoomAllowed && session.status === 'STARTED'">
                <div class="live-state"><span>&bull;</span> LIVE</div>
            </li>
            <li v-if="isHostHere && (session.status === 'UPCOMING' || session.status === 'GREEN_ROOM')">
                <b-button
                    :disabled="isStarting || !isStartAvailable"
                    variant="blue"
                    @click="startSession"
                    :title="'You can start the session at ' + sessionOpenTime"
                >
                  Start Session
                </b-button>
            </li>
            <li v-if="canFinishSession && session.status === 'STARTED'">
                <b-button variant="black" :disabled="isStarting" @click="endSessionClick">End Session</b-button>
            </li>
        </ul>
        <confirmation-modal
            id="stop-session"
            message="Are you sure you want to end this Session?"
            cancel-button-text="Nevermind"
            confirm-button-text="Yes, Leave Session"
            :confirmed-cb="endSession"
        />
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import ConfirmationModal from "../Modals/Confirmation";
    import {permissions} from "@/mixins/permissions";
    export default {
        name: 'StreamControl',
        mixins: [permissions],
        components: {ConfirmationModal},
        props: {
            isHostHere: {
                type: Boolean
            },
            isGreenRoomAllowed: {
                type: Boolean
            },
            streamProfile: {
                type: Object,
                required: true
            },
            isStartAvailable: {
                type: Boolean
            },
        },
        data() {
            return {
                isStarting: false,
                hostStream: null,
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'session',
            ]),
            sessionOpenTime: function () {
                return this.$moment(this.session.schedule).format('h:mm A')
            },
        },
        methods: {
            ...mapActions([
                'setSessionStatus',
                'setInitStreamProfile',
            ]),
            init() {
                this.$agora.on('initialization-started', () => {
                    this.isStarting = true;
                });
                this.$agora.on('local-stream-started', () => {
                    this.isStarting = false;
                });
                this.$agora.on('local-stream-error', () => {
                    this.isStarting = false;
                });
                this.$agora.on('publish-stream-error', () => {
                    this.isStarting = false;
                });
                this.$agora.on('publish-stream-success', () => {
                    this.isStarting = false;
                });
                this.$agora.on('host-video-added', (hostStream) => {
                    this.hostStream = hostStream;
                });
            },
            startSession() {
              if (this.isHostHere) {
                this.isStarting = true;
                this.setInitStreamProfile(this.streamProfile);
                this.$agora.publishStream();
              }
            },
            endSessionClick() {
                this.$root.$emit('bv::show::modal', 'confirmation-modal-stop-session');
            },
            endSession() {
                if (this.canFinishSession) {
                    this.isStarting = true;
                    this.$agora.stopSession().finally(() => {
                        this.isStarting = false;
                    });
                }
            }
        },
        mounted() {
            this.init();
        },
        destroyed() {
            clearInterval(this.interval);
        },
    }
</script>
