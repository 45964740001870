var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-view auth-register"},[_c('div',{staticClass:"auth-page-bg"},[_c('div',{staticClass:"auth-container"},[_c('div',{staticClass:"auth-wrapper"},[_c('validation-observer',{ref:"validationObserver"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-form-group',[_c('label',{attrs:{"for":"form-input-fullName"}},[_vm._v("Full Name")]),_c('validation-provider',{attrs:{"vid":"fullName","rules":"required|max:256","mode":"eager","name":"Full Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-fullName"},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.fullName"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"form-input-password"}},[_vm._v("Password")]),_c('validation-provider',{attrs:{"vid":"password","rules":"required","mode":"eager","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"type":"password","id":"form-input-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))]),_c('b-button',{staticClass:"semibold",attrs:{"size":"lg","variant":"blue","type":"submit","disabled":_vm.apiRequesting}},[_vm._v("Create Account")]),_c('validation-provider',{attrs:{"vid":"accepted","mode":"eager","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('p',{staticClass:"error-msg relative-error-mgs",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))]),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-30",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.form.accepted),callback:function ($$v) {_vm.$set(_vm.form, "accepted", $$v)},expression:"form.accepted"}},[_vm._v(" By signing up, I agree the 2GTHR "),_c('router-link',{attrs:{"target":"_blank","to":{name: 'privacy-policy'}}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('router-link',{attrs:{"target":"_blank","to":{name: 'terms-of-service'}}},[_vm._v("Terms of Service")])],1)],1)]}}])})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }