import Vue from 'vue'
import env from './configuration/env'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import router from './configuration/router'
import store from './store'
import {BootstrapVue} from 'bootstrap-vue'
import VueIntercom from 'vue-intercom';
import {initializeApp} from "firebase/app";
import {browserStorage, security} from "./services";
import VueMoment from "vue-moment";
import moment from 'moment-timezone'
import InfiniteLoading from 'vue-infinite-loading';
import Notifications from 'vue-notification'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VClamp from "vue-clamp";
import VueMask from "v-mask";
import './utils/validation';
import kb from './plugins/kb';
import bag from './plugins/bag';
import Agora from './plugins/agora';
import Alert from './plugins/alert';
import analytics from './plugins/analytics';
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
import nameToChars from './filters/nameToChars'
import n2br from './filters/n2br'
import './directives/clickOutside';
import './directives/maxchars';
import './directives/uppercase';
import VueSocialSharing from 'vue-social-sharing'
import Message from './components/shared/GlobalMessage/Message';
import 'moment-duration-format';

if (env.appEnv === 'development') {
  Vue.config.devtools = true;
}

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueMoment, { moment });
Vue.use(VueIntercom, { appId: env.intercom.appId });
Vue.use(InfiniteLoading);
Vue.use(Notifications);
Vue.use(VueMeta);
Vue.use(new Agora(), {
  appId: env.agora.appId
});
Vue.use(VueCookies);
Vue.use(VueSocialSharing);
Vue.use(Alert);
Vue.use(kb);
Vue.use(bag);
Vue.use(analytics);
Vue.use(VueMask);

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('vue-clamp', VClamp);
Vue.component('global-message', Message);

Vue.filter('nameToChars', nameToChars);
Vue.filter('n2br', n2br);

initializeApp(env.firebase);

browserStorage.init();

security.init().then(() => {
  createApp();
});

const createApp = () => {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app');
};
