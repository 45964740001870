<template>
    <transition name="fade">
        <div class="alert-wrapper" :class="classes + ' alert-type-' + type" v-if="shown">
            <div class="body">
                <img @click="close" class="icon-close" src="/images/icons/x.svg" alt="Close"/>
                <img v-if="type === 'warn'" class="icon" src="/images/icons/alert.svg" alt="Alert"/>
                <img v-if="type === 'info'" class="icon icon-info" src="/images/icons/check-cyan.svg" alt="Info"/>
                <p>{{ text }}</p>
            </div>
        </div>
    </transition>
</template>
<script>

    import Alert from '../../../plugins/alert';

    export default {
        name: 'Alert',
        data() {
            return {
                type: null,
                name: null,
                exp: null,
                timeout: null,
                shown: false,
                text: '',
                force: false,
                classes: '',
            }
        },
        methods: {
            close() {
                if (this.exp && this.name) {
                    this.setAlertCookie();
                }
                this.hide();
            },
            hide() {
                this.shown = false;
            },
            getAlertCookie() {
                return this.$cookies.get(`alert-${this.name}`);
            },
            setAlertCookie() {
                this.$cookies.set(`alert-${this.name}`, +new Date(), this.exp);
            },
            show(params) {
                this.type = params.type;
                this.force = params.force;
                if (this.force && this.shown) {
                    this.hide();
                } else if (this.shown) {
                    return;
                }
                this.classes = params.classes || '';
                this.name = params.name;
                this.exp = params.exp;
                if (this.exp && this.name) {
                    const opened = this.getAlertCookie();
                    if (opened) {
                        return;
                    }
                }
                this.text = params.text;
                this.timeout = params.timeout;
                if (this.timeout) {
                    const self = this;
                    setTimeout(function() {
                        self.close();
                    }, self.timeout)
                }
                this.shown = true;
            }
        },
        beforeMount() {
            Alert.EventBus.$on('show', (params) => {
                this.show(params)
            })
        }
    }
</script>
