<template>
    <b-modal id="homepage-video-player-modal"
             @shown="onModalShown"
             @hide="onModalHide"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
             size="custom"
             dialog-class="video-disable-fullscreen"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div class="modal-body-wrapper">
            <div v-show="!playingStarted" class="control">
                <b-button variant="round" class="btn-gray icon-play-cyan mb-2" @click="playVideo"></b-button>
                <p class="h2">What is 2GTHR?</p>
                <p>Watch Now</p>
            </div>
            <div v-show="!playingStarted" class="disabled-wrapper"></div>
            <video
                    id="video-player"
                    class="video-player"
                    ref="videoRef"
                    :controls="playingStarted"
                    controlsList="nodownload"
                    disablePictureInPicture
            ></video>
        </div>
    </b-modal>
</template>

<script>

    import { mapGetters } from "vuex";

    export default {
        name: 'HomepageVideoPlayerModal',
        props: {
            videoUrl: {
                type: String,
                required: true
            },
            posterUrl: {
                type: String,
            },
            videoAutoplay: {
                type: Boolean,
            },
            closeCb: {
                type: Function
            }
        },
        data() {
            return {
                shown: false,
                playingStarted: false,
            }
        },
        computed: {
            ...mapGetters([
                'recording'
            ])
        },
        methods: {
            closeModal() {
                this.$root.$emit('bv::hide::modal', 'homepage-video-player-modal');
                if (this.closeCb && typeof this.closeCb === 'function') {
                    this.closeCb();
                }
            },
            onModalHide() {
                this.shown = false;
            },
            onModalShown() {
                setTimeout(() => {
                    this.shown = true;
                }, 400);
                this.$refs.videoRef.src = this.videoUrl;
                this.$refs.videoRef.poster = this.videoAutoplay ? '' : this.posterUrl;
                if (this.videoAutoplay) {
                    this.playingStarted = true;
                    this.$refs.videoRef.play();
                }
            },
            playVideo() {
                this.blockDownload();
                this.playingStarted = true;
                this.$refs.videoRef.play();
            },
            blockDownload() {
                document.getElementById('video-player').addEventListener('contextmenu',(e) => {e.preventDefault(); return false;});
            }
        },
    }
</script>