import axios from 'axios'
import axiosRetry from 'axios-retry';
import env from './env'
import store from '../store'
import { apiErrorHandler } from '../utils/apiErrorHandler'
import { security } from "../services";

const Api = axios.create({
    baseURL: env.apiBaseUrl,
    timeout: 30000,
});

axiosRetry(Api, {
    retries: 3, retryCondition: (error) => {
        return error?.response?.data?.code === 'UNAUTHORIZED';
    },
});

const addAuthHeader = () => {
    return new Promise((resolve) => {
        return security.getApiToken().then((token) => {
            resolve(token);
        }).catch((e) => {
            console.error(e);
            resolve(null);
        });
    })
};

Api.interceptors.request.use(
    config => {
        if (config.url !== 'agora/debug' && !config.url.endsWith('layout') && !config.url.endsWith('chat')) {
            store.dispatch('setApiRequesting', true);
        }
        return addAuthHeader().then((authToken) => {
            if (authToken) {
                config.headers.Authorization = 'Bearer ' + authToken;
            }
            return config;
        });
    }
);

Api.interceptors.response.use(
    response => {
        store.dispatch('setApiRequesting', false);
        return response;
    },
    error => {
        store.dispatch('setApiRequesting', false);
        apiErrorHandler(error);
        return Promise.reject(error);
    }
);

export default Api;
