<template>
  <div class="auth-view auth-register">
    <div class="register-content-wrapper position-relative">
      <b-container>
        <div class="register-form-wrapper position-relative">
          <div class="register-form-wrapper__after"></div>
          <b-row><b-col class="text-center"><h1 class="mb-40">Welcome👋</h1></b-col></b-row>
          <b-row>
            <b-col lg="6">
              <div class="video-block">
                <autoplay-video
                    class="w-100"
                    style="aspect-ratio: 600/502"
                    roundedTop
                    videoUrl="https://firebasestorage.googleapis.com/v0/b/gthr-2c4b2.appspot.com/o/assets%2FCompilation%20Ad%201%20Final.mp4?alt=media&token=67efb656-a078-4d31-a6f0-47c26c3e7c52"
                    posterUrl="https://firebasestorage.googleapis.com/v0/b/gthr-2c4b2.appspot.com/o/assets%2Fthumbnail.png?alt=media&token=2d05096e-fee2-49a2-bbad-94a60eb2e77c"
                />
              </div>
            </b-col>
            <b-col lg="6">
              <div class="form-block">
                <p class="h3 font-weight-normal">We’ve assembled an all-star roster of artists and 2GTHR is the only place you can watch them play in HD Video & Audio, then meet them face-to-face onscreen!</p>
                <p class="h4 font-weight-bold color-green">Instant Access</p>
                <ul>
                  <li class="h4">New live shows every week</li>
                  <li class="h4">Interactive virtual meet & greets every show</li>
                  <li class="h4">Hundreds of hours of replays available on-demand</li>
                </ul>
                <validation-observer ref="validationObserver">
                  <b-form @submit.prevent="submitForm">
                    <validation-provider
                      vid="fullName"
                      rules="required|max:256"
                      v-slot="{ classes, errors }"
                      mode="eager"
                      name="Full Name"
                    >
                      <b-input
                        v-model.trim="form.fullName"
                        id="form-input-fullName"
                        placeholder="Full name"
                        class="mb-4 mr-sm-2"
                        :class="classes"
                      />
                      <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                    </validation-provider>
                    <div class="d-flex">
                      <validation-provider
                        vid="email"
                        rules="required|email"
                        v-slot="{ classes, errors }"
                        mode="eager"
                        name="Email"
                        tag="div"
                        class="w-100 mr-3"
                      >
                        <b-input
                          v-model.trim="form.email"
                          placeholder="Email Address"
                          id="form-input-email"
                          class="mb-4 mr-sm-2"
                          :class="classes"
                        />
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                      <validation-provider
                        vid="password"
                        rules="required"
                        v-slot="{ classes, errors }"
                        mode="eager"
                        name="Password"
                        tag="div"
                        class="w-100"
                      >
                        <b-input
                          type="password"
                          v-model="form.password"
                          placeholder="Password"
                          id="form-input-password"
                          class="mb-4 mr-sm-2"
                          :class="classes"
                        />
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </div>
                    <p class="text-danger" v-show="error">{{ error }}</p>
                    <validation-provider
                      tag="div"
                      class="accepted-block"
                      vid="accepted"
                      v-slot="{ classes, errors }"
                      mode="eager"
                      name="Password"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          v-model="form.accepted"
                          :value="true"
                          :unchecked-value="false"
                          :class="classes"
                        >
                          By signing up, I agree the 2GTHR
                          <router-link
                            target="_blank"
                            :to="{ name: 'privacy-policy' }"
                          >
                            Privacy Policy
                          </router-link>
                          and
                          <router-link
                            target="_blank"
                            :to="{ name: 'terms-of-service' }"
                          >
                            Terms of Service
                          </router-link>
                        </b-form-checkbox>
                      </b-form-group>
                      <p class="error-msg relative-error-mgs" :state="errors.length == 0">{{ errors[0] }}</p>
                    </validation-provider>
                    <b-button
                      size="lg"
                      variant="blue"
                      type="submit"
                      class="semibold w-100 submit-btn mb-2"
                      :disabled="apiRequesting"
                    >
                      Try it free now
                    </b-button>
                    <div class="under-btn">
                      <p class="under-btn__text-left"><small>30-Day No-Obligation Trial</small></p>
                      <div class="d-flex">
                        <p class="mr-1"><small>Already have an account?</small></p>
                        <b-link :to="{ name: 'login' }">Login</b-link></div>
                    </div>
                  </b-form>
                </validation-observer>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <section class="section-artists">
        <b-container v-if="isLoadingArtists">
          <div class="artists-loading d-flex align-items-center justify-content-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-container>
        <b-container v-else>
          <b-row>
            <b-col>
              <h3 class="font-weight-normal">Some of our amazing <span class="font-weight-bold">2GTHR artists</span></h3>
            </b-col>
          </b-row>
          <b-row class="justify-content-start">
            <!-- eslint-disable-next-line -->
            <b-col v-for="artist in artists" :key="artist.name">
              <div class="artist-wrapper">
                <div class="artist-image mb-2" :style="{ backgroundImage: 'url(' + artist.imageUrl + ')'}"></div>
                <h4 class="artist-info">{{ artist.name }}</h4>
              </div>
            </b-col>
            <b-col class="flex-grow-0">
              <div class="artist-plus d-flex justify-content-start align-items-center">
                <div class="artist-plus-btn">
                  <b-link :to="{ name: 'artists' }">
                    <small class="artist-plus-btn__text font-weight-bold">+ many more!</small>
                  </b-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section-reviews">
        <b-container class="review-container">
          <b-row class="flex-wrap">
            <b-col md>
              <div class="reviews-wrapper d-flex">
                <div class="review-wrapper">
                  <div class="review-wrapper__stars d-flex">
                    <div v-for="(_, index) in 5" :key="index" class="star">
                      ★
                    </div>
                  </div>
                  <div class="review-wrapper__text h3 font-weight-normal">
                    “I feel right at home. I am SO glad I found this platform. Thank you!”
                  </div>
                  <div class="review-wrapper__footer d-flex">
                    <p class="footer-name h4">Robert W.</p>
                    <p class="footer-fan h4">FAN</p>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md>
              <div class="reviews-wrapper d-flex">
                <div class="review-wrapper">
                  <div class="review-wrapper__stars d-flex">
                    <div v-for="(_, index) in 5" :key="index" class="star">
                      ★
                    </div>
                  </div>
                  <div class="review-wrapper__text h3 font-weight-normal">
                    “Charlie, it made my dream come true to speak with you!”
                  </div>
                  <div class="review-wrapper__footer d-flex">
                    <p class="footer-name h4">Luke G.</p>
                    <p class="footer-fan h4">FAN</p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <div class="lines-block-one" />
      <div class="lines-block-two" />
      <div class="lines-block-three" />
    </div>
  </div>
</template>

<script>
import { api, security } from "@/services";
import { mapGetters } from "vuex";
import AutoplayVideo from "../../shared/AutoplayVideo.vue";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "Auth",
  data() {
    return {
      artists: [],
      isLoadingArtists: true,
      form: {
        fullName: "",
        email: "",
        password: "",
        accepted: false,
      },
      tab: "",
      error: null,
    };
  },
  async mounted() {
    this.isLoadingArtists = true;
    const { data } = await api.settings.getRegisteredArtists();
    this.artists = data.item.data;
    this.isLoadingArtists = false;
  },
  computed: {
    ...mapGetters(["apiRequesting"]),
  },
  methods: {
    async submitForm() {
      this.error = null;
      const isValid = await this.$refs.validationObserver.validate();
      if (!isValid) {
        return;
      }
      api.auth
        .register(this.form)
          .then((response) => {
            return firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                  return firebase
                      .auth()
                      .signInWithEmailAndPassword(
                          this.form.email,
                          this.form.password
                      );
                })
            .then(() => {
              security.setUserResponse(response.data);
              this.$bag.setItem("registered", 1);
              this.$analytics.dataLayerPush({
                event: "Create Account",
                category: "Subscribe-Music",
                action: "Create Account",
                label: window.location.href,
              });
              this.$analytics.redditPush("SignUp");
              return this.$router.replace({ name: "get-started" });
            });
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.validation) {
            let beValidation = {};
            for (const item of data.validation) {
              beValidation[item.field] = [item.message];
            }
            this.$refs.validationObserver.setErrors(beValidation);
          } else if (data.code && data.code === `USERNAME_TAKEN`) {
            this.$refs.validationObserver.setErrors({
              fullName: [data.message],
            });
          } else if (data.code && data.code === `EMAIL_TAKEN`) {
            this.$refs.validationObserver.setErrors({
              email: [data.message],
            });
          } else {
            this.signupError = data.message;
          }
        });
    },
  },
  components: { AutoplayVideo },
};
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/_color-variables.scss/";

  .register-content-wrapper {
    padding-top: 100px;
    @media (max-width: 768px) {
      padding-top: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1024px;
      top: 0;
      left: 0;
      right: 0;
      background-image: url("/images/content/new-register-bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.4;
      filter: blur(82px);
      z-index: -1;
    }
  }
  .register-form-wrapper::v-deep {
    padding: 0px 60px 60px 60px;
    @media (max-width: 768px) {
      padding: 0px;
    }
    .register-form-wrapper__after {
      position: absolute;
      left: 0;
      right: 0;
      top: 45px;
      height: 100%;
      background-color: $c-black;
      border-radius: 4px;
      @media (max-width: 768px) {
        top: -30px;
        left: -15px;
        right: -15px;
      }
    }
    .form-block {
      padding-left: 30px;
      @media (max-width: 768px) {
        padding-left: 0px;
      }
    }
    ul {
      list-style: none;
      padding-left: 20px;

      li {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          margin-top: 8px;
          left: -20px;
          background-color: $c-cyan;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
        }
      }
    }
    .custom-control-label {
      color: $c-border-gray;
    }
    .under-btn {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      &__text-left {
        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
    }
    .error-msg {
      position: relative;
      top: -20px;
    }
  }

  section {
    z-index: 2;
  }

  .section-artists {
    padding: 100px 0px 70px;
    @media (max-width: 768px) {
      padding: 40px 0px 00px;;
    }

    .artist-wrapper {
      width: 181px;
      @media (max-width: 450px) {
        min-width: 160px;
        width: 100%;
      }

      .artist-image {
        aspect-ratio: 182 / 120;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 4px 20px;
      }

      .artist-info {
        color: $c-white;
        margin-bottom: 30px;
      }
    }

    .artist-plus {
      width: 181px;
      aspect-ratio: 182 / 120;

      .artist-plus-btn {
        background-color: $c-cyan;
        padding: 5px 20px;
        border-radius: 4px 40px 40px 40px;

        &__text {
          color: $c-black;
        }
      }
    }
  }

  .section-reviews {
    @media (max-width: 768px) {
      padding-top: 34px;
    }
    .review-container {
      max-width: 818px;
    }
    .review-wrapper {
      max-width: 390px;
      margin-bottom: 30px;
      &__stars {
        margin-bottom: 15px;
        .star {
          font-size: 25px;
          line-height: 25px;
          color: $c-cyan;
          margin-right: 5px;
        }
      }
      &__text {
        margin-bottom: 15px;
      }
      &__footer {
        .footer-name {
          margin-right: 15px;
        }
        .footer-fan {
          color: $c-orange;
        }
      }
    }
  }

  .lines-block-one {
    position: absolute;
    bottom: -150px;
    left: 0;
    width: 539.5px;
    height: 539.5px;
    z-index: 1;
    background-image: url("/images/content/lines-block-one.svg");
    background-position: center;
    background-size: cover;
    @media (max-width: 768px) {
      width: 417.65px;
      height: 300px;
      background-image: url("/images/content/lines-block-one-mobile.svg");
      background-size: contain;
      background-repeat: no-repeat;
      left: 0px;
      background-position-x: 0px;
      bottom: 110px;
    }
  }

  .lines-block-two {
    position: absolute;
    bottom: -150px;
    left: 269px;
    width: 751px;
    height: 539.5px;
    z-index: 1;
    background-image: url("/images/content/lines-block-two.svg");
    background-position: center;
    background-size: cover;
    @media (max-width: 768px) {
      left: auto;
      right: 0px;
      bottom: 110px;
      width: 341px;
      height: 300px;
      background-image: url("/images/content/lines-block-two-mobile.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: initial;
    }
  }

  .lines-block-three {
    position: absolute;
    right: 0px;
    bottom: -150px;
    width: 539.5px;
    height: 539.5px;
    z-index: 1;
    background-image: url("/images/content/lines-block-three.svg");
    background-position: center;
    background-size: cover;
    @media (max-width: 768px) {
      background-size: contain;
      background-repeat: no-repeat;
      right: -1px;
      bottom: 110px;
      background-position: 100%;
      width: 417.65px;
      height: 300px;
      background-image: url("/images/content/lines-block-three-mobile.svg");
    }
  }

  .artists-loading {
    height: 300px;
  }

  .video-block {
    @media (max-width: 768px) {
      padding-right: 0px;
      margin-bottom: 20px;
    }
  }

  .submit-btn {
    padding: 10px 28px;
  }

  h1 {
    @media (max-width: 768px) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .h4 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .mb-40 {
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .custom-control-label {
    color: $c-border-gray;
  }

  .accepted-block {
    .error-msg {
      bottom: 0;
    }
  }
  .color-green {
    color: $c-cyan;
  }
</style>

