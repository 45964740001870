<template>
    <div class="page terms-of-service-page">
        <section>
            <b-container>
                    <b-col>
                        <h1 class="text-stroke mb-120" id="faq">Frequently Asked Questions</h1>

                        <h2 class="mt-70">How much does 2GTHR cost?</h2>
                        <p class="h4">View our subscription plans <router-link :to="{name: 'get-started'}">here</router-link> and try it out for free before paying anything. There are never any tickets to buy, or any upsells.</p>

                        <h2 class="mt-70">Do I have to pay for individual Sessions I want to watch?</h2>
                        <p class="h4">No, join as many Sessions as you want, everything’s included in your monthly subscription.</p>

                        <h2 class="mt-70">I’m not super tech-y.  Is this going to be a pain?</h2>
                        <p class="h4">Nope, it’s easier (and more fun) than all your video conference calls. Just follow the instructions below for <a href="#getting-started">Getting Started</a>.</p>

                        <h2 class="mt-70">Do I need to download any software?</h2>
                        <p class="h4">No, 2GTHR works right from your web browser, from any connected device.  We recommend using Chrome for the best experience.</p>

                        <h2 class="mt-70">Why didn’t I get an email confirmation for my Session?</h2>
                        <p class="h4">Check your spam or junk folders.  And remember you can always find all of your Sessions from <router-link :to="{name: 'my-schedule'}">My Schedule</router-link>.</p>

                        <h2 class="mt-70">Why isn’t every past Session available on the <router-link :to="{name: 'catch-up'}">Catch Up</router-link> page?</h2>
                        <p class="h4">We know that life happens, so we leave them up for a bit in case you missed something.  But the idea of 2GTHR is to come hang out with the Artists – LIVE!</p>

                        <h2 class="mt-70">Do you have a mobile or AppleTV app?</h2>
                        <p class="h4">We’re working on it.  But 2GTHR works in your mobile browser, and if you want to watch on AppleTV you can AirPlay from your computer.</p>

                        <h2 class="mt-70">Are more artists coming onboard?</h2>
                        <p class="h4">We’re talking to lots of Artists with more joining every month! Drop us a line <a href="mailto:info@2gthr.co">here</a> and let us know who you’d like to see!</p>

                        <h2 class="mt-70">I’m an Artist – how can I get on 2GTHR?</h2>
                        <p class="h4">We’d love to hear from you – hit us up <a href="mailto:info@2gthr.co">here</a>!</p>

                        <h2 class="mt-70">Still have questions? </h2>
                        <p class="h4">Reach out to us <a href="mailto:info@2gthr.co">here</a>.</p>

                        <h1 class="text-stroke mt-120 mb-120" id="getting-started">Getting Started</h1>

                        <p class="h3 mt-70">2GTHR recommends that you use the Chrome browser for the best experience.  We’ve designed a cutting edge interactive experience for you, and unfortunately some browsers like Safari just don’t support all of 2GTHR’s features.</p>
                        <p class="h3 mt-70">Log in at 2GTHR.co or create an account if you don’t already have one.  It can take up to 20 minutes to receive your confirmation e-mail, and please also check your spam or junk folder.</p>
                        <p class="h3 mt-70">Browse the site for <router-link :to="{name: 'upcoming'}"><strong>Upcoming Sessions</strong></router-link>.</p>
                        <p class="h3 mt-70">Add as many Sessions as you like by clicking Add to Schedule.</p>
                        <p class="h3 mt-70">We’ll send you a confirmation email with a calendar attachment so you can add the session to your calendar.  You’ll also receive a reminder e-mail 30 minutes before the session starts.</p>
                        <p class="h3 mt-70">When it’s time to join your Session, there are 2 easy ways to get there.  You can click the “Join Room” button in the emails you received and you’ll be taken right to your session.  Or, come to 2GTHR.co and go to <router-link :to="{name: 'my-schedule'}">My Schedule</router-link> – you’ll see your session there and you can click “Join Room”.</p>
                        <p class="h3 mt-70">When you join your Session, your browser may ask for permission to use your Camera and Microphone.  Please choose “Allow” so that you’ll be able to talk with the Artists!</p>
                        <p class="h3 mt-70">Click the Settings button to select your microphone and camera.  Join in and start chatting.  Or raise your hand to be brought onstage to ask your question on video – and smile!</p>
                        <p class="h3 mt-70">Any questions, just reach out to us <a href="mailto:info@2gthr.co">here</a>.</p>
                    </b-col>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'FAQ',
    }
</script>
