<template>
    <b-modal :id="'confirmation-modal' + (id ? '-' + id : '')"
             body-class="confirmation-modal"
             @hide="onModalHide"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
             size="lg"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div class="modal-body-wrapper">
            <h3>{{ message }}</h3>
            <div>
                <b-button variant="black" @click="confirmed">{{ confirmButtonText }}</b-button>
                <b-button variant="blue" @click="closeModal">{{ cancelButtonText }}</b-button>
            </div>
            <div class="bottom-note mt-30" v-if="bottomNote">{{ bottomNote }}</div>
        </div>
    </b-modal>
</template>

<script>

    export default {
        name: 'ConfirmationModal',
        props: {
            id: {
                type: String,
                required: false,
            },
            message: {
                type: String,
                required: true,
            },
            bottomNote: {
                type: String
            },
            confirmButtonText: {
                type: String,
            },
            cancelButtonText: {
                type: String,
            },
            confirmedCb: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                shown: false,
            }
        },
        methods: {
            onModalHide() {
                this.shown = false;
            },
            closeModal() {
                this.$root.$emit('bv::hide::modal', 'confirmation-modal' + (this.id ? '-' + this.id : ''))
            },
            confirmed() {
                this.confirmedCb();
                this.closeModal();
            }
        },
    }
</script>