import api from '../../configuration/api'

export const auth = {
  me: (data) => api.get('auth/me', data),
  login: (data) => api.post('auth/login', data),
  register: (data) => api.post('auth/register', data),
  checkEmail: (data) => api.get('auth/check/email', data),
  passwordForgot: (data) => api.post('auth/password/forgot', data),
  passwordReset: (data) => api.post('auth/password/reset', data),
  updateUser: (data) => api.put('auth/user', data),
};
