<template>
    <footer :class="footerType">
        <div v-if="footerType === 'strict'">
            <p v-if="isSessionPage" class="note">2GTHR recommends using Google Chrome for the best experience</p>
            <p class="text-center copyrigth">&copy;{{ new Date().getFullYear() }} 2GTHR LLC
                <a href="/terms-of-service">Terms of Service</a>
                &bull;
                <a href="/privacy-policy">Privacy policy</a>
                &bull;
                <router-link :to="{name: 'faq' }">FAQ</router-link>
            </p>
        </div>
        <div v-if="footerType === 'short'">
            <p class="text-center copyrigth">&copy;{{ new Date().getFullYear() }} 2GTHR LLC<br/>
                <router-link :to="{name: 'terms-of-service' }">Terms of Service</router-link>
                &bull;
                <router-link :to="{name: 'privacy-policy' }">Privacy policy</router-link>
                &bull;
                <router-link :to="{name: 'faq' }">FAQ</router-link>
            </p>
        </div>
        <div v-if="footerType === 'full'">
            <img class="lines-left" src="/images/lines-up-cyan.svg" alt="Lines">
            <img class="lines-right" src="/images/lines-up-blue.svg" alt="Lines Right">
            <div class="text-center mb-30 brand-logo">
                <img src="/images/logo-white.svg" alt="2GTHR Logo">
            </div>
            <h2 v-if="!hideTryItFree" class="position-relative text-center mb-40">Try it free <b-button class="ml-3 semibold" variant="blue" size="lg" :to="user ? {name: 'upcoming'} : {name: 'register'}">Get Started</b-button> </h2>
            <div class="footer-table">
                <div class="table-inline-row social-icons">
                    <div class="table-inline-col d-inline-flex col-3">
                        <a href="https://www.instagram.com/2gthrofficial" target="_blank">
                            <img src="/images/social/instagram.svg" alt="2GTHR Instagram">
                        </a>
                    </div>
                    <div class="table-inline-col d-inline-flex col-3">
                        <a href="https://twitter.com/2gthrofficial" target="_blank">
                            <img src="/images/social/twitter.svg" alt="2GTHR Twitter">
                        </a>
                    </div>
                    <div class="table-inline-col d-inline-flex col-3">
                        <a href="https://www.facebook.com/2GTHRofficial" target="_blank">
                            <img src="/images/social/facebook.svg" alt="2GTHR Facebook">
                        </a>
                    </div>
                    <div class="table-inline-col d-inline-flex col-3">
                      <a href="https://www.youtube.com/channel/UCjqCtdhKVSMeA0m--KZwTLA" target="_blank">
                        <img src="/images/social/youtube.svg" alt="2GTHR Youtube">
                      </a>
                    </div>
                </div>
                <div class="table-inline-row">
                    <div class="table-inline-col">
                        <router-link :to="{name: 'contact-us' }">Contact Us</router-link>
                        <p class="text-center copyrigth mt-2">
                            <span>&copy;{{ new Date().getFullYear() }} 2GTHR LLC</span>
                            <br/>
                            <router-link :to="{name: 'terms-of-service' }">Terms of Service</router-link>
                            &bull;
                            <router-link :to="{name: 'privacy-policy' }">Privacy policy</router-link>
                            &bull;
                            <router-link :to="{name: 'faq' }">FAQ</router-link>
                        </p>
                    </div>
                </div>
            </div>
            <div class="text-center mt-30">
                <a :href="appleStore" target="_blank">
                    <img class="app-store mr-2" src="/images/app-store-black.svg" alt="2GTHR App Store"/>
                </a>
                <a :href="googleStore" target="_blank">
                  <img class="app-store" src="/images/play-store-black.svg" alt="2GTHR Play Store"/>
                </a>
            </div>
        </div>

    </footer>
</template>

<script>
    import {mapGetters} from "vuex";
    import {constants} from "@/mixins/constants";
    export default {
        name: 'WebsiteFooter',
        mixins: [constants],
        computed: {
            ...mapGetters([
                'user',
            ]),
            footerType() {
                if (['session'].includes(this.$route.name)) {
                    return 'strict';
                }
                return [
                    'login',
                    'register',
                    'get-started',
                    'account-billing',
                    'subscription-success',
                    'access-denied',
                ].includes(this.$route.name) ? 'short' : 'full'
            },
            hideTryItFree() {
                return this.user;
            },
            isSessionPage() {
                return ['session'].includes(this.$route.name);
            },
        },
    }
</script>

