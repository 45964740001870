<template>
  <div class="page account-plan-page">
    <promo-subscription classes="empty-state" variant="subscription" />
    <b-container v-if="subscriptionType('default')">
      <b-row v-if="viewMode === 'active'">
        <b-col>
          <div v-if="planOnTrial" class="empty-state mb-30">
            <b-row>
              <b-col cols="8">
                <h3 class="mb-3">
                  <strong>
                    {{ promoSubscription
                      ? "After your Promotion code expires, your 2GTHR subscription will begin – with an extra month free!"
                      : "Your free trial is active"
                    }}
                  </strong>
                </h3>
                <p>
                  {{ promoSubscription ? "Your additional free month begins" : "The trial started" }}
                  {{ plan.trialStartAt | moment("MMMM D, YYYY") }} and ends
                  {{ plan.trialEndAt | moment("MMMM D, YYYY") }}.
                </p>
                <p>
                  {{ promoSubscription
                    ? `Your ${activePlanSlug} plan will begin billing on`
                    : `Your ${activePlanSlug} plan will begin on`
                  }}
                  {{
                    plan.trialEndAt
                      | moment("add", "1 day")
                      | moment("MMMM D, YYYY")
                  }}.
                </p>
              </b-col>
              <b-col cols="4" class="text-right">
                <img src="/images/icons/avatar-bg.svg" />
              </b-col>
            </b-row>
          </div>
          <div v-if="isPlanCancelled" class="empty-state mb-30">
            <b-row>
              <b-col cols="8">
                <p>
                  You have cancelled your subscription, effective
                  {{ plan.endAt | moment("MMMM D, YYYY") }}.
                </p>
                <p>
                  You can re-subscribe, and your new subscription will take
                  effect {{ plan.endAt | moment("MMMM D, YYYY") }}.
                </p>
              </b-col>
              <b-col cols="4" class="text-right">
                <img src="/images/icons/avatar-bg.svg" />
              </b-col>
            </b-row>
          </div>
          <div class="empty-state mb-30">
            <h3 class="mb-3"><strong>Your Plan</strong></h3>
            <b-row>
              <b-col cols="6">
                <plan
                  :plan-name="activePlan.planName"
                  :price="activePlan.price"
                  :is-active="activePlanSlug === activePlan.name"
                  :discount="discount"
                  @click.native="setSubscription(activePlan.name)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  class="mt-30"
                  variant="dark"
                  :disabled="apiRequesting"
                  @click="switchViewMode('plans')"
                  >Change Plan</b-button
                >
              </b-col>
            </b-row>
            <b-row class="mt-20">
              <b-col>
                <p>
                  See
                  <router-link :to="{ name: 'terms-of-service' }"
                    >Terms of Service</router-link
                  >
                  for more details
                </p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="viewMode === 'plans'">
        <b-col>
          <div class="empty-state mb-30">
            <h3 class="mb-3"><strong>Your Plan</strong></h3>
            <b-row class="mb-30">
              <b-col
                v-for="(plan, index) in plans"
                :key="index"
                cols="12"
                md="4"
              >
                <plan
                  class="mb-30"
                  :plan-name="plan.planName"
                  :price="plan.price"
                  :is-active="selectedSub === plan.name"
                  :is-current="activePlanSlug === plan.name"
                  @click.native="setSubscription(plan.name)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  variant="black"
                  :disabled="apiRequesting"
                  @click="switchViewMode('active')"
                  >Go Back</b-button
                >
                <b-button
                  v-if="
                    selectedSub !== activePlanSlug ||
                    (selectedSub === activePlanSlug && plan.canceledAt)
                  "
                  variant="blue"
                  :disabled="apiRequesting"
                  @click="attemptToChangePlan"
                  >Save Changes</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col>
              <b-button
                v-if="!isPlanCancelled"
                class="mb-5"
                size="sm"
                variant="black"
                :disabled="apiRequesting"
                @click="attemptToCancelSubscription"
                >Cancel My Subscription</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <confirmation-modal
      id="cancel-confirmation"
      message="Are you sure you want to cancel?"
      :bottom-note="'Your cancellation will take effect at the end of the current billing period.'"
      cancel-button-text="Nevermind"
      confirm-button-text="Yes, Cancel"
      :confirmed-cb="cancelSubscription"
    />
    <confirmation-modal
      id="resume-confirmation"
      message="You have previously cancelled your subscription, effective at the end of the current billing period. Would you like to re-instate your subscription?"
      cancel-button-text="Nevermind"
      confirm-button-text="Yes"
      :confirmed-cb="resumeSubscription"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import env from "../../../configuration/env";
import ConfirmationModal from "../../shared/Modals/Confirmation";
import { api } from "@/services";
import { formatCurrency } from "@/utils/formatters";
import {SubscriptionName} from "@/shared/enums"
import PromoSubscription from "@/components/shared/Account/PromoSubscription";
import Plan from '@/components/shared/Plans/Plan';
import {plans} from '@/mixins/plans';

export default {
  name: "AccountPlan",
  mixins: [plans],
  data() {
    return {
      viewMode: "active",
      error: null,
      selectedNewSub: null,
      discount: "",
    };
  },
  computed: {
    ...mapGetters(["apiRequesting", "subscriptions", "subscriptionType", "internalPromotionCodeInfo"]),
    promoSubscription() {
      return this.subscriptionType(SubscriptionName.PROMO);
    },
    plan() {
      return this.subscriptionType(SubscriptionName.DEFAULT);
    },
    isPlanCancelled() {
      return this.plan && this.plan.canceledAt;
    },
    planOnTrial() {
      const plan = this.plan;
      if (plan && !plan.canceledAt && plan.trialEndAt) {
        const now = Math.round(+new Date() / 1000);
        if (plan.trialEndAt > now) {
          return true;
        }
      }
      return false;
    },
    activePlanSlug() {
      if (!this.subscriptions || !this.subscriptions.length) {
        return null;
      }
      const subscription = this.subscriptionType(SubscriptionName.DEFAULT);
      if (!subscription) {
        return null;
      }
      const plans = {};
      plans[env.stripe.plans.quarterly] = "quarterly";
      plans[env.stripe.plans.monthly] = "monthly";
      plans[env.stripe.plans.annual] = "annual";
      if (!plans[subscription.planId]) {
        return null;
      }
      return plans[subscription.planId];
    },
    selectedSub() {
      if (this.selectedNewSub) {
        return this.selectedNewSub;
      }
      return this.activePlanSlug;
    },
    activePlan() {
      return this.allPlans.find(p => p.name === this.activePlanSlug);
    }
  },
  methods: {
    ...mapActions(["setAuthBilling", "setAuthSubscriptions"]),
    switchViewMode(to) {
      this.selectedNewSub = null;
      this.viewMode = to;
    },
    attemptToCancelSubscription() {
      this.$root.$emit(
        "bv::show::modal",
        "confirmation-modal-cancel-confirmation"
      );
    },
    cancelSubscription() {
      api.accounts
        .cancelSubscription({ planId: this.plan.planId })
        .then(({ data }) => {
          this.setAuthSubscriptions(data.items);
          if (!this.plan) {
            return this.$router.replace({ name: "upcoming" });
          }
          this.$alert.info({
            text: `Changes saved.`,
            timeout: 5000,
          });
          this.viewMode = "active";
          this.getSubscriptionDetails();
        });
    },
    setSubscription(slug) {
      this.selectedNewSub = slug;
    },
    attemptToChangePlan() {
      const planId = env.stripe.plans[this.selectedSub];
      if (this.selectedSub === this.activePlanSlug && this.plan.canceledAt) {
        this.$root.$emit(
          "bv::show::modal",
          "confirmation-modal-resume-confirmation"
        );
      } else {
        this.subscribe(planId);
      }
    },
    resumeSubscription() {
      const planId = env.stripe.plans[this.selectedSub];
      this.subscribe(planId);
    },
    subscribe(planId) {
      api.accounts
        .subscribe({ planId: planId })
        .then(({ data }) => {
          this.setAuthSubscriptions(data.items);
          this.$alert.info({
            text: `Changes saved.`,
            timeout: 5000,
          });
          this.viewMode = "active";
          this.getSubscriptionDetails();
        })
        .catch((err) => {
          this.cardError = err.response.data.message;
        })
        .finally(() => {
          this.isRequesting = false;
        });
    },
    formatDiscount(number) {
      return formatCurrency(number);
    },
    getSubscriptionDetails() {
      this.discount = "";
      if (!this.subscriptions || !this.subscriptions.length) {
        return null;
      }
      const subscription = this.subscriptionType("default");
      if (!subscription) {
        return null;
      }
      if (
        !subscription.discount ||
        subscription.discount.end * 1000 < Date.now()
      ) {
        return null;
      }
      const { percentOff, amountOff } = subscription.discount;
      this.discount = `${
        percentOff
          ? `${percentOff}%`
          : `$${this.formatDiscount(amountOff / 100)}`
      }`;
    },
  },
  created() {
    this.getSubscriptionDetails();
  },
  components: {
    PromoSubscription,
    Plan,
    ConfirmationModal,
  },
};
</script>
<style lang="scss" scoped>
  .subscriptions-wrapper {
    &:before {
      content: none;
    }
  }
</style>

