import NotificationAlert from '../components/shared/Notifications/Alert'

const Alert = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        this.EventBus = new Vue()
        Vue.component('alert', NotificationAlert);
        Vue.prototype.$alert = {
            warn(params) {
                params.type = 'warn';
                Alert.EventBus.$emit('show', params)
            },
            info(params) {
                params.type = 'info';
                Alert.EventBus.$emit('show', params)
            }
        }
    }
}

export default Alert;
