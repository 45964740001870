import api from '../../configuration/api'

export const courses = {
  list: (data) => api.get('courses', data),
  getOne: (id, data) => api.get('courses/' + id, data),
  start: (id, data) => api.post('courses/' + id + '/start', data),
  layout: (id, data) => api.post('courses/' + id + '/layout', data),
  stop: (id, data) => api.post('courses/' + id + '/stop', data),
  watch: (id, data) => api.get('courses/' + id + '/recordings', data),
  setPoster: (id, data) => api.put('courses/' + id + '/poster', data),
  ics: (id) => api.get('courses/' + id + '/ics', { responseType: 'blob' }),
  getStreamToken: (id, data) => api.get('courses/' + id + '/token/stream', data),
  getSignalToken: (id, data) => api.get('courses/' + id + '/token/signal', data),
  getSignalTokenVerification: (id, data) => api.get('courses/' + id + '/token/signal/verification', data),
  logChat: (id, data) => api.post('courses/' + id + '/chat', data),
  debug: (id, data) => api.post('courses/' + id + '/debug', data),
};
