export const API_REQUESTING = 'API_REQUESTING';

// Alerts
export const SET_ALERT = 'SET_ALERT';

// Artists
export const SET_ARTIST = 'SET_ARTIST';
export const SET_FEATURED_ARTIST = 'SET_FEATURED_ARTIST';
export const SET_FEATURED_ARTICLES = 'SET_FEATURED_ARTICLES';

// Auth
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_AUTH_BILLING = 'SET_AUTH_BILLING';
export const SET_AUTH_SUBSCRIPTIONS = 'SET_AUTH_SUBSCRIPTIONS';
export const SET_TRIAL_STARTED = 'SET_TRIAL_STARTED';
export const SET_INTERNAL_PROMO_INFO = 'SET_INTERNAL_PROMO_INFO';

// Categories
export const SET_CATEGORIES = 'SET_CATEGORIES';

// Recordings
export const SET_RECORDING = 'SET_RECORDING';
export const SET_SESSION_SHARING = 'SET_SESSION_SHARING';

// Session
export const SET_SESSION = 'SET_SESSION';
export const SET_SESSION_STATUS = 'SET_SESSION_STATUS';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const ADD_HAND_UP = 'ADD_HAND_UP';
export const REMOVE_HAND_UP = 'REMOVE_HAND_UP';
export const CLEAR_HAND_UP = 'CLEAR_HAND_UP';
export const ADD_STREAM = 'ADD_STREAM';
export const REMOVE_STREAM = 'REMOVE_STREAM';
export const ADD_STREAM_TO_REMOVE = 'ADD_STREAM_TO_REMOVE';
export const REMOVE_STREAM_FROM_REMOVE_STREAMS = 'REMOVE_STREAM_FROM_REMOVE_STREAMS';
export const CLEAR_STREAMS = 'CLEAR_STREAMS';
export const ADD_STREAM_TO_MAIN_VIEW = 'ADD_STREAM_TO_MAIN_VIEW';
export const REMOVE_STREAM_FROM_MAIN_VIEW = 'REMOVE_STREAM_FROM_MAIN_VIEW';
export const CLEAR_STREAMS_ON_MAIN_VIEW = 'CLEAR_STREAMS_ON_MAIN_VIEW';
export const SET_STREAMS_ON_MAIN_VIEW = 'SET_STREAMS_ON_MAIN_VIEW';
export const ADD_AUDIENCE = 'ADD_AUDIENCE';
export const SET_FULLSCREEN_MODE = 'SET_FULLSCREEN_MODE';
export const SET_INIT_STREAM_PROFILE = 'SET_INIT_STREAM_PROFILE';
export const ADD_AUDIO_AUTOPLAY_FAILED = 'ADD_AUDIO_AUTOPLAY_FAILED';
export const REMOVE_AUDIO_AUTOPLAY_FAILED = 'REMOVE_AUDIO_AUTOPLAY_FAILED';
export const CLEAR_AUDIO_AUTOPLAY_FAILED = 'CLEAR_AUDIO_AUTOPLAY_FAILED';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_HAND_UP_LIST = 'SET_HAND_UP_LIST';
