import Vue from 'vue'
import Vuex from 'vuex'
import alerts from './modules/alerts'
import artists from './modules/artists'
import auth from './modules/auth'
import common from './modules/common'
import courses from './modules/courses'
import session from './modules/session'
import acl from './modules/acl'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alerts,
    artists,
    auth,
    common,
    courses,
    session,
    acl,
  }
})
