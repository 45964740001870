<template>
  <section v-if="promoSubscription">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div :class="['bg-promo',  'mb-30', classes]">
            <h3 class="mb-3"><strong>You have an active 2GTHR promotion code</strong></h3>
            <template>
              <p v-if="isGetStartedVariant">Your active code is <strong class="color-green">{{ internalPromotionCodeInfo.code }}</strong>
                and is valid until {{ promoSubscription.endAt | moment("Do MMMM, YYYY")}}
              </p>
              <p v-else-if="isSubscriptionVariant">Your active code is <strong class="color-green">{{ internalPromotionCodeInfo.code }}</strong></p>
            </template>
            <template>
              <p v-if="isSubscriptionVariant">You’ll be able to enjoy the 2GTHR site free with this code until {{ promoSubscription.endAt | moment("MMMM D, YYYY")}}</p>
              <p v-else-if="isGetStartedVariant">You'll be able to enter a new Promo Code when your current code expires</p>
            </template>
            <p v-if="!plan && !isGetStartedVariant">
              <b-link class="link mr-1" :to="{name: 'get-started'}">Subscribe now</b-link>to maintain your access to 2GTHR after your code expires
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
  import {mapGetters} from "vuex";
  import {SubscriptionName} from "@/shared/enums";

  export default {
    name: 'PromoSubscription',
    props: {
      variant: {
        type: String,
        required: true,
      },
      classes: {
        type: String,
        required: false,
      },
    },
    computed: {
      ...mapGetters(["subscriptions", "subscriptionType", "internalPromotionCodeInfo"]),
      plan() {
        return this.subscriptionType(SubscriptionName.DEFAULT);
      },
      promoSubscription() {
        return this.subscriptionType(SubscriptionName.PROMO);
      },
      isSubscriptionVariant() {
        return this.variant === 'subscription';
      },
      isGetStartedVariant() {
        return this.variant === 'get-started';
      }
    },
  }
</script>
<style lang="scss" scoped>
  @import "~@/assets/styles/_color-variables.scss/";

  .bg-promo {
    background: rgb(25,27,31);
    background: linear-gradient(33deg, $c-dark-gray 51%, $c-blue 100%);
  }

  .color-green {
    color: $c-cyan;
  }
</style>
