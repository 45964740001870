import countries from './kb/countries.json'
import regions from './kb/regions.json'

export default {
    countries: countries,
    regions: regions,
    countriesList() {
        return this.countries;
    },
    countryRegions(cCode) {
        if (!this.regions[cCode]) {
            return null;
        }
        return this.regions[cCode];
    },
    install(Vue) {
        Vue.prototype.$kb = this;
    },
}
