<template>
    <b-modal id="session-sharing-modal"
             @hide="onModalHide"
             @shown="shownModal"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div class="modal-body-wrapper text-center">
            <b-img class="mb-30" width="74px" src="/images/icons/check-bg.svg" alt="You're in"/>
            <h3>This session was added to your <b-link :to="{name:'my-schedule'}">schedule</b-link>.</h3>
            <div class="gray-wrapper text-center">
                <h4>Let your friends know!</h4>
                <ul class="list-inline list-unstyled">
                    <li v-if="sessionSharing">
                        <ShareNetwork
                                network="twitter"
                                :url="url"
                                :title="title"
                                :description="title"
                        >
                            <img src="/images/social/twitter.svg" alt="2GTHR Twitter">
                        </ShareNetwork>
                    </li>
                    <li>
                        <ShareNetwork
                                network="facebook"
                                :url="url"
                                :title="title"
                                :description="title"
                                :quote="title"
                        >
                            <img src="/images/social/facebook.svg" alt="2GTHR Facebook">
                        </ShareNetwork>
                    </li>
                </ul>
            </div>
            <h4 class="note">Add this event to your Apple, Google, or Outlook calendar.</h4>
            <div>
                <b-button class="semibold" variant="blue" @click="downloadIcs">Add to Calendar</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import {api} from "../../../services";
    import { mapGetters } from "vuex";
    import env from '../../../configuration/env'
    import { saveAs } from 'file-saver';

    export default {
        name: 'SessionSharingModal',
        data() {
            return {
                shown: false,
                env: env,
            }
        },
        computed: {
            ...mapGetters([
                'sessionSharing',
            ]),
            url() {
                return window.location.href;
            },
            title() {
                if (!this.sessionSharing) {
                    return null;
                }
                return this.sessionSharing.artist.firstName + ' ' + this.sessionSharing.artist.lastName + ' is performing on @2GTHRofficial';
            },
        },
        methods: {
            shownModal() {
                setTimeout(() => {
                    this.shown = true;
                }, 400);
            },
            onModalHide() {
                this.shown = false;
            },
            closeModal() {
                this.$root.$emit('bv::hide::modal', 'session-sharing-modal')
            },
            downloadIcs() {
                api.courses.ics(this.sessionSharing.id).then(({data}) => {
                    const blob = new Blob([data], { type: 'text/calendar;charset=utf-8' });
                    saveAs(blob, 'invitation.ics');
                })
            }
        },
    }
</script>