export default {
    dataLayerPush(item) {
        if (window.dataLayer) {
            window.dataLayer.push(item)
        }
    },
    redditPush(eventName) {
        if (window.rdt) {
            window.rdt('track', eventName)
        }
    },
    install(Vue) {
        Vue.prototype.$analytics = this;
    },
}
