var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page settings-page"},[_c('validation-observer',{ref:"validationEmailObserver"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitEmail.apply(null, arguments)}}},[_c('section',[_c('b-container',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"form-wrapper mb-30"},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-30"},[_c('strong',[_vm._v("Update Your Email Address")])])]),_c('b-col',[(_vm.emailUpdated)?_c('p',{staticClass:"mb-30 text-right f-17"},[_vm._v("Email Updated "),_c('img',{staticClass:"inline-check",attrs:{"src":"/images/icons/check-bg.svg"}})]):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|email","mode":"passive","name":"Current Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"form-input-current-email"}},[_vm._v("Current Email Address")]),_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-current-email"},model:{value:(_vm.emailCurrent),callback:function ($$v) {_vm.emailCurrent=$$v},expression:"emailCurrent"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|email","mode":"passive","name":"New Email","vid":"newEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"form-input-new-email"}},[_vm._v("New Email Address")]),_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-new-email"},model:{value:(_vm.emailNew),callback:function ($$v) {_vm.emailNew=$$v},expression:"emailNew"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|email|confirmed:newEmail","mode":"passive","name":"New Email Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"form-input-new-email-confirmation"}},[_vm._v("Confirm New Email Address")]),_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-new-email-confirmation"},model:{value:(_vm.emailNewConfirmation),callback:function ($$v) {_vm.emailNewConfirmation=$$v},expression:"emailNewConfirmation"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.emailError),expression:"emailError"}],staticClass:"mt-2 error-text"},[_vm._v(_vm._s(_vm.emailError))]),_c('b-button',{staticClass:"mt-3 semibold",attrs:{"size":"lg","variant":"blue","type":"submit","disabled":_vm.apiRequesting}},[_vm._v("Submit")])],1)])],1)],1)],1)])]),_c('validation-observer',{ref:"validationPasswordObserver"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitPassword.apply(null, arguments)}}},[_c('section',[_c('b-container',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"form-wrapper mb-30"},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-30"},[_c('strong',[_vm._v("Update Your Password")])])]),_c('b-col',[(_vm.passwordUpdated)?_c('p',{staticClass:"mb-30 text-right f-17"},[_vm._v("Password Updated "),_c('img',{staticClass:"inline-check",attrs:{"src":"/images/icons/check-bg.svg"}})]):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","mode":"passive","name":"Current Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"form-input-current-password"}},[_vm._v("Current Password")]),_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-current-password","type":"password"},model:{value:(_vm.passwordCurrent),callback:function ($$v) {_vm.passwordCurrent=$$v},expression:"passwordCurrent"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","mode":"passive","name":"New Password","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"form-input-new-password"}},[_vm._v("New Password")]),_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-new-password","type":"password"},model:{value:(_vm.passwordNew),callback:function ($$v) {_vm.passwordNew=$$v},expression:"passwordNew"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|confirmed:newPassword","mode":"passive","name":"New Password Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"form-input-new-password-confirmation"}},[_vm._v("Confirm New Password")]),_c('b-input',{staticClass:"mb-4 mr-sm-2",class:classes,attrs:{"id":"form-input-new-password-confirmation","type":"password"},model:{value:(_vm.passwordNewConfirmation),callback:function ($$v) {_vm.passwordNewConfirmation=$$v},expression:"passwordNewConfirmation"}}),_c('p',{staticClass:"error-msg",attrs:{"state":errors.length == 0}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.passwordError),expression:"passwordError"}],staticClass:"mt-2 error-text"},[_vm._v(_vm._s(_vm.passwordError))]),_c('b-button',{staticClass:"mt-3 semibold",attrs:{"size":"lg","variant":"blue","type":"submit","disabled":_vm.apiRequesting}},[_vm._v("Submit")])],1)])],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }