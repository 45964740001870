<template>
    <div class="page account-page">
        <b-container>
            <b-row class="mb-30">
                <b-col>
                    <h1 class="text-stroke">Account</h1>
                </b-col>
            </b-row>
            <b-row class="mb-30">
                <b-col>
                    <ul class="account-menu list-inline">
                        <li v-if="!freeFromSubscription">
                            <b-link :to="{name:'account-subscription'}">Subscription</b-link>
                        </li>
                        <li v-if="!freeFromSubscription">
                            <b-link :to="{name:'account-billing'}">Billing</b-link>
                        </li>
                        <li>
                            <b-link :to="{name:'account-settings'}">Email / Password</b-link>
                        </li>
                    </ul>
                </b-col>
            </b-row>
            <router-view></router-view>
        </b-container>
    </div>
</template>

<script>
    import {permissions} from "@/mixins/permissions";
    export default {
        name: 'AccountPage',
        mixins: [permissions],
    }
</script>
