import { extend, configure } from 'vee-validate';
import { required, email, confirmed, max } from 'vee-validate/dist/rules';

// setInteractionMode('lazy');

configure({
    classes: {
        invalid: 'is-invalid',
    }
})

extend('email', email);
extend('max', max);
extend('required', {
    ...required,
    message: '{_field_} is required'
});
extend('confirmed', {
    ...confirmed,
    message: 'The {_field_} does not match'
});
