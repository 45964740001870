<template>
    <div class="page terms-of-service-page">
        <section>
            <b-container>
                <b-col>
                    <h1 class="text-stroke mb-120">Terms Of Service</h1>

                    <p class="h4">This Terms of Service document was last revised on June 22nd, 2020.</p>

                    <h2 class="mt-70">I. Introduction; Your Agreement to these Terms of Service.</h2>
                    <p class="h4">Welcome to 2GTHR, a California LLC, (“<strong>2GTHR</strong>” or “<strong>we</strong>”), streaming and digital performance platform consisting of web sites, services, and software applications (the “<strong>2GTHR Service</strong>” or “<strong>Service</strong>”). The following terms of service (the “<strong>Terms of Service</strong>”) for the 2GTHR Service is a legal contract between you, an individual user of at least 13 years of age or a single entity (“<strong>you</strong>”, “<strong>user</strong>” “<strong>User</strong>” or, collectively, “<strong>Users</strong>”), and 2GTHR regarding your use of the 2GTHR Service. By using and/or visiting the 2GTHR Service, you signify your agreement to (1) the Terms of Service; and (2) 2GTHR’ s privacy notice incorporated here by reference (the “<strong>Privacy Policy</strong>”).</p>
                    <p class="h4">Our Privacy Policy contains information about our policies and procedures regarding the collection, use and disclosure of information we receive from Users. If you do not consent to the Terms of Service, and the Privacy Policy, please do not use the 2GTHR Services.</p>
                    <p class="h4">2GTHR reserves the right, at its discretion, to change, modify, add, or remove portions of the Terms of Service at any time by posting amended terms to the Terms of Service. 2GTHR will attempt to notify you when material changes are made to the Terms of Service. Please check the Terms of Service periodically for changes. Your continued use of the 2GTHR Service after the posting of changes constitutes your binding acceptance of such changes.</p>

                    <h2 class="mt-70">II. Membership</h2>
                    <p class="h4">Your 2GTHR membership will continue and automatically renew until terminated. To use the 2GTHR service you must have Internet access and a connected device and provide us with a Payment Method. "Payment Method" means a current, valid, accepted method of payment, as may be updated from time to time. You must cancel your membership before it renews in order to avoid billing of the membership fees for the next billing cycle to your Payment Method. The length of your billing cycle will depend on the type of subscription that you choose when you signed up for the service. Membership fees are fully earned upon payment. We may authorize your Payment Method in anticipation of membership charges through authorizing it up to approximately one month of service as soon as you register.</p>

                    <h2 class="mt-70">III. Eligibility</h2>
                    <p class="h4">The 2GTHR Service is not available to persons under the age of 13 or to any users previously suspended or removed from the 2GTHR Service by 2GTHR. BY USING THE 2GTHR SERVICE, YOU REPRESENT THAT YOU ARE AT LEAST 13 YEARS OF AGE AND HAVE NOT BEEN PREVIOUSLY SUSPENDED OR REMOVED FROM THE 2GTHR SERVICE.</p>

                    <h2 class="mt-70">IV. Ownership of Materials; Limited License</h2>
                    <p class="h4">The data and materials on the 2GTHR Service, except the Artist Performances, including, without limitation, the text, graphics, interactive features, logos, photos, music, videos, software, and all other audible and visual materials, as well as the selection, organization, coordination, compilation and overall look and feel of the 2GTHR Service (collectively, the “<strong>Materials</strong>”) are the intellectual property of 2GTHR, its licensors and its suppliers. The Materials are protected by copyright, trade dress, patent, trademark and other laws, international conventions and proprietary rights and all ownership rights to the Materials remain with 2GTHR, its licensors or its suppliers, as the case may be. All trademarks, service marks, and trade names are proprietary to 2GTHR or its affiliates and/or third party licensors. You agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make use of the Materials. 2GTHR reserves all rights not expressly granted in and to the 2GTHR Service and the Materials.</p>

                    <h2 class="mt-70">V. 2GTHR Service License Grant</h2>
                    <p class="h4">2GTHR enables “<strong>Artists</strong>” to distribute and display streaming live videos (“<strong>Artist Performances</strong>”) and users referred to herein as “<strong>Viewers</strong>” to view the 2GTHR Performances and create and transmit textual and photographic and audio-visual content and comments (collectively, “<strong>Viewer Comments</strong>”).</p>
                    <p class="h4">If you sign up for a user account, subject to your compliance with the terms and conditions set out in the Terms of Service, 2GTHR hereby grants to you a personal, limited, non-exclusive, non-transferable, freely revocable license to use the 2GTHR Service for the viewing of Artist Performances.</p>

                    <h2 class="mt-70">VI. Viewer Comments </h2>
                    <p class="h4">You shall be solely responsible for your own Viewer Comments and the consequences of posting or publishing or transmitting them. You agree that You are solely responsible for the content and Viewer Comments sent or transmitted by You or displayed or uploaded by You in using the Services and for compliance with all Laws pertaining to the Viewer Comments, including, but not limited to, Laws requiring You to obtain the consent of a third party to use the Viewer Comments and to provide appropriate notices of third party rights. You represent and warrant that You have the right to upload the Viewer Comments to 2GTHR and that such use does not violate or infringe on any rights of any third party. Under no circumstances will 2GTHR be liable in any way for any (a) Viewer Comments transmitted or viewed while using the Services, (b) errors or omissions in the Viewer Comments, or (c) any loss or damage of any kind incurred as a result of the use of, access to, or denial of access to Viewer Comments. Although 2GTHR is not responsible for any Viewer Comments, 2GTHR may delete any Viewer Comments, at any time without notice to You, if 2GTHR becomes aware that it violates any provision of this Agreement, or any law. You retain copyright and any other rights You already hold in Viewer Comments which You submit, post or display on or through, the Services.</p>
                    <p class="h4">You are responsible for compliance will all recording laws. 2GTHR can choose to record and replay 2GTHR Artist Performances and Viewer Comments. By using the Services, you are giving 2GTHR consent to store and replay recordings for any or all Artist Performances that you join, including Viewer Comments. If you do not consent to being recorded and the replay by 2GTHR of these recordings, do not use the 2GTHR Service.</p>

                    <h2 class="mt-70">VII. Your Use of the 2GTHR Services</h2>
                    <p class="h4">You understand that when using the 2GTHR Service, you will be exposed to Artist Performances and Viewer Comments from a variety of sources, and that 2GTHR is not responsible for the accuracy, usefulness, safety, or intellectual property rights (except as otherwise explicitly stated) of or relating to such content. You further understand and acknowledge that you may be exposed to Viewer Comments that are inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against 2GTHR with respect thereto. 2GTHR does not endorse any Viewer Comments or any opinion, recommendation, or advice expressed therein, and 2GTHR expressly disclaims any and all liability in connection with the Viewer Comments. YOU AGREE TO WAIVE, AND HEREBY DO WAIVE, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST 2GTHR WITH RESPECT THERETO AND AGREE TO INDEMNIFY AND HOLD 2GTHR, ITS OWNERS/OPERATORS, AFFILIATES, SUPPLIERS, AND/OR LICENSORS HARMLESS TO THE FULLEST EXTENT ALLOWED BY LAW REGARDING ALL MATTERS RELATED TO VIEWER COMMENTS AND YOUR USE OF THE 2GTHR SERVICE.</p>
                    <p class="h4">Artist Performances and Viewer Comments are made available to you for your information and personal use solely as intended through the normal functionality of the 2GTHR Services. They are made available “as is”, and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, downloaded, or otherwise exploited in any manner not intended by the normal functionality of the 2GTHR Services or otherwise as prohibited under the Terms of Service. You may access Artist Performances and Viewer Comments for your information and personal use solely as intended through the provided functionality of the 2GTHR Services.</p>
                    <p class="h4">You agree not to circumvent, disable or otherwise interfere with security-related features of the 2GTHR Services or features that prevent or restrict use or copying of any content or enforce limitations on use of the 2GTHR Services or the Viewer Comments therein.</p>

                    <h2 class="mt-70">VIII. Prohibited Conduct</h2>
                    <p class="h4">BY USING THE 2GTHR SERVICE YOU AGREE NOT TO:</p>
                    <p class="h4">Use the 2GTHR Service for any purposes other than to receive original or appropriately licensed content and/or to access the 2GTHR Service as such services are offered by 2GTHR.</p>
                    <p class="h4">Rent, lease, loan, sell, resell, sublicense, distribute or otherwise transfer the licenses granted herein or any Materials.</p>
                    <p class="h4">Post, upload, or distribute any defamatory, libelous, or inaccurate content.</p>
                    <p class="h4">Assume a misleading or false identity in connection with your Viewer Comments.</p>
                    <p class="h4">Post, comment, display, upload, or distribute any content that is unlawful or that a reasonable person could deem to be objectionable, offensive, indecent, pornographic, invasive of another's privacy, harassing, threatening, embarrassing, distressing, hateful, racially or ethnically offensive, or otherwise inappropriate.</p>
                    <p class="h4">Post, upload, or distribute any programs that contain viruses, worms and/or Trojan horses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications.</p>
                    <p class="h4">Impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the 2GTHR Service accounts of others without permission, forge another persons' digital signature, misrepresent the source, identity, or content of information transmitted via the 2GTHR Service, or perform any other similar fraudulent activity.</p>
                    <p class="h4">Make unsolicited offers, advertisements, proposals, or send junk mail or spam to other users of the 2GTHR Service. This includes, but is not limited to, unsolicited advertising, promotional materials, or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signatures.</p>
                    <p class="h4">Use the 2GTHR Service for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property and other proprietary rights, and data protection and privacy.</p>
                    <p class="h4">Defame, harass, abuse, threaten or defraud Users of the 2GTHR Service, or collect, or attempt to collect, personal information about Users or third parties without their consent.</p>
                    <p class="h4">Remove, circumvent, disable, damage or otherwise interfere with security-related features of the 2GTHR Service or Viewer Comments, features that prevent or restrict use or copying of any content accessible through the 2GTHR Service, or features that enforce limitations on the use of the 2GTHR Service or Viewer Comments.</p>
                    <p class="h4">Reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the 2GTHR Service or any part thereof.</p>
                    <p class="h4">Modify, adapt, translate or create derivative works based upon the 2GTHR Service or any part thereof.</p>
                    <p class="h4">Intentionally interfere with or damage operation of the 2GTHR Service or any User's enjoyment of them, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code.</p>
                    <p class="h4">Relay email from a third party's mail servers without the permission of that third party.</p>
                    <p class="h4">Use any robot, spider, scraper, or other automated means to access the 2GTHR Service for any purpose or bypass any measures 2GTHR may use to prevent or restrict access to the 2GTHR Service.</p>
                    <p class="h4">Manipulate identifiers in order to disguise the origin of any Viewer Comments transmitted through the Service; or interfere with or disrupt the 2GTHR Service or servers or networks connected to the 2GTHR Service, or disobey any requirements, procedures, policies or regulations of networks connected to the 2GTHR Service.</p>
                    <p class="h4">Disrupt the normal flow of dialogue, or otherwise act in a way which affects the ability of other people to engage in real time activities via the 2GTHR Services.</p>

                    <h2 class="mt-70">IX. Account</h2>
                    <p class="h4">When you use the 2GTHR Service, you may be asked to provide a password. You are solely responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You agree that the information you provide to 2GTHR on registration and at all other times will be true, accurate, current, and complete. You also agree that you will ensure that this information is kept accurate and up-to-date at all times. If you have reason to believe that your account is no longer secure, then you agree to immediately notify 2GTHR. You may be liable for the losses incurred by 2GTHR or others due to any unauthorized use of your 2GTHR Service account.</p>

                    <h2 class="mt-70">X. Third-Party Sites, Products and Services; Links</h2>
                    <p class="h4">The 2GTHR Service may include links or references to other Web sites or services to Users (“<strong>Third Party Sites</strong>”). 2GTHR does not endorse any such Third Party Sites or the information, materials, products, or services contained on or accessible through Reference Sites. Access and use of Third Party Sites, including the information, materials, products, and services on or available through Third Party Sites is solely at your own risk.</p>

                    <h2 class="mt-70">XI. Termination; Terms of Service Violations</h2>
                    <p class="h4">You agree that 2GTHR, in its sole discretion, for any or no reason, and without penalty, may terminate any account you may have with 2GTHR or your use of the 2GTHR Service and remove and discard your account, or user profile at any time. 2GTHR may also in its sole discretion and at any time discontinue providing access to the 2GTHR Service, or any part thereof, with or without notice. You agree that any termination of your access to the 2GTHR Service or any account you may have may be effected without prior notice, and you agree that 2GTHR will not be liable to you or any third party for any such termination. Any suspected fraudulent, abusive or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies 2GTHR may have at law or in equity.</p>
                    <p class="h4">Your only remedy with respect to any dissatisfaction with the 2GTHR Service, any term of the Terms of Service, any policy or practice of 2GTHR in operating the 2GTHR Service, or any content or information transmitted through the 2GTHR Service, is to terminate the Terms of Service and your account. You may terminate the Terms of Service at any time by discontinuing use of any and all parts of the 2GTHR Service.</p>

                    <h2 class="mt-70">XII. Indemnification</h2>
                    <p class="h4">You agree to indemnify, save, and hold 2GTHR, its affiliated companies, contractors, employees, agents and its third-party suppliers, licensors, and partners harmless from any claims, losses, damages, liabilities, including legal fees and expenses, arising out of your use or misuse of the 2GTHR Service, any violation by you of the Terms of Service, or any breach of the representations, warranties, and covenants made by you herein. 2GTHR reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify 2GTHR, and you agree to cooperate with 2GTHR's defense of these claims. 2GTHR will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.</p>

                    <h2 class="mt-70">XIII. Disclaimers; No Warranties</h2>

                    <h3 class="mt-70">No warranties.</h3>
                    <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, 2GTHR, AND ITS AFFILIATES, PARTNERS, AND SUPPLIERS DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM 2GTHR OR THROUGH THE 2GTHR SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. YOU EXPRESSLY ACKNOWLEGE THAT AS USED IN THIS SECTION XIV, THE TERM 2GTHR INCLUDES 2GTHR'S OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, SHAREHOLDERS, AGENTS, LICENSORS AND SUBCONTRACTORS.</p>

                    <h3 class="mt-70">“As is” and “As available” and “With All Faults”.</h3>
                    <p>YOU EXPRESSLY AGREE THAT THE USE OF THE 2GTHR SERVICE IS AT YOUR SOLE RISK. THE 2GTHR SERVICE AND ANY DATA, INFORMATION, THIRD-PARTY SOFTWARE, CONTENT (INCLUDING VIEWER COMMENTS), REFERENCE SITES, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE 2GTHR SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”, “WITH ALL FAULTS” BASIS AND WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND EITHER EXPRESS OR IMPLIED.</p>

                    <h3 class="mt-70">Service Operation and Viewer Comments.</h3>
                    <p>2GTHR, ITS SUPPLIERS, LICENSORS, AFFILIATES, AND PARTNERS DO NOT WARRANT THAT THE DATA, CONTENT, FUNCTIONS, OR ANY OTHER INFORMATION OFFERED ON OR THROUGH THE 2GTHR SERVICE OR ANY REFERENCE SITES WILL BE UNINTERRUPTED, OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS AND DO NOT WARRANT THAT ANY OF THE FOREGOING WILL BE CORRECTED.</p>

                    <h3 class="mt-70">Accuracy.</h3>
                    <p>2GTHR, ITS SUPPLIERS, LICENSORS, AFFILIATES, AND PARTNERS DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE 2GTHR SERVICES OR ANY REFERENCE SITES IN TERMS OF CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE.</p>

                    <h3 class="mt-70">Harm to Your Computer.</h3>
                    <p>YOU UNDERSTAND AND AGREE THAT YOU USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN INFORMATION, MATERIALS, OR DATA THROUGH THE 2GTHR SERVICES OR ANY REFERENCE SITES AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL OR DATA.</p>

                    <h3 class="mt-70">Uploaded Content.</h3>
                    <p>THE SECURITY MEASURES TO PROTECT VIEWER COMMENTS USED BY 2GTHR HEREIN ARE WITH NO ASSURANCES THAT SUCH SECURITY MEASURES WILL WITHSTAND ATTEMPTS TO EVADE SECURITY MECHANISMS OR THAT THERE WILL BE NO CRACKS, DISABLEMENTS OR OTHER CIRCUMVENTION OF SUCH SECURITY MEASURES.</p>

                    <h3 class="mt-70">Internet Interruption and Web Site Contamination.</h3>
                    <p>2GTHR IS NOT RESPONSIBLE TO USER FOR ANY INTERRUPTION OF ACCESS TO THE 2GTHR WEB SITE FOR ANY REASON, OR FOR CONTAMINATION, VIRUS, OR INFECTION THAT CAUSES DAMAGES TO USER’ S CONTENT UPLOADED ONTO THE 2GTHR WEB SITE. 2GTHR IS NOT RESPONSIBLE FOR ANY LOSS OF REVENUE OR GOODWILL TO USER DUE TO ANY INTERRUPTION OR CONTAMINATION OR DUE TO ANY OTHER REASON, INCLUDING BUT NOT LIMITED TO ANY DAMAGE TO USER’ S REPUTATION THAT MAY RESULT.</p>

                    <h2 class="mt-70">XIV. Limitation of Liability and Damages</h2>

                    <h3 class="mt-70">Limitation of Liability.</h3>
                    <p>UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, WILL 2GTHR OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS, LICENSORS, OR SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES ARISING FROM ANY UNSUCCESSFUL COURT ACTION OR LEGAL DISPUTE, LOST BUSINESS, LOST REVENUES OR LOSS OF ANTICIPATED PROFITS OR ANY OTHER PECUNIARY OR NON-PECUNIARY LOSS OR DAMAGE OF ANY NATURE WHATSOEVER) ARISING OUT OF OR RELATING TO THESE TERMS OR THAT RESULT FROM YOUR USE OR YOUR INABILITY TO USE THE MATERIALS (INCLUDING VIEWER COMMENTS) ON THE 2GTHR SERVICES OR ANY REFERENCE SITES, OR ANY OTHER INTERACTIONS WITH 2GTHR, EVEN IF 2GTHR OR A 2GTHR AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                    <h3 class="mt-70">Limitation of Damages.</h3>
                    <p>IN NO EVENT WILL 2GTHR OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS, LICENSORS, OR SUPPLIERS TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR USE OF THE 2GTHR SERVICES OR YOUR INTERACTION WITH OTHER 2GTHR SERVICES USERS (WHETHER IN CONTRACT, TORT INCLUDING NEGLIGENCE, WARRANTY, OR OTHERWISE), EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE 2GTHR SERVICES DURING THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE HUNDRED DOLLARS, WHICHEVER IS GREATER.</p>

                    <h3 class="mt-70">Third Party Sites.</h3>
                    <p>THESE LIMITATIONS OF LIABILITY ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY YOU BY REASON OF ANY PRODUCTS OR SERVICES SOLD OR PROVIDED ON ANY THID PARTY SITES OR OTHERWISE BY THIRD PARTIES OTHER THAN 2GTHR AND RECEIVED THROUGH OR ADVERTISED ON THE 2GTHR SERVICES OR RECEIVED THROUGH ANY REFERENCE SITES.</p>

                    <h3 class="mt-70">Basis of the Bargain.</h3>
                    <p>YOU ACKNOWLEDGE AND AGREE THAT 2GTHR HAS OFFERED ITS PRODUCTS AND SERVICES, SET ITS PRICES, AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND 2GTHR, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND 2GTHR. 2GTHR WOULD NOT BE ABLE TO PROVIDE THE 2GTHR SERVICES TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.</p>

                    <h3 class="mt-70">Limitations by Applicable Law.</h3>
                    <p>CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF YOU RESIDE IS SUCH A JURISDICTION, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. THE LIMITATIONS OR EXCLUSIONS OF WARRANTIES, REMEDIES OR LIABILITY CONTAINED IN THE TERMS OF SERVICE APPLY TO YOU TO THE FULLEST EXTENT SUCH LIMITATIONS OR EXCLUSIONS ARE PERMITTED UNDER THE LAWS OF THE JURISDICTION WHERE YOU ARE LOCATED.</p>

                    <h2 class="mt-70">XV. United States Export Controls</h2>
                    <p class="h4">You agree to comply with all export laws and restrictions and regulations of the United States Department of Commerce or other United States or other sovereign agency or authority, and not to export, or allow the export or re-export of any software, technical data or any direct product thereof in violation of any such restrictions, laws or regulations, or unless and until all required licenses and authorizations are obtained with respect to the countries specified in the applicable United States Export Administration Regulations (or any successor supplement or regulations). The transfer of certain technical data and commodities may require a license from an agency of the United States government and/or written assurances by you that you will not export such software, technical data or commodities to certain foreign countries without prior approval of such agency. Your rights under the Terms of Service are contingent on your compliance with this provision.</p>

                    <h2 class="mt-70">XVI. Miscellaneous</h2>

                    <h3 class="mt-70">Notice.</h3>
                    <p>2GTHR may provide you with notices by email, regular mail or postings on the 2GTHR Service. Notice will be deemed given twenty-four hours after email is sent, unless 2GTHR is notified that the email address is invalid. Alternatively, we may give you legal notice by mail to a postal address, if provided by you through the 2GTHR Service. In such case, notice will be deemed given three days after the date of mailing. Notice posted on the 2GTHR Service is deemed given 24 hours following the initial posting. Notwithstanding the foregoing, your continued use of the 2GTHR Service after the posting of any changes to the Terms of Service constitutes your binding acceptance of such changes.</p>

                    <h3 class="mt-70">Prevention of Unauthorized Use.</h3>
                    <p>2GTHR reserves the right to exercise whatever lawful means it deems necessary to prevent unauthorized use of the 2GTHR Service, including, but not limited to, technological barriers, IP mapping, and directly contacting your Internet Service Provider (ISP) regarding such unauthorized use.</p>

                    <h3 class="mt-70">Waiver.</h3>
                    <p>The failure of 2GTHR to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision. Any waiver of any provision of these Terms will be effective only if in writing and signed by 2GTHR.</p>

                    <h3 class="mt-70">Governing Law.</h3>
                    <p>These Terms of Service will be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law.</p>

                    <h3 class="mt-70">Jurisdiction.</h3>
                    <p>You agree that any action at law or in equity arising out of or relating to these Terms of Service or 2GTHR will be filed only in the state or federal courts in and for Los Angeles County, California, and you hereby consent and submit to the personal and exclusive jurisdiction of such courts for the purposes of litigating any such action.</p>

                    <h3 class="mt-70">Severability.</h3>
                    <p>If any provision of the Terms of Service is held to be unlawful, void, or for any reason unenforceable, then that provision will be limited or eliminated from the Terms of Service to the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions.</p>

                    <h3 class="mt-70">Assignment.</h3>
                    <p>The Terms of Service, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by 2GTHR without restriction. Any assignment attempted to be made in violation of the Terms of Service shall be void.</p>

                    <h3 class="mt-70">Survival.</h3>
                    <p>Upon termination of the Terms of Service, any provision, which, by its nature or express terms should survive, will survive such termination or expiration.</p>

                    <h3 class="mt-70">Headings.</h3>
                    <p>The heading references herein are for convenience purposes only, do not constitute a part of the Terms of Service, and will not be deemed to limit or affect any of the provisions hereof.</p>

                    <h3 class="mt-70">Claims.</h3>
                    <p>YOU AND 2GTHR AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE 2GTHR PLATFORM MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>

                </b-col>
            </b-container>
        </section>
    </div>
</template>

<script>

    export default {
        name: 'TermsOfServicePage',
    }
</script>
