<template>
  <div class="page home-page">
    <section class="home-1-section">
      <img class="bg-lines" src="/images/home-hero-lines.svg" />
      <img class="bg-lines lines-right" src="/images/lines-up-white.svg" />
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="hero-banner">
              <h1 class="text-stroke">
                Watch your guitar heroes perform live, then meet them!
              </h1>
              <h3>2GTHR is your live-stream home for great guitar music.</h3>
              <div v-if="user" :style="{ height: `58px` }"></div>
              <div v-if="!user" class="home-signup-email">
                <validation-observer ref="validationSignupEmailObserver">
                  <b-form @submit.prevent="submitSignupEmailForm">
                    <validation-provider
                      rules="required|email"
                      v-slot="{ classes, errors }"
                      mode="passive"
                      name="Email"
                    >
                      <b-input
                        v-model.trim="signUpEmail"
                        class="mb-4 mr-sm-2"
                        placeholder="Email Address"
                        @change="onChangeSignupEmail"
                        @input="onChangeSignupEmail"
                        :class="classes"
                      ></b-input>
                      <p class="error-msg" :state="errors.length == 0">
                        {{ errors[0] }}
                      </p>
                      <p class="error-msg common" v-show="signupEmailError">
                        {{ signupEmailError }}
                      </p>
                    </validation-provider>
                    <b-button
                      :disabled="apiRequesting"
                      type="submit"
                      class="semibold"
                      variant="blue"
                      size="sm"
                      >Start Free Trial</b-button
                    >
                  </b-form>
                </validation-observer>
              </div>
            </div>
            <div class="carousel-wrapper">
              <vueper-slides
                ref="vueperSlides"
                id="homepage-artists-carousel"
                autoplay
                :bullets="false"
                :pauseOnHover="false"
                :touchable="false"
                :duration="8000"
                fixedHeight="620px"
              >
                <vueper-slide
                  v-for="artist in featuredArtists"
                  v-bind:key="artist.name"
                >
                  <template v-slot:content>
                    <div
                      class="artist-image-wrapper"
                      v-bind:style="{
                        backgroundImage: 'url(' + artist.imageUrl + ')',
                      }"
                    >
                      <div class="backdrop-gradient"></div>
                      <div class="artist-name">{{ artist.name }}</div>
                    </div>
                  </template>
                </vueper-slide>
                <template v-slot:arrow-right>
                  <img src="/images/icons/caret.svg" />
                </template>
              </vueper-slides>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="home-2-section">
      <b-container>
        <b-row>
          <b-col cols="12" md="6">
            <div class="play-video-banner mb-20">
              <div class="image-wrapper">
                <img src="/images/content/home-video-poster.jpg" />
                <b-button
                  variant="round"
                  class="btn-blue icon-play-cyan"
                  @click="playVideo"
                ></b-button>
              </div>
              <p>What is 2GTHR?</p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div>
              <h2 class="text-stroke">
                More than a live-stream service, 2GTHR is a community where
                artists and fans know each other by name
              </h2>
              <h3>
                We’ve assembled an all-star roster of
                <b-link :to="{ name: 'artists' }">Artists</b-link> and 2GTHR is
                the only place you can watch them play in HD Video + Audio, then
                meet them face-to-face onscreen!
              </h3>
              <h4>
                For as little as $<span v-html="homePrice" /> per month you’ll get access to unlimited
                interactive live- stream sessions, plus our archive of replays.
                Watch as many shows as you like, meet our community of music
                fans, and be amazed as the Artists get to know YOU by name. Try
                it for one month
                <b-link :to="{ name: 'register' }">free</b-link> before paying
                anything.
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section v-if="courses && courses.length" class="home-upcoming-section">
      <b-container>
        <b-row class="title-wrapper">
          <b-col cols="12">
            <h3 class="text-center mb-60">
              <strong>Upcoming Sessions</strong>
              <b-link :to="{ name: 'upcoming' }"
                >Check the full month’s schedule</b-link
              >
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid="" class="overflow-hidden">
        <b-row>
          <b-col cols="12">
            <b-container>
              <b-row>
                <b-col cols="12">
                  <upcoming-sessions :courses="courses"></upcoming-sessions>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="home-4-section mb-0">
      <b-container fluid="">
        <b-row>
          <b-col class="col-12 col-md-3 offset-md-1 offset-0">
            <img
              src="/images/content/home-hero-4.png"
              alt="2GTHR’s mission is to connect live audiences with the artists they love"
            />
          </b-col>
          <b-col class="col-12 col-md-7 mt-5 mt-sm-0">
            <div class="text-wrapper">
              <h2 class="text-stroke">Great Performances + Community</h2>
              <h3>
                Shared connection is part of what makes music special. But that
                connection is missing from most platforms, so we created the
                experience we couldn’t find anywhere else.
              </h3>
              <h4>
                We launched in March 2021, combining great shows with
                interactivity that guitar fans from all around the world love –
                check it out for
                <b-link :to="{ name: 'register' }">free</b-link> and see why.
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="home-5-section">
      <b-container fluid="">
        <b-row>
          <b-col>
            <h2 class="text-stroke text-center">Join 2GTHR</h2>
          </b-col>
        </b-row>
        <b-row class="justify-content-center home-features">
          <b-col cols="12" md="3">
            <img
              src="/images/content/home-hero-5-1.svg"
              alt="Support  Artists"
            />
            <h3>Curated</h3>
            <h4>
              We’ve assembled an all-star roster of
              <b-link :to="{ name: 'artists' }">Artists</b-link> – every time we
              go live, you’re seeing someone great.
            </h4>
          </b-col>
          <b-col cols="12" md="3">
            <img
              src="/images/content/home-hero-5-2.svg"
              alt="Participate Live"
            />
            <h3>Interactive</h3>
            <h4>
              Jump onscreen and meet your heroes face-to-face, and get to know
              our community of fans.
            </h4>
          </b-col>
          <b-col cols="12" md="3">
            <img src="/images/content/home-hero-5-3.svg" alt="Never Miss Out" />
            <h3>Always on</h3>
            <h4>
              We’ve got tons of live shows – all included with your subscription
              – and if you miss one, catch the
              <b-link :to="{ name: 'catch-up' }">replay</b-link>.
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              class="semibold mt-80"
              size="lg"
              variant="blue"
              :to="user ? { name: 'upcoming' } : { name: 'register' }"
              >Try it Free</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="home-6-section">
      <b-container>
        <b-row class="text-center">
          <b-col cols="8" offset="2">
            <img src="/images/icons/quotes.svg" alt="Quotes" />
            <h3 class="bold mt-3">
              To livestream a high quality show is one thing but it's BEYOND
              next level to be able to truly engage with the audience virtually!
              It's what we are all missing!! The first show we did where I could
              bring folks onstage with me while I was performing truly astounded
              me! I'm IN!!!
            </h3>
            <h4>JD Simo</h4>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section v-if="!user" class="home-signup-section">
      <div class="text-center">
        <span class="h2 text-stroke text-center">Start Your Free Trial</span>
      </div>
      <div class="signup-wrapper">
        <validation-observer ref="validationSignupObserver">
          <b-form @submit.prevent="submitSignupForm">
            <b-form-group>
              <label for="form-input-fullName">Full Name</label>
              <validation-provider
                vid="fullName"
                rules="required|max:256"
                v-slot="{ classes, errors }"
                mode="eager"
                name="Full Name"
              >
                <b-input
                  v-model.trim="signUpForm.fullName"
                  id="form-input-fullName"
                  class="mb-4 mr-sm-2"
                  :class="classes"
                ></b-input>
                <p class="error-msg" :state="errors.length == 0">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="form-input-email">Email</label>
              <validation-provider
                vid="email"
                rules="required|email"
                v-slot="{ classes, errors }"
                mode="eager"
                name="Email"
              >
                <b-input
                  v-model.trim="signUpForm.email"
                  id="form-input-email"
                  class="mb-4 mr-sm-2"
                  :class="classes"
                ></b-input>
                <p class="error-msg" :state="errors.length == 0">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="form-input-password">Password</label>
              <validation-provider
                vid="password"
                rules="required"
                v-slot="{ classes, errors }"
                mode="eager"
                name="Password"
              >
                <b-input
                  type="password"
                  v-model="signUpForm.password"
                  id="form-input-password"
                  class="mb-4 mr-sm-2"
                  :class="classes"
                ></b-input>
                <p class="error-msg" :state="errors.length == 0">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                vid="accepted"
                v-slot="{ classes, errors }"
                mode="eager"
                name="Password"
              >
                <b-form-checkbox
                  class="mt-30"
                  v-model="signUpForm.accepted"
                  :value="true"
                  :unchecked-value="false"
                  :class="classes"
                >
                  By signing up, I agree the 2GTHR
                  <router-link target="_blank" :to="{ name: 'privacy-policy' }"
                    >Privacy Policy</router-link
                  >
                  and
                  <router-link
                    target="_blank"
                    :to="{ name: 'terms-of-service' }"
                    >Terms of Service</router-link
                  >
                </b-form-checkbox>
                <p
                  class="error-msg relative-error-mgs"
                  :state="errors.length == 0"
                >
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </b-form-group>
            <p class="text-danger" v-show="signupError">{{ signupError }}</p>
            <div class="text-center mt-30">
              <b-button
                size="lg"
                variant="blue"
                type="submit"
                class="semibold"
                :disabled="apiRequesting"
                >Continue</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
    </section>

    <section class="home-app-section mb-0">
      <b-container fluid="">
        <b-row>
          <b-col class="col-12 col-md-5 offset-md-1 offset-0">
            <img
              class="banner"
              src="/images/content/home-phone-graphic.png"
              alt="2GTHR’s mission is to connect live audiences with the artists they love"
            />
          </b-col>
          <b-col class="col-12 col-md-6 mt-5 mt-sm-0">
            <div class="text-wrapper">
              <h2 class="text-stroke">2GTHR Apps</h2>
              <h3 class="font-weight-normal">
                Grab our iOS or Android app and join live and replay sessions from wherever
                you are.
              </h3>
              <div class="d-flex flex-wrap">
                <div>
                  <a
                      :href="appleStore"
                      target="_blank"
                  >
                    <img
                        class="app-store mb-2 mr-2"
                        src="/images/app-store-white.svg"
                        alt="2GTHR App Store"
                    />
                  </a>
                </div>
                <div>
                  <a
                      :href="googleStore"
                      target="_blank"
                  >
                    <img
                        width="180"
                        class="app-store"
                        src="/images/play-store-white.svg"
                        alt="2GTHR App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <homepage-video-player-modal
      :videoAutoplay="videoAutoplay"
      :video-url="videoUrl"
      poster-url="/images/content/home-video-poster.jpg"
    />
    <artist-modal></artist-modal>
    <session-sharing-modal></session-sharing-modal>
    <session-tips-modal></session-tips-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueperSlides, VueperSlide } from "vueperslides";
import HomepageVideoPlayerModal from "../shared/Modals/HomepageVideoPlayer";
import { api } from "@/services";
import SessionTipsModal from "../shared/Modals/SessionTips";
import SessionSharingModal from "../shared/Modals/SessionSharing";
import ArtistModal from "../shared/Modals/Artist";
import UpcomingSessions from "../shared/Carousels/UpcomingSessions";
import firebase from "firebase";
import { security } from "@/services";
import {constants} from "@/mixins/constants";
import {plans} from "@/mixins/plans"


export default {
  name: "FrontPage",
  mixins: [constants, plans],
  metaInfo: {
    title: "2GTHR | Interact with your favorite music artists",
    meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: "2GTHR | Interact with your favorite music artists",
      },
      {
        vmid: "description",
        property: "description",
        content: "Live stream music directly from the artist’s home to yours.",
      },
      {
        vmid: "og:description",
        property: "og:description",
        content: "Live stream music directly from the artist’s home to yours.",
      },
    ],
  },
  data() {
    return {
      courses: null,
      videoAutoplay: false,
      videoUrl: `https://firebasestorage.googleapis.com/v0/b/gthr-2c4b2.appspot.com/o/assets%2F2GTHR - Version 4.mp4?alt=media&token=c68716dc-ade5-4548-9b45-e879f4d37294`,
      signUpEmail: ``,
      signupEmailError: null,
      signUpForm: {
        fullName: "",
        email: "",
        password: "",
        accepted: false,
      },
      signupError: null,
    };
  },
  computed: {
    ...mapGetters(["user", "featuredArtists", "apiRequesting"]),
  },
  methods: {
    ...mapActions(["initFeaturedArtists"]),
    showVideo() {
      this.$bag.setItem("homepage-video-played", +new Date());
      this.$root.$emit("bv::show::modal", "homepage-video-player-modal");
    },
    playVideo() {
      this.videoAutoplay = true;
      this.$root.$emit("bv::show::modal", "homepage-video-player-modal");
    },
    getUpcomingEvents() {
      api.courses.list({ params: { type: "upcoming" } }).then(({ data }) => {
        this.courses = data.items;
      });
    },
    async submitSignupEmailForm() {
      this.signupEmailError = null;
      const isValid = await this.$refs.validationSignupEmailObserver.validate();
      if (!isValid) {
        return;
      }
      api.auth
        .checkEmail({ params: { email: this.signUpEmail } })
        .then(async ({ data }) => {
          if (data.exists) {
            return (this.signupEmailError = `The email address is already in use by another account`);
          }
          await this.createEmail(this.signUpEmail);
          return this.$router.push({
            name: "register-email",
            params: { email: this.signUpEmail },
          });
        })
        .catch((err) => {
          console.warn(err);
          const data = err.response.data;
          this.signupEmailError = data.message;
        });
    },
    onChangeSignupEmail() {
      this.signupEmailError = null;
    },
    async submitSignupForm() {
      this.error = null;
      const isValid = await this.$refs.validationSignupObserver.validate();
      if (!isValid) {
        return;
      }
      api.auth
        .register(this.signUpForm)
          .then((response) => {
            return firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                  return firebase
                      .auth()
                      .signInWithEmailAndPassword(
                          this.signUpForm.email,
                          this.signUpForm.password
                      );
                })
            .then(() => {
              security.setUserResponse(response.data);
              this.$bag.setItem("registered", 1);
              this.$analytics.dataLayerPush({
                event: "Create Account",
                category: "Subscribe-Music",
                action: "Create Account",
                label: window.location.href,
              });
              this.$analytics.redditPush("SignUp");
              return this.$router.replace({ name: "get-started" });
            });
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.validation) {
            let beValidation = {};
            for (const item of data.validation) {
              beValidation[item.field] = [item.message];
            }
            this.$refs.validationSignupObserver.setErrors(beValidation);
          } else if (data.code && data.code === `USERNAME_TAKEN`) {
            this.$refs.validationSignupObserver.setErrors({
              fullName: [data.message],
            });
          } else if (data.code && data.code === `EMAIL_TAKEN`) {
            this.$refs.validationSignupObserver.setErrors({
              email: [data.message],
            });
          } else {
            this.signupError = data.message;
          }
        });
    },
    async createEmail(email) {
      api.emails
        .createEmail({ email: email })
        .then(() => {})
        .catch((err) => {
          console.warn(err);
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.initFeaturedArtists();
      vm.getUpcomingEvents();
      if (!vm.$bag.getItem("homepage-video-played")) {
        setTimeout(() => {
          vm.showVideo();
        }, 1000);
      }
    });
  },
  mounted() {
    this.$refs.vueperSlides.doAutoplay();
  },
  components: {
    UpcomingSessions,
    VueperSlides,
    VueperSlide,
    HomepageVideoPlayerModal,
    SessionTipsModal,
    SessionSharingModal,
    ArtistModal,
  },
};
</script>
