import store from '@/store';
import {Role} from '@/shared/enums';

export const isAnonymous = () => {
    return !store.getters.user;
};

export const isAuthenticated = () => {
    return !!store.getters.user;
};

export const isEmailVerified = () => {
    return !!(store.getters.user && store.getters.user.isEmailVerified);
};

export const isVip = () => {
    return !!(store.getters.user && store.getters.user.vip);
};

export const isAllowedByRoles = (roles) => {
    return !!(store.getters.user && store.getters.user.role && roles.includes(store.getters.user.role));
};

export const isDeniedByRoles = (roles) => {
    return !isAllowedByRoles(roles);
};

export const isSubscribed = (names = ['default', 'promo']) => {
    if (store.getters.subscriptions && store.getters.subscriptions.length) {
        return store.getters.subscriptions.some(s => names.includes(s.name));
    }
    return false;
};

export const isAllowedByTopRolesOrSubscribedDefault = () => {
    return isAllowedByRoles([Role.INSTRUCTOR, Role.ADMIN, Role.MANAGER]) || isVip() || isSubscribed();
};

export const isDeniedForInstructors = () => {
    return !isDeniedByRoles([Role.INSTRUCTOR, Role.ADMIN]);
};
