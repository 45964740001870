<template>
  <div class="page opt-out-page">
  </div>
</template>

<script>

  import {api} from '@/services'

  export default {
    name: 'PasswordReset',
    methods: {
      verifyRequirements(token) {
        if (!token) {
          return this.$router.replace({name: 'upcoming'});
        }
        const tokenString = decodeURI(token);
        api.users.applyToken({
          token: tokenString,
        }).then(({data}) => {
          if (data.success) {
            this.$alert.info({
              text: `You have been Opt Out successfully.`,
              timeout: 5000,
            });
          }
        }).finally(() => {
          return this.$router.replace({name: 'upcoming'});
        });
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.verifyRequirements(to.params.token);
      });
    }
  }
</script>
