import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";
import store from "../store";
import { api } from '@/services'
import Bowser from "bowser";
import moment from "moment";
import { randomBytes } from "@/utils/utils";
import env from "@/configuration/env";
import {ChannelEventType} from "@/shared/enums";

const INSTRUCTOR_VIEW = 'instructor-view';

class AgoraStream {

    uid = 0;
    audio = null;
    video = null;

    _lastElementId = null;
    _client = null;
    _published = false;

    constructor(audio, video, uid) {
        this.audio = audio;
        this.video = video;
        this.uid = uid;
    }

    getId() {
        return this.uid;
    }

    stop() {
        this.audio?.stop?.();
        this.video?.stop?.();
    }

    play(elementId, config, cb) {
        this._lastElementId = elementId;
        try {
            this.video?.play?.(elementId);
            if (!config?.muted) {
                this.audio?.play?.();
            }
        } catch (err) {
            console.error(err);
            cb && cb(err);
        }
    }

    async resume() {
        if (this._lastElementId) {
            this.video?.play?.(this._lastElementId);
            this.audio?.play?.();
        }
    }

    isPlaying() {
        return this.video?.isPlaying || this.audio?.isPlaying;
    }

    close() {
        this.video?.close?.();
        this.audio?.close?.();
        this._lastElementId = null;
    }

    setAudioVolume(num) {
        this.audio?.setVolume?.(num);
    }

    async publish(client) {
        this._client = client;
        const streams = [];
        if (this.audio) {
            streams.push(this.audio);
        }
        if (this.video) {
            streams.push(this.video);
        }
        if (streams.length > 0) {
            await client?.publish?.(streams);
            this._published = true;
            return true;
        }
        return false;
    }
    unpublish(client) {
        if (!this._published) {
            return;
        }
        try {
            this.audio && client?.unpublish?.(this.audio);
        } catch (e) {
            console.log("unpublish audio error: ", e);
        }
        try {
            this.video && client?.unpublish?.(this.video);
        } catch (e) {
            console.log("unpublish video error: ", e);
        }
        this._published = false;
    }

    muteAudio() {
        this.audio?.setMuted?.(true);
    }
    unmuteAudio() {
        this.audio?.setMuted?.(false);
    }

    muteVideo() {
        this.video?.setMuted?.(true);
    }
    unmuteVideo() {
        this.video?.setMuted?.(false);
    }

    replaceAudio(stream) {
        const isPlaying = this.audio?.isPlaying;
        const muted = this.audio?.muted;
        if (isPlaying) {
            this.audio?.stop?.();
            stream.play();
        }
        stream.setMuted(muted);
        if (this._published) {
            this.audio && this._client?.unpublish?.(this.audio);
            this._client?.publish?.([stream]);
        }
        this.audio = stream;
    }
    replaceVideo(stream) {
        const isPlaying = this.video?.isPlaying;
        const muted = this.video?.muted;
        if (isPlaying) {
            this.video?.stop?.();
            stream.play(this._lastElementId);
        }
        stream.setMuted(muted);
        if (this._published) {
            this.video && this._client?.unpublish?.(this.video);
            this._client?.publish?.([stream]);
        }
        this.video = stream;
    }

    merge(otherStream) {
        if (otherStream.video) {
            // this.video?.stop?.();
            this.video = otherStream.video;
            if (this._lastElementId && !this.video.isPlaying) {
                this.video.play(this._lastElementId);
            }
        }
        if (otherStream.audio) {
            // this.audio?.stop?.();
            this.audio = otherStream.audio;
            if (!this.audio.isPlaying) {
                this.audio.play();
            }
        }
        return this;
    }

}
export default class Agora {
    $eventBus = null;
    appId = null;
    timeChecked = [];
    timeDiffNotified = false;
    devicesInitialization = true;
    listenOnDeviceChange = false;
    audioDeviceAccessChecked = false;
    videoDeviceAccessChecked = false;
    localStream = null;
    hostRemoteStream = null;
    playHostStreamForLearnerStarted = false;
    onDevicesFoundCb = false;
    learnerStreams = [];
    mainViewStreams = [];
    rtmClient = null;
    rtmChannel = null;
    rtcClient = null;
    isMutedByHost = false;
    audioDeviceId = null;
    audioEncoderProfile = null;
    videoDeviceId = null;
    initialized = false;
    localLogLevel = 0;
    remoteLog = {
        enabled: (env.appEnv === 'development'),
        uploadInterval: null,
        getStatusInterval: null,
        log: [],
        logUploading: false,
    };
    chatLog = {
        uploadInterval: null,
        items: [],
        logUploading: false,
    };
    get channelHandlers() {
        return [
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.HAND_DOWN && store.getters.canManageOnStage;
                },
                handle(event) {
                    store.dispatch('removeHandUp', event);
                    this._stopRemoteStreamLocally(event.uid);
                    this.stopRemoteStream(event.uid);
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.HAND_UP_IGNORED_CHANNEL && store.getters.canManageOnStage;
                },
                handle(event) {
                    store.dispatch('removeHandUp', event.payload);
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.HAND_UP && store.getters.canManageOnStage;
                },
                handle(event) {
                    store.dispatch('addHandUp', event);
                    store.dispatch('addAudience', {
                        uid: event.uid,
                        fullName: event.fullName,
                        role: event.role,
                    });
                }
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.PING_MANAGERS && store.getters.canManageOnStage;
                },
                handle() {
                    this.sendMessageToHost({ type: 'JOIN_MANAGER', payload: this.getUser()});
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.MAIN_VIEW_STREAMS;
                },
                handle(event) {
                    store.dispatch('setStreamsOnMainView', event.items);
                    // this.sendMessageToHost({ type: 'JOIN_MANAGER', payload: this.getUser()});
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.SESSION;
                },
                handle(event) {
                    store.dispatch('setSession', event.session);
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.SESSION_ENDED;
                },
                handle() {
                    store.dispatch('setSessionStatus', 'ENDED');
                    this.handleDestroy();
                    this.$eventBus.$emit('set-hand-down');
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.UNMUTE_ALL;
                },
                handle() {
                    this.isMutedByHost = false;
                    if (!store.getters.isHostHere) {
                        // this.enableAudioSecretly();
                        this.enableAudio();
                    }
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.MUTE_ALL;
                },
                handle() {
                    if (!store.getters.isHostHere) {
                        // this.disableAudioSecretly();
                        this.disableAudio();
                    }
                    this.isMutedByHost = true;
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.STOP_LEARNER_STREAM;
                },
                handle(event) {
                    if (event.targetUid === this.getUser().nid) {
                        this.unPublishStream();
                        this.$eventBus.$emit('set-hand-down');
                    }
                    store.dispatch('removeStream', event.targetUid).then(() => this.updateLayout());
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.ROLLCALL;
                },
                handle() {
                    this.sendChannelMessage({ type: ChannelEventType.AUDIENCE });
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.AUDIENCE;
                },
                handle(event) {
                    store.dispatch('addAudience', {
                        uid: event.uid,
                        fullName: event.fullName,
                        role: event.role,
                    });
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.CHAT_MESSAGE;
                },
                handle(event) {
                    this._addNewChatMessage(event);
                },
            },
            {
                canHandle(eventType) {
                    return eventType === ChannelEventType.HAS_HAND_UP_CHANNEL && store.getters.canManageOnStage;
                },
                handle(event) {
                    this.sendInstantMessage(event.uid, { type: 'HAS_HAND_UP',  payload: store.getters.handUp})
                }
            }
        ]
    }
    init() {
        this.$eventBus.$emit('initialization-started');
        AgoraRTC.enableLogUpload();
        if (this.remoteLog.enabled) {
            this._initRemoteLog();
        }
        this._initChatUpload();
    }
    async isConnectionConflict() {
        const nid = this.getUser().nid.toString();
        if (!nid) {
            return true;
        }
        const connectionClient = AgoraRTM.createInstance(this.appId);
        const name = randomBytes();
        let data;
        try {
            const response = await api.courses.getSignalTokenVerification(this.getChannelId(), {
                params: {
                    name: name
                }
            });
            data = response.data;
        } catch (e) {
            await connectionClient.logout();
            const message = e.response?.data?.message || e.message;
            this.error('Signal Verification. Get token error.', { message });
            return true;
        }
        const connectionToken = data.token;
        const uid = nid + `v` + name;
        try {
            await connectionClient.login({ uid: uid, token: connectionToken })
        } catch (e) {
            await connectionClient.logout();
            this.error('Signal Verification. Login failed', { message: e.message });
            return true;
        }
        const connectionChannel = connectionClient.createChannel(this.getChannelId());
        try {
            await connectionChannel.join();
        } catch (e) {
            await connectionClient.logout();
            this.error('Signal Verification. Join channel error.', { message: e.message });
            return true;
        }
        let members = [];
        try {
            members = await connectionChannel.getMembers();
        } catch (e) {
            await connectionClient.logout();
            this.error('Signal Verification. Get channel members error.', { message: e.message });
            return true;
        }
        await connectionClient.logout();
        const result = members.includes(nid);
        if (result) {
            this.handleDestroy();
        }
        return result;
    }
    getDevices() {
        const self = this;
        if (!self.audioDeviceAccessChecked && !self.videoDeviceAccessChecked) {
            return self._checkDevicesAccess();
        }
        const audioSources = [];
        const videoSources = [];
        if (!self.listenOnDeviceChange && navigator?.mediaDevices) {
            self.listenOnDeviceChange = true;
            navigator.mediaDevices.ondevicechange = () => {
                self.getDevices();
            }
        }
        AgoraRTC.getDevices().then((items) => {
            items
                .filter(item => ['audioinput', 'videoinput'].indexOf(item.kind) !== -1)
                .map(function (item) {
                    if (!item.deviceId) {
                        return;
                    }
                    const device = {
                        label: item.label,
                        deviceId: item.deviceId,
                        kind: item.kind,
                        groupId: item.groupId,
                    };
                    if (device.kind === 'audioinput') {
                        audioSources.push(device);
                    }
                    if (device.kind === 'videoinput') {
                        videoSources.push(device);
                    }
                });
            self.log(`Stream. Audio devices`, { devices: audioSources });
            self.log(`Stream. Video devices`, { devices: videoSources });
            self.$eventBus.$emit('devices-list', {
                audio: audioSources,
                video: videoSources,
                devicesInitialization: self.devicesInitialization,
            });
            if (self.onDevicesFoundCb && (audioSources.length || videoSources.length)) {
                self.onDevicesFoundCb().then(() => {
                    self.onDevicesFoundCb = null;
                });
            }
            self.devicesInitialization = false;
        });
    }
    async _retrieveAgoraStreamToken() {
        this.log('Stream. Get token');
        try {
            const { data } = await api.courses.getStreamToken(this.getChannelId());
            return data.token;
        } catch(e) {
            const message = (e.response && e.response.data && e.response.data.message) ? e.response.data.message : e.message;
            this.error('Stream. Get token error.', message);
        }
    }
    async _retrieveAgoraSignalToken() {
        this.log('Signal. Get token');
        try {
            const { data } = await api.courses.getSignalToken(this.getChannelId());
            return data.token;
        } catch(e) {
            const message = (e.response && e.response.data && e.response.data.message) ? e.response.data.message : e.message;
            this.error('Signal. Get token error.', { message });
        }
    }
    async _loginRtmSystem(token) {
        const id = this.getUser().nid.toString();
        return await this.rtmClient.login({ uid: id, token: token }).then(() => {
            this.log('Signal. Login success', id);
            store.dispatch('addAudience', {
                uid: id,
                fullName: this.getUser().fullName,
                role: this.getUser().role,
            });
        }).catch(err => {
            this.error('Signal. Login failed', { message: err.message });
        });
    }
    createRtmChannel() {
        this.rtmChannel = this.rtmClient.createChannel(this.getChannelId());
    }
    async initChat() {
        this._createRtmClient();
        const token = await this._retrieveAgoraSignalToken();
        await this._loginRtmSystem(token);
        this.createRtmChannel();
        this._subscribeOnRtmClient();
        this._subscribeOnRtmChannel();
        await this._joinToRtmChannel();
    }
    async _joinToRtmChannel() {
        try {
            await this.rtmChannel.join();
            this.sendChannelMessage({ type: ChannelEventType.ROLLCALL });
            this.sendMessageToHost({ type: 'GET_STREAMS_LIST' });
            this.log('Signal. Channel joined');
        } catch(err) {
            this.error('Signal. Join channel error.', { message: err.message });
        }
    }
    sendMessageIfCan(checking, cb) {
        if (checking.some(c => c === false)) return;
        cb();
    }
    sendMessageToHost(json) {
        this.sendInstantMessage(this.getSessionHostNid(), json);
    }
    sendInstantMessage(uid, json) {
        json.time = + new Date();
        json.serverTime = json.time;
        json.uid = this.getUser().nid;
        json.fullName = this.getUser().fullName;
        json.role = this.getUser().role;
        const message = { text: JSON.stringify(json) };
        this.rtmClient && this.rtmClient.sendMessageToPeer(message, uid.toString()).then(() => {
            this.log(`Signal. Instant message sent.`, uid, message.text);
        }).catch(err => {
            this.error('Signal. Instant message send error.', { message: err.message });
        });
    }
    sendChannelMessage(json) {
        json.time = + new Date();
        json.serverTime = json.time;
        json.uid = this.getUser().nid;
        json.fullName = this.getUser().fullName;
        json.role = this.getUser().role;
        const message = { text: JSON.stringify(json) };
        this.rtmChannel && this.rtmChannel.sendMessage(message).then(() => {
            this.log('Signal. Channel message sent.', message.text);
            if (json.type === ChannelEventType.CHAT_MESSAGE) {
                this._addNewChatMessage(json);
            }
        }).catch(err => {
            this.error('Signal. Channel message send error.', { message: err.message, event: json });
        });
    }
    setOnDevicesFoundCallback(cb) {
        this.onDevicesFoundCb = cb;
    }
    async joinStreamSession() {
        this._createRtcClient();
        const token = await this._retrieveAgoraStreamToken();
        await this._joinRtcChannel(token);
        this._subscribeRtcEvents();
    }
    async createLocalStream(uid, profile, cb) {
        if (this.localStream) {
            return;
        }
        const audioSourceId = profile.audioSource?.deviceId;
        const videoSourceId = profile.videoSource?.deviceId;
        this.log(`Stream. Local stream initialization. Audio: ${!!audioSourceId}. Video: ${!!videoSourceId}.`);
        if (!audioSourceId && !videoSourceId) {
            return this.$eventBus.$emit('local-stream-error', { code: 'NO_INPUT_SOURCES', message: `No input devices found` });
        }

        let audioStreamPromise, videoStreamPromise;
        if (audioSourceId) {
            this.audioDeviceId = audioSourceId;
            this.audioEncoderProfile = profile.audioProfile;
            const audioData = {
                microphoneId: audioSourceId,
                AGC: profile.agc,
                AEC: profile.aec,
                ANS: profile.ans,
                encoderConfig: profile.audioProfile
            };
            this.log(`Stream. Creating local audio stream.`, audioData);
            audioStreamPromise = AgoraRTC.createMicrophoneAudioTrack(audioData)
        }
        if (videoSourceId) {
            this.videoDeviceId = videoSourceId;
            const videoData = {
                cameraId: videoSourceId,
                encoderConfig: {
                    frameRate: {
                        min: parseInt(profile.resolution.fps.min),
                        max: parseInt(profile.resolution.fps.max)
                    },
                    bitrateMin: parseInt(profile.bitrate.min),
                    bitrateMax: parseInt(profile.bitrate.max),
                    width: parseInt(profile.resolution.width),
                    height: parseInt(profile.resolution.height)
                }
            }
            this.log(`Stream. Creating local video stream.`, videoData);
            videoStreamPromise = AgoraRTC.createCameraVideoTrack(videoData)
        }

        try {
            const [audioStream, videoStream] = await Promise.all([audioStreamPromise, videoStreamPromise]);
            this.localStream = new AgoraStream(audioStream, videoStream, this.getUser().nid);
            this.log(`Stream. Local stream initialized.`, { id: this.localStream?.getId() });
            this.initialized = true;
            this.$eventBus.$emit('local-stream-started');
            this.$eventBus.$emit('audio-state-changed', this.getAudioState());
            this.$eventBus.$emit('video-state-changed', this.getVideoState());
            if (cb && typeof cb === 'function') {
                cb();
            }
        } catch (err) {
            const error = err.msg || err.info;
            this.$eventBus.$emit('local-stream-error', { error: err, message: error });
            this.error(`Stream. Init local stream error.`, { message: error });
            audioStreamPromise.then(s => s.close());
            videoStreamPromise.then(s => s.close());
        }
    }
    async publishStream() {
        this.log(`Stream. Publishing stream.`, { id: this.localStream?.getId() });
        try {
            if (await this.localStream?.publish?.(this.rtcClient)) {
                this._onStreamPublished();
            }
        } catch (err) {
            this.error(`Stream. Publish stream error.`, { message: err.message });
            this.$eventBus.$emit('publish-stream-error', err);
        }
    }
    unPublishStream() {
        if (this.sessionTimedOut) {
            return;
        }
        this.log(`Stream. UnPublish stream.`, { id: this.localStream?.getId?.() });
        this.localStream?.unpublish?.(this.rtcClient);
        this._onStreamUnpublished();
    }
    stopRemoteStream(id) {
        this.sendChannelMessage({
            type: ChannelEventType.STOP_LEARNER_STREAM,
            targetUid: id,
        });
    }
    clearLearnerStreamView(id) {
        this.log(`Stream. Clear Learner stream view.`, { id: id });
        const el = document.getElementById('learner-' + id);
        if (el) {
            el.innerHTML = '';
        }
    }
    displayHostStream(stream) {
        stream = stream || this.localStream;
        if (!stream) {
            return;
        }
        this.clearHostStreamView();
        this.localStream?.stop?.();
        try {
            stream.play(INSTRUCTOR_VIEW, { muted: true });
        } catch (errState) {
            if (errState && errState.status !== "aborted") {
                const streamId = stream.getId();
                store.dispatch('addAudioAutoplayFailed', streamId);
                this.error(`Stream. Autoplay failed.`, streamId);
            }
        }

        // TODO: what's this???
        // stream.on('player-status-change', (evt) => this.listenForPlayerStatusChange(evt, stream, this));
        // stream.player.resize && stream.player.resize();
        this.log(`Stream. Host stream view displayed.`);
    }
    clearHostStreamView() {
        this.log(`Stream. Clear Host stream view.`);
        const el = document.getElementById(INSTRUCTOR_VIEW);
        if (el) {
            el.innerHTML = '';
        }
    }
    updateLayout() {
        if (store.getters.canManageOnStage && store.getters.session.status === 'STARTED') {
            const removeStreams = store.getters.removeStreams;
            const data = {
                instructors: [this.getSessionHostNid()],
                learners: store.getters.streams.map(x => x.getId()),
                mainView: store.getters.streamsOnMainView,
                remove: removeStreams,
            };
            api.courses.layout(this.getChannelId(), data).then(() => {
                this.log(`Stream Layout Updated.`, { data });
                store.dispatch('removeStreamsFromRemove', removeStreams);
            });
            this.learnerStreams = data.learners;
            this.mainViewStreams = data.mainView;
        }
    }
    streamDuration() {
        if (store.getters.session && store.getters.session.startedAt) {
            const seconds = moment(moment()).diff(store.getters.session.startedAt, 'seconds');
            return moment.utc(seconds * 1000).format('HH:mm:ss');
        }
        return 0;
    }
    async applyAudioEffects(agc, aec, ans) {
        if (!this.localStream) {
            return;
        }
        const audioData = {
            microphoneId: this.audioDeviceId,
            AGC: agc,
            AEC: aec,
            ANS: ans,
            encoderConfig: this.audioEncoderProfile,
        };
        try {
            const audioStream = await AgoraRTC.createMicrophoneAudioTrack(audioData);
            this.localStream?.replaceAudio(audioStream);
            this.log(`Stream. Audio effects updated.`, { device: this.audioDeviceId, effects: { agc, aec, ans } });
            this.$eventBus.$emit('audio-state-changed', this.getAudioState());
        } catch (err) {
            this.error(`Stream. Audio effects update error.`, { message: err.message });
        }
    }
    getAudioState() {
        return !this.localStream?.audio?.muted;
    }
    toggleMuteAll() {
        this.mutedAll ? this.unMuteAll() : this.muteAll();
        this.mutedAll = !this.mutedAll;
        return this.mutedAll;
    }
    muteAll() {
        this.isMutedByHost = true;
        this.sendChannelMessage({ type: ChannelEventType.MUTE_ALL });
    }
    unMuteAll() {
        this.isMutedByHost = false;
        this.sendChannelMessage({ type: ChannelEventType.UNMUTE_ALL });
    }
    toggleAudioState() {
        const state = this.getAudioState();
        return state ? this.disableAudio() : this.enableAudio()
    }
    enableAudio() {
        if (this.localStream) {
            if (store.getters.isHostHere) {
                this.localStream?.unmuteAudio();
                this.updateAudioUI(true);
            } else {
                if (this.isMutedByHost) {
                    this.localStream?.muteAudio();
                    this.updateAudioUI(false);
                } else {
                    this.localStream?.unmuteAudio();
                    this.updateAudioUI(true);
                }
            }
        }
    }
    disableAudio() {
        this.localStream?.muteAudio?.();
        this.updateAudioUI(false);
    }
    updateAudioUI(state) {
        this.$eventBus.$emit('audio-state-changed', state);
    }
    async setAudioSource(deviceId, profile) {
        this.audioDeviceId = deviceId;
        this.audioEncoderProfile = profile.audioProfile;
        if (!this.initialized || !this.localStream) {
            return;
        }
        const audioData = {
            microphoneId: deviceId,
            AGC: profile.agc,
            AEC: profile.aec,
            ANS: profile.ans,
            encoderConfig: profile.audioProfile
        };
        this.log(`Stream. Creating local audio stream.`, audioData);
        try {
            const audioStream = await AgoraRTC.createMicrophoneAudioTrack(audioData);
            this.localStream?.replaceAudio(audioStream);
            this.log(`Stream. Switch Audio Device success.`, { device: deviceId, profile });
            this.$eventBus.$emit('audio-state-changed', this.getAudioState());
        } catch (err) {
            this.error(`Stream. Switch audio device error.`, { message: err.message, device: deviceId });
        }
    }
    async setAudioProfile(audioProfile, profile) {
        this.audioEncoderProfile = audioProfile;
        if (!this.initialized || !this.localStream) {
            return;
        }
        const audioData = {
            microphoneId: this.audioDeviceId,
            AGC: profile.agc,
            AEC: profile.aec,
            ANS: profile.ans,
            encoderConfig: audioProfile
        };
        this.log(`Stream. Creating local audio stream.`, audioData);
        try {
            const audioStream = await AgoraRTC.createMicrophoneAudioTrack(audioData);
            this.localStream?.replaceAudio(audioStream);
            this.log(`Stream. Set Audio Profile success.`, { device: this.audioDeviceId, profile });
            this.$eventBus.$emit('audio-state-changed', this.getAudioState());
        } catch (err) {
            this.error(`Stream. Set Audio Profile error.`, { message: err.message, device: this.audioDeviceId });
        }
    }
    getVideoState() {
        return !this.localStream?.video?.muted;
    }
    toggleVideoState() {
        this.getVideoState() ? this.disableVideo() : this.enableVideo()
    }
    enableVideo() {
        this.localStream?.unmuteVideo?.();
        this.updateVideoUI(true);
    }
    disableVideo() {
        this.localStream?.muteVideo?.();
        this.updateVideoUI(false);
    }
    updateVideoUI(state) {
        this.$eventBus.$emit('video-state-changed', state);
    }
    async setVideoSource(deviceId, profile) {
        if (!this.initialized || !this.localStream) {
            return;
        }
        this.videoDeviceId = deviceId;
        const videoData = {
            cameraId: deviceId,
            encoderConfig: {
                frameRate: {
                    min: parseInt(profile.resolution.fps.min),
                    max: parseInt(profile.resolution.fps.max)
                },
                bitrateMin: parseInt(profile.bitrate.min),
                bitrateMax: parseInt(profile.bitrate.max),
                width: parseInt(profile.resolution.width),
                height: parseInt(profile.resolution.height)
            }
        }
        this.log(`Stream. Creating local video stream.`, videoData);
        try {
            const videoStream = await AgoraRTC.createCameraVideoTrack(videoData);
            this.localStream?.replaceVideo(videoStream);
            this.log(`Stream. Switch Video Device success.`, { device: deviceId, profile });
            this.$eventBus.$emit('video-state-changed', this.getVideoState());
        } catch (err) {
            this.error(`Stream. Switch Video device error.`, { message: err.message, device: deviceId });
        }
    }
    setVideoProfile(videoProfile) {
        this.localStream?.setVideoProfile?.(videoProfile);
    }
    async setVideoEncoderConfiguration(profile) {
        if (!this.initialized || !this.localStream) {
            return;
        }
        const videoData = {
            cameraId: this.videoDeviceId,
            encoderConfig: {
                frameRate: {
                    min: parseInt(profile.resolution.fps.min),
                    max: parseInt(profile.resolution.fps.max)
                },
                bitrateMin: parseInt(profile.bitrate.min),
                bitrateMax: parseInt(profile.bitrate.max),
                width: parseInt(profile.resolution.width),
                height: parseInt(profile.resolution.height)
            }
        }
        this.log('Stream. New Video encoder config', { profile });
        try {
            const videoStream = await AgoraRTC.createCameraVideoTrack(videoData);
            this.localStream?.replaceVideo(videoStream);
            this.log(`Stream. Set Video Encoder Configuration success.`, { device: this.videoDeviceId });
            this.$eventBus.$emit('video-state-changed', this.getVideoState());
        } catch (err) {
            this.error(`Stream. Set Video Encoder Configuration error.`, { message: err.message, device: this.videoDeviceId });
        }

    }
    playHostStreamForLearner() {
        this.playHostStreamForLearnerStarted = true;
        if (!store.getters.isHostHere && this.hostRemoteStream) {
            this.hostRemoteStream.play(INSTRUCTOR_VIEW, { muted: false });
            // this.hostRemoteStream.on('player-status-change', (evt) => this.listenForPlayerStatusChange(evt, this.hostRemoteStream, this));
        }
    }
    listenForPlayerStatusChange(evt, stream, self) {
        const streamId = stream.getId();
        if (evt.mediaType === 'audio') {
            if (evt.isErrorState || evt.status === 'paused') {
                store.dispatch('addAudioAutoplayFailed', streamId);
                self.error(`Stream. Autoplay failed.`, {
                    id: streamId,
                    isErrorState: evt.isErrorState,
                    mediaType: evt.mediaType,
                    status: evt.status,
                    reason: evt.reason,
                });
            } else {
                store.dispatch('removeAudioAutoplayFailed', streamId);
            }
        }
    }
    displayLearnerStream(stream) {
        const streamId = stream.getId();
        this.clearLearnerStreamView(streamId);
        this.log(`Stream. Display Learner stream view`, streamId);
        if (document.getElementById('learner-' + streamId)) {
            if (!this.localStream || this.localStream?.getId() != streamId) {
                try {
                    stream.play('learner-' + streamId);
                } catch (errState) {
                    if (errState && errState.status !== "aborted") {
                        const streamId = stream.getId();
                        store.dispatch('addAudioAutoplayFailed', streamId);
                        this.error(`Stream. Autoplay failed.`, { id: streamId });
                    }
                }
            } else {
                try {
                    stream.play('learner-' + streamId, { muted: true });
                } catch (errState) {
                    if (errState && errState.status !== "aborted") {
                        const streamId = stream.getId();
                        store.dispatch('removeAudioAutoplayFailed', streamId);
                    }
                }
            }
            // stream.player.resize && stream.player.resize();
            // stream.on('player-status-change', (evt) => this.listenForPlayerStatusChange(evt, stream, this));
        }
    }
    getUser() {
        return store.getters.user;
    }
    getChannelId() {
        return store.getters.session.id;
    }
    getSessionHostNid() {
        return store.getters.session.artistUserNid.toString();
    }
    getTemplateType() {
        return store.getters.streamsOnMainView.length + 1;
    }
    maxLearnersAllowed() {
        return (this.getTemplateType() - 1) + 3;
    }
    addToMainView(id) {
        store.dispatch('addStreamToMainView', id).then(() => {
            this.sendChannelMessage({
                type: ChannelEventType.MAIN_VIEW_STREAMS,
                items: store.getters.streamsOnMainView,
            });
            this.updateLayout();
        });
    }
    removeFromMainView(id) {
        store.dispatch('removeStreamFromMainView', id).then(() => {
            this.sendChannelMessage({
                type: ChannelEventType.MAIN_VIEW_STREAMS,
                items: store.getters.streamsOnMainView,
            });
            this.updateLayout();
        });
    }
    resumeAudioAutoplay() {
        if (this.hostRemoteStream) {
            const streamId = this.hostRemoteStream?.getId();
            this.hostRemoteStream?.resume().then(() => {
                store.dispatch('removeAudioAutoplayFailed', streamId);
                this.log(`Stream. Resume stream success.`, { id: streamId });
            }).catch((reason) => {
                this.error(`Stream. Resume stream error.`, { message: reason, id: streamId });
            })
        }
        for (let stream of store.getters.streams) {
            const streamId = stream.getId();
            if (!this.localStream || this.localStream?.getId() != streamId) {
                stream.resume().then(() => {
                    store.dispatch('removeAudioAutoplayFailed', streamId);
                    this.log(`Stream. Resume stream success.`, { id: streamId });
                }).catch((reason) => {
                    this.error(`Stream. Resume stream error.`, { message: reason, id: streamId });
                })
            }
        }
        store.dispatch('clearAudioAutoplayFailed');
    }
    stopSession() {
        this.log(`Stream. ${this.getUser()} clicks end session.`);
        return new Promise((resolve, reject) => {
            this._apiStopRecording().then(() => {
                this.sendChannelMessage({ type: ChannelEventType.SESSION_ENDED });
                this.handleDestroy();
                store.dispatch('setSessionStatus', 'ENDED');
                this.log(`Stream. Session ended.`);
                resolve();
            }).catch((e) => {
                this.error(`Stream. Session end error.`, { message: e.message });
                reject(e);
            })
        })
    }
    error() {
        if (this.localLogLevel <= 4) {
            console.error(...arguments);
        }
        if (this.remoteLog.enabled) {
            this.remoteLog.log.push({
                level: 'ERROR',
                dl: this._dl(),
                timestamp: +new Date(),
                duration: this.streamDuration(),
                memoryUsage: window.performance.memory,
                message: arguments[0],
                data: { ...arguments }
            });
        }
    }
    warn() {
        if (this.localLogLevel <= 3) {
            console.warn(...arguments);
        }
        if (this.remoteLog.enabled) {
            this.remoteLog.log.push({
                level: 'WARN',
                dl: this._dl(),
                timestamp: +new Date(),
                duration: this.streamDuration(),
                memoryUsage: window.performance.memory,
                message: arguments[0],
                data: { ...arguments }
            });
        }
    }
    log() {
        if (this.localLogLevel <= 2) {
            console.log(...arguments);
        }
        if (this.remoteLog.enabled) {
            this.remoteLog.log.push({
                level: 'LOG',
                dl: this._dl(),
                timestamp: +new Date(),
                memoryUsage: window.performance.memory,
                duration: this.streamDuration(),
                message: arguments[0],
                data: { ...arguments }
            });
        }
    }
    _addChatLogItem(nid, fullName, message) {
        this.chatLog.items.push({
            dl: this._dl(),
            utc: +new Date(),
            nid: nid,
            fullName: fullName,
            message: message
        });
    }
    _createRtmClient() {
        this.rtmClient = AgoraRTM.createInstance(this.appId);
    }
    _subscribeOnRtmClient() {
        this.rtmClient.on('ConnectionStateChanged', (newState, reason) => {
            this.log('Signal. Connection state changed: ' + newState, reason);
        });
        this.rtmClient.on('MessageFromPeer', ({ text: msg }, uid) => {
            this.log('Signal. Instant message received', uid, msg);
            const event = JSON.parse(msg);
            switch (event.type) {
                case 'HAND_UP':
                    store.dispatch('addHandUp', event);
                    store.dispatch('addAudience', {
                        uid: event.uid,
                        fullName: event.fullName,
                        role: event.role,
                    });
                    break;
                case 'HAND_DOWN':
                    store.dispatch('removeHandUp', event);
                    this._stopRemoteStreamLocally(event.uid);
                    this.stopRemoteStream(event.uid);
                    break;
                case 'HAND_UP_IGNORED':
                    this.$eventBus.$emit('set-hand-down');
                    break;
                case 'HAND_UP_ACCEPTED':
                    this.publishStream();
                    break;
                case 'INTRODUCE':
                    this.sendChannelMessage({ type: ChannelEventType.AUDIENCE });
                    break;
                case 'SET_INIT_STATE':
                    this.isMutedByHost = event.state.isMutedByHost;
                    if (!store.getters.isHostHere) {
                        if (this.isMutedByHost) {
                            this.disableAudio();
                        } else {
                            this.enableAudio();
                        }
                    }
                    break;
                case 'GET_STREAMS_LIST':
                    if (store.getters.isHostHere) {
                        this.sendInstantMessage(event.uid, {
                            type: 'STREAMS_LIST_OUT',
                            list: store.getters.streams.map(x => x.getId()),
                            mainView: store.getters.streamsOnMainView
                        });
                    }
                    break;
                case 'STREAMS_LIST_OUT':
                    store.dispatch('setStreamsOnMainView', event.mainView);
                    store.getters.streams.filter(x => event.list.includes(x));
                    break;
                case 'HAS_HAND_UP':
                    store.dispatch('setHandUpList', event.payload);
                    break;
            }
        });
    }
    _subscribeOnRtmChannel() {
        this.rtmChannel.on('ChannelMessage', ({ text: msg }, uid) => {
            this.log('Signal. Channel message received', uid, msg);
            const event = JSON.parse(msg);
            for (let handler of this.channelHandlers) {
                if (handler.canHandle(event.type)) {
                    handler.handle.call(this, event);
                    break;
                }
            }
        });
    }
    _addNewChatMessage(event) {
        store.dispatch('addChatMessage', event);
        store.dispatch('addAudience', {
            uid: event.uid,
            fullName: event.fullName,
            role: event.role,
        });
        if (store.getters.isHostHere) {
            this._addChatLogItem(event.uid, event.fullName, event.text);
        }
    }
    _createRtcClient() {
        this.rtcClient = AgoraRTC.createClient({
            mode: 'rtc',
            codec: 'vp8'
        });
    }
    _enableDualStreamMode() {
        this.rtcClient.enableDualStream(() => {
            this.log(`Stream. Dual mode enabled`);
            this._setLowStreamParameter();
        }, (err) => {
            this.error(`Stream. Dual mode enable error`, { message: err });
        });
    }
    _setLowStreamParameter() {
        this.rtcClient.setLowStreamParameter({
            bitrate: 500,
            framerate: 15,
            height: 480,
            width: 640,
        });
    }
    async _joinRtcChannel(token) {
        try {
            await this.rtcClient.join(this.appId, this.getChannelId(), token, this.getUser().nid);
            this.log(`Stream. Channel joined.`, { channel: this.getChannelId(), nid: this.getUser().nid });
        } catch (err) {
            this.error(`Stream. Join Channel error.`, { channel: this.getChannelId(), nid: this.getUser().nid, message: err.message || err || '' });
            throw err;
        }

    }
    _onStreamPublished() {
        this.log(`Stream. Local stream published.`, this.localStream?.getId());
        this.sendChannelMessage({ type: ChannelEventType.AUDIENCE });
        if (store.getters.isHostHere) {
            if (!this.localStream?.isPlaying()) {
                this.displayHostStream(this.localStream);
            }
            const streamProfile = store.getters.initStreamProfile;
            if (['STARTED'].includes(store.getters.session.status)) {
                return;
            }
            const profile = {
                width: streamProfile.resolution.width,
                height: streamProfile.resolution.height,
                fps: streamProfile.resolution.fps.max,
                bitrate: streamProfile.bitrate.max,
                recBitrate: streamProfile.resolution.recBitrate,
                timestamp: +new Date(),
            };
            this._apiStartRecording(profile).then((data) => {
                this.log(`Stream. Recording started`);
                store.dispatch('setSession', data.item);
                store.dispatch('setSessionStatus', 'STARTED');
                this.$eventBus.$emit('publish-stream-success');
                this.$eventBus.$emit('audio-state-changed', this.getAudioState());
                this.$eventBus.$emit('video-state-changed', this.getAudioState());
                this.sendChannelMessage({
                    type: ChannelEventType.SESSION,
                    session: data.item,
                });
            }).catch((err) => {
                self.error(`Stream. Start recording error`, { message: err.message });
                this.$eventBus.$emit('publish-stream-error', err);
            })
        } else {
            if (this.isMutedByHost) {
                this.disableAudio();
            }
            store.dispatch('addStream', this.localStream).then(() => this.updateLayout());
            this.$eventBus.$emit('audio-state-changed', this.getAudioState());
            this.$eventBus.$emit('video-state-changed', this.getVideoState());
        }
    }
    _onStreamUnpublished() {
        const streamId = this.localStream?.getId?.();
        this.log(`Stream. Local stream unpublished.`, { id: streamId });
        store.dispatch('removeStream', streamId).then(() => this.updateLayout());
    }
    _subscribeRtcEvents() {
        let self = this;
        this.rtcClient.on("client-banned", () => {
            store.dispatch('setSessionStatus', 'ENDED');
            this.handleDestroy();
            this.$eventBus.$emit('set-hand-down');
        });
        this.rtcClient.on("user-published", async (remoteUser, mediaType) => {
            this.log(`Stream. Remote stream published.`, { id: remoteUser.uid });
            await this.rtcClient.subscribe(remoteUser, mediaType);
            const stream = new AgoraStream(remoteUser.hasAudio && remoteUser.audioTrack, remoteUser.hasVideo && remoteUser.videoTrack, remoteUser.uid);
            this.log(`Stream. Remote stream subscribed.`, { id: stream.getId() });
            if (this.hostRemoteStream && this.hostRemoteStream?.getId() === stream.getId()) {
                this.hostRemoteStream.merge(stream);
            } else if (this.getSessionHostNid() == stream.getId()) {
                this._apiGetCourse().then((data) => {
                    // need to double check because user-published is triggered twise, for video and for audio
                    if (this.hostRemoteStream && this.hostRemoteStream?.getId() === stream.getId()) {
                        this.hostRemoteStream?.merge(stream);
                        return;
                    }
                    store.dispatch('setSession', data.item);
                    store.dispatch('setSessionStatus', 'STARTED');
                    this.displayHostStream(stream);
                    if (this.getUser().nid != stream.getId()) {
                        this.hostRemoteStream = stream;
                        this.emit('host-video-added', this.hostRemoteStream);
                    }
                    if (this.playHostStreamForLearnerStarted) {
                        setTimeout(() => {
                            this.playHostStreamForLearner();
                        }, 1000);
                    }
                });
            } else {
                store.dispatch('addStream', stream).then(() => this.updateLayout());
                stream.setAudioVolume(65);
            }
        })
        self.rtcClient.on("user-joined", (remoteUser) => {
            self.log(`Stream. Peer online.`, { id: remoteUser.uid });
            this.sendInstantMessage(remoteUser.uid, { type: 'AUDIENCE' });
            if (store.getters.isHostHere) {
                this.sendInstantMessage(remoteUser.uid, { type: 'SET_INIT_STATE', state: { isMutedByHost: this.isMutedByHost } });
            }
        });
        self.rtcClient.on("user-left", (remoteUser) => {
            self.log(`Stream. Peer left.`, { id: remoteUser.uid });
            store.dispatch('removeStream', remoteUser.uid).then(() => this.updateLayout());
            if (store.getters.isHostHere) {
                this.stopRemoteStream(remoteUser.uid);
            }
        });
        self.rtcClient.on("stream-removed", (evt) => {
            const stream = evt.stream;
            const streamId = stream.getId();
            self.log(`Stream. Stream removed.`, { id: stream.getId() });
            store.dispatch('removeStream', streamId).then(() => this.updateLayout());
            if (self.isHostHere) {
                self.stopRemoteStream(streamId);
            }
            if (streamId == self.getSessionHostNid() && self.getSessionHostNid() != self.getUser().nid) {
                self.hostRemoteStream = false;
            }
        });
        self.rtcClient.on("liveStreamingStopped", (evt) => {
            self.warn(`Stream. Stream stopped.`, { id: evt.stream.getId() });
        });
        self.rtcClient.on("connection-state-change", (evt) => {
            this.log('Signal. Connection state changed: ' + evt);
            // stream ended. this is called when session timeout occures
            if (evt === 'DISCONNECTED' && store.getters.isHostHere) {
                this.sessionTimedOut = true;
                this.sendChannelMessage({ type: 'SESSION_ENDED' });
                this.handleDestroy();
                store.dispatch('setSessionStatus', 'ENDED');
                this.log(`Stream. Session ended by remote server.`);
            }
        });
        self.rtcClient.on("exception", (evt) => {
            self.error(`Stream. Stream exception.`, { message: evt.msg, id: evt.uid, code: evt.code });
        });
        self.rtcClient.on("error", (err) => {
            self.error(`Stream. Stream error.`, { message: err.message || '', reason: err.reason || '' });
        });
        self.rtcClient.on("mute-audio", (evt) => {
            self.log(`Stream. Stream audio muted.`, { id: evt.uid });
        });
        self.rtcClient.on("unmute-audio", (evt) => {
            self.log(`Stream. Stream audio unmuted.`, { id: evt.uid });
        });
    }
    async _checkDeviceAccess(createTrackFn, type, sourceFlag, sourceName) {
        if (this[sourceFlag]) {
            return;
        }
        try {
            const tempTrack = await createTrackFn();
            tempTrack.close();
            this[sourceFlag] = 'success';
        } catch (err) {
            const errorCode = err.msg;
            switch (errorCode) {
                case 'NotAllowedError':
                    this[sourceFlag] = 'blocked';
                    return { code: errorCode, type: type, text: `${sourceName} is blocked by browser permissions` };
                case 'NotReadableError':
                    this[sourceFlag] = 'busy';
                    return { code: errorCode, type: type, text: `${sourceName} is busy by other application` };
                case 'NotFoundError':
                    this[sourceFlag] = 'not-found';
                    return { code: errorCode, type: type, text: `${sourceName} not found` };
                default:
                    this[sourceFlag] = 'blocked';
                    return { code: errorCode, type: type, text: `${sourceName} not found` };
            }
        }

    }
    async _checkDevicesAccess() {
        const self = this;
        if (self.audioDeviceAccessChecked && self.videoDeviceAccessChecked) {
            return;
        }

        const errors = {
            audio: await this._checkDeviceAccess(AgoraRTC.createMicrophoneAudioTrack, 'audio', 'audioDeviceAccessChecked', 'Microphone'),
            video: await this._checkDeviceAccess(AgoraRTC.createCameraVideoTrack, 'video', 'videoDeviceAccessChecked', 'Camera'),
        };

        if (errors.audio || errors.video) {
            this.$eventBus.$emit('devices-error', errors);
            this.error(`Stream. Check Devices Access error.`, { errors });
        }
        this.getDevices();
    }
    _apiGetCourse() {
        return api.courses.getOne(this.getChannelId()).then(({ data }) => {
            return data;
        });
    }
    _apiStartRecording(data) {
        return api.courses.start(this.getChannelId(), data).then(({ data }) => {
            return data;
        });
    }
    _apiStopRecording() {
        return api.courses.stop(this.getChannelId()).then(({ data }) => {
            this.log(`Stream. Recording ended`);
            return data;
        });
    }
    _stopRemoteStreamLocally(id) {
        const found = store.getters.streams.find(x => x.getId() === id);
        if (found) {
            this.log(`Stop remote stream`, !!found);
            found.stop();
        }
        store.dispatch('removeStream', id).then(() => this.updateLayout());
    }
    _dl() {
        return '' + (+new Date()) + Math.random();
    }
    _uploadChatLog() {
        if (this.chatLog.items.length && !this.chatLog.logUploading) {
            this.chatLog.logUploading = true;
            return api.courses.logChat(this.getChannelId(), {
                items: this.chatLog.items
            }).then(({ data }) => {
                this.chatLog.items.splice(0, data.recordsTotal);
            }).catch((e) => {
                this.error(`Chat uploading error`, { message: e.message });
            }).finally(() => {
                this.chatLog.logUploading = false;
            });
        }
        return Promise.resolve();
    }
    _initChatUpload() {
        this.chatLog.uploadInterval = setInterval(() => {
            this._uploadChatLog();
        }, 500);
    }
    _initRemoteLog() {
        this._logBrowserInfo();
        this.remoteLog.uploadInterval = setInterval(() => {
            this._uploadRemoteLog();
        }, 500);
        this.remoteLog.getStatusInterval = setInterval(() => {
            const audioStats = this.rtcClient.getLocalAudioStats();
            const videoStats = this.rtcClient.getLocalVideoStats();
            if (this.localStream && (audioStats || videoStats)) {
                this.remoteLog.log.push({
                    level: 'AUDIT',
                    dl: this._dl(),
                    id: this.localStream?.getId(),
                    timestamp: +new Date(),
                    duration: this.streamDuration(),
                    memoryUsage: window.performance.memory,
                    type: 'localStream',
                    data: {
                        audioSendBytes: audioStats?.sendBytes,
                        audioSendPackets: audioStats?.sendPackets,
                        audioSendPacketsLost: audioStats?.sendPacketsLost,
                        videoSendBytes: videoStats?.sendBytes,
                        videoSendFrameRate: videoStats?.sendFrameRate,
                        videoSendPackets: videoStats?.sendPackets,
                        videoSendPacketsLost: videoStats?.sendPacketsLost,
                        videoSendResolutionHeight: videoStats?.sendResolutionHeight,
                        videoSendResolutionWidth: videoStats?.sendResolutionWidth,
                    }
                })
            }
            const remoteAudioStats = this.rtcClient.getRemoteAudioStats();
            const remoteVideoStats = this.rtcClient.getRemoteVideoStats();
            const statIds = new Set([...Object.keys(remoteAudioStats), ...Object.keys(remoteVideoStats)]);
            for (let id of statIds) {
                const aStats = remoteAudioStats[id];
                const vStats = remoteVideoStats[id];
                this.remoteLog.log.push({
                    level: 'AUDIT',
                    dl: this._dl(),
                    id: id,
                    timestamp: +new Date(),
                    duration: this.streamDuration(),
                    type: id === String(this.hostRemoteStream?.getId()) ? 'hostStream' : 'learnerStream',
                    memoryUsage: window.performance.memory,
                    data: {
                        audioReceiveBytes: aStats?.receiveBytes,
                        audioReceiveDelay: aStats?.receiveDelay,
                        audioReceivePackets: aStats?.receivePackets,
                        audioReceivePacketsLost: aStats?.receivePacketsLost,
                        endToEndDelay: vStats?.end2EndDelay,
                        videoReceiveBytes: vStats?.receiveBytes,
                        videoReceiveDecodeFrameRate: vStats?.receiveDecodeFrameRate,
                        videoReceiveDelay: vStats?.receiveDelay,
                        videoReceiveFrameRate: vStats?.receiveFrameRate,
                        videoReceivePackets: vStats?.receivePackets,
                        videoReceivePacketsLost: vStats?.receivePacketsLost,
                        videoReceiveResolutionHeight: vStats?.receiveResolutionHeight,
                        videoReceiveResolutionWidth: vStats?.receiveResolutionWidth
                    }
                })
            }
        }, 15010);
    }
    _logBrowserInfo() {
        if (this.remoteLog.enabled) {
            this.remoteLog.log.push({
                level: 'BROWSER',
                dl: this._dl(),
                id: this.getUser()?.id,
                timestamp: +new Date(),
                memoryUsage: window.performance.memory,
                duration: this.streamDuration(),
                data: Bowser.parse(window.navigator.userAgent),
            })
        }
    }
    _uploadRemoteLog() {
        if (this.remoteLog.log.length && !this.remoteLog.logUploading) {
            this.remoteLog.logUploading = true;
            return api.courses.debug(this.getChannelId(), {
                channel: this.getChannelId(),
                uid: this.getUser().id,
                nid: this.getUser().nid,
                items: this.remoteLog.log
            }).then(({ data }) => {
                this.remoteLog.log.splice(0, data.recordsTotal);
            }).catch(() => {
                clearInterval(this.remoteLog.uploadInterval);
                this.remoteLog.log = [];
            }).finally(() => {
                this.remoteLog.logUploading = false;
            });
        }
        return Promise.resolve();
    }
    handleDestroy() {
        if (!this.rtmClient && !this.rtcClient) {
            return
        }
        this.warn(`Destroying...`);
        try {
            this.rtmClient?.logout?.();
            this.unPublishStream();
            this.localStream?.close?.();
            this.rtcClient?.leave?.(() => {
                this.log(`Client left stream success`);
            }, (e) => {
                this.error(`Client failed to leave stream client`, e);
            });
        } catch (e) {
            this.error(`Client leave channel failed`, e);
        } finally {
            this.rtmClient = null;
            this.rtcClient = null;
            this.localStream = null;
            this.hostRemoteStream = null;
            store.dispatch('clearStreams');
        }
        if (this.remoteLog.enabled) {
            if (this.remoteLog.uploadInterval) {
                if (this.remoteLog.log.length) {
                    this._uploadRemoteLog().then(() => {
                        clearInterval(this.remoteLog.uploadInterval);
                    });
                } else {
                    clearInterval(this.remoteLog.uploadInterval);
                }
            }
            if (this.remoteLog.getStatusInterval) {
                clearInterval(this.remoteLog.getStatusInterval);
            }
        }
        if (this.chatLog.uploadInterval) {
            if (this.chatLog.items.length) {
                this._uploadChatLog().then(() => {
                    clearInterval(this.chatLog.uploadInterval);
                });
            } else {
                clearInterval(this.chatLog.uploadInterval);
            }
        }
    }
    on(event, handler) {
        this.$eventBus.$on(event, handler)
    }
    emit(event, data) {
        this.$eventBus.$emit(event, data)
    }
    install(Vue, options) {
        this.$eventBus = new Vue()
        this.appId = options.appId;
        Vue.prototype.$agora = this;
    }
}
