<template>
  <div class="page session-page">
    <div
      v-show="isPageReady"
      class="close-session"
      v-if="
        !isConnectionConflict &&
        !fullScreenMode &&
        session &&
        session.status === 'STARTED'
      "
      :disabled="apiRequesting"
      @click="closeSessionClick"
    />
    <section v-show="isPageReady">
      <b-container>
        <b-row>
          <b-col v-if="session">
            <div
              class="stream-wrapper"
              id="stream-wrapper"
              :class="{ 'full-screen inner': fullScreenMode }"
            >
              <div class="session-stream-wrapper">
                <div class="session-empty-state" v-show="isEmptyState">
                  <div class="wrapper" v-if="isFinishedState">
                    <p v-html="sessionEndedMessage"></p>
                    <b-button
                      variant="blue"
                      :to="{
                        name: 'catch-up-play',
                        params: { courseId: session.id },
                      }"
                      v-html="sessionEndedBtnViewName"
                    />
                    <p class="mt-20">
                      Or, check out upcoming 2GTHR Sessions here
                    </p>
                    <b-button variant="blue" :to="{ name: 'upcoming' }">Upcoming Sessions</b-button>
                  </div>
                  <div
                    class="wrapper"
                    v-if="!isConnectionConflict && session.status === 'UPCOMING'"
                  >
                    <p>
                      Session starts
                      {{
                        session.schedule | moment("MMMM D, YYYY \\a\\t h:mm A")
                      }}
                    </p>
                  </div>
                  <div class="wrapper" v-if="isConnectionConflict">
                    <p>Your 2GTHR account is in use on another device</p>
                    <b-button variant="blue" :to="{ name: 'upcoming' }">Go Back</b-button>
                  </div>
                </div>
                <stream-view v-show="!isEmptyState" />
                <stream-chat
                  :isChatAvailable="
                    !this.isConnectionConflict &&
                    isChatAvailable &&
                    session.status !== 'ENDED'
                  "
                  :canSendMessages="canSendMessages"
                />
              </div>
              <div class="session-control-bar">
                <div>
                  <stream-settings
                    :canUseSettings="canUseSettings"
                    :streamProfile="streamProfile"
                    :isGreenRoomAllowed="isGreenRoomAllowed"
                  />
                  <stream-control
                    :isHostHere="isHostHere"
                    :canUseControl="canUseControl"
                  />
                  <stream-state
                    :isHostHere="isHostHere"
                    :isGreenRoomAllowed="isGreenRoomAllowed"
                    :isStartAvailable="isStartAvailable"
                    :streamProfile="streamProfile"
                  />
                </div>
                <div></div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <confirmation-modal
      id="end-session"
      message="Are you sure you want to end this Session?"
      cancel-button-text="Nevermind"
      confirm-button-text="Yes, Leave Session"
      :confirmed-cb="leaveSession"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {permissions} from "@/mixins/permissions";
import { bag } from "@/services/bag";
import StreamView from "../../shared/Session/StreamView";
import StreamControl from "../../shared/Session/StreamControl";
import StreamSettings from "../../shared/Session/StreamSettings";
import StreamState from "../../shared/Session/StreamState";
import StreamChat from "../../shared/Session/StreamChat";
import ConfirmationModal from "../../shared/Modals/Confirmation";
import { topRoleOrSubscribedMiddleware } from "../../../middlewares";
import { isAllowedByTopRolesOrSubscribedDefault } from "@/middlewares/base/conditions";
import {ChannelEventType} from "@/shared/enums";

export default {
  name: "SessionPage",
  mixins: [permissions],
  data() {
    return {
      isPageReady: false,
      isConnectionConflict: false,
      sessionInitStatus: null,
      isClosing: false,
      interval: null,
      untilStartTimeSec: null,
      isStartAvailable: false,
      isChatAvailable: false,
      isGreenRoomAvailable: false,
      chatInitialized: false,
      streamInitializationReady: false,
      streamInitialized: false,
      mediaState: {
        audio: false,
        video: false,
      },
      streamProfile: {
        audioSource: null,
        videoSource: null,
        audioProfile: "high_quality",
        bitrate: {
          min: 4000,
          max: 5000,
        },
        resolution: {
          width: 1920,
          height: 1080,
          fps: {
            min: 30,
            max: 30,
          },
          recBitrate: 3000,
        },
        videoProfile: "720p_2",
        agc: false,
        aec: false,
        ans: false,
      },
      courseId: null,
      isUserSubscribedOrTopTole: false,
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "trialStarted",
      "subscriptions",
      "session",
      "fullScreenMode",
      "apiRequesting",
      "permissions",
    ]),
    isInstructorHere() {
      return this.user.role === "INSTRUCTOR" || this.user.role === "ADMIN";
    },
    isFinishedState() {
      return ["ENDED", "RECORDED", "EXPIRED"].includes(this.session?.status);
    },
    isEmptyState() {
      return (
        ["UPCOMING", "ENDED", "RECORDED"].includes(this.session.status) ||
        this.isConnectionConflict
      );
    },
    canUseSettings() {
      return (
        !this.isConnectionConflict &&
        ["UPCOMING", "STARTED", "GREEN_ROOM"].includes(this.session.status)
      );
    },
    canUseControl() {
      return (
        !this.isConnectionConflict &&
        ["STARTED"].includes(this.session.status) &&
        this.isUserSubscribedOrTopTole
      );
    },
    canSendMessages() {
      return (
        this.isChatAvailable &&
        (this.isUserSubscribedOrTopTole || this.session.canNonsubscribedComment)
      );
    },
    sessionEndedMessage() {
      if (
        this.sessionInitStatus === "UPCOMING" ||
        this.sessionInitStatus === "STARTED"
      ) {
        return `Thanks for joining this Session!  If you want to watch it again, a recording is available here`;
      }
      return `This Session has ended, but you can watch it on the Catch Up page.`;
    },
    sessionEndedBtnViewName() {
      if (
        this.sessionInitStatus === "UPCOMING" ||
        this.sessionInitStatus === "STARTED"
      ) {
        return `Watch Again`;
      }
      return `Let's Go`;
    },
  },
  watch: {
    isStartAvailable: function (newVal) {
      if (newVal && this.interval) {
        clearInterval(this.interval);
      }
    },
    isChatAvailable: async function (newVal, oldVal) {
      if (newVal && !oldVal) {
        await this.initChat();
        this.$agora.sendMessageIfCan(
            [this.isHostHere, this.isManagedSession],
            () => this.$agora.sendChannelMessage({ type: ChannelEventType.PING_MANAGERS })
        );
        this.$agora.sendMessageIfCan(
            [this.canManageOnStage],
            () => this.$agora.sendChannelMessage({ type: ChannelEventType.HAS_HAND_UP_CHANNEL })
        );
      }
    },
    isGreenRoomAvailable: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.init();
      }
    },
    chatInitialized(newVal) {
      if (!this.streamInitialized && newVal && this.streamInitializationReady) {
        this.initStreamSession();
      }
    },
    streamInitializationReady(newVal) {
      if (!this.streamInitialized && newVal) {
        this.initStreamSession();
      }
    },
  },
  methods: {
    ...mapActions([
      "initSession",
      "setSessionStatus",
      "setFullscreenMode",
      "setSession",
    ]),
    setProfileConfig() {
      if (this.isGreenRoomAllowed) {
        const profile = bag.getSessionProfile();
        if (profile) {
          this.streamProfile.resolution = profile.resolution;
          this.streamProfile.audioProfile = profile.audioProfile;
          this.streamProfile.bitrate = profile.bitrate;
          this.streamProfile.agc = profile.agc;
          this.streamProfile.aec = profile.aec;
          this.streamProfile.ans = profile.ans;
        } else {
          this.streamProfile.agc = false;
          this.streamProfile.aec = false;
          this.streamProfile.ans = false;
        }
      } else {
        this.streamProfile.agc = true;
        this.streamProfile.aec = true;
        this.streamProfile.ans = true;
        this.streamProfile.audioProfile = "speech_standard";
        this.streamProfile.resolution = {
          width: 640,
          height: 480,
          fps: {
            min: 15,
            max: 15,
          },
          recBitrate: 500,
        };
        this.streamProfile.bitrate = {
          min: 500,
          max: 1000,
        };
      }
    },
    init() {
      if (this.session.status === "EXPIRED") {
        this.setSessionStatus("ENDED");
        return;
      }
      if (
        this.session.status === "EXPIRED" ||
        this.session.status === "ENDED"
      ) {
        return;
      }
      this.subscribeEvents();
      this.$agora.init();
      const startSharing =
        this.isHostHere &&
        this.isStartAvailable &&
        this.session.status === "STARTED";
      this.$agora.setOnDevicesFoundCallback(async () => {
        this.$agora.createLocalStream(this.user.nid, this.streamProfile, () => {
          if (
            this.isGreenRoomAllowed &&
            this.session.status === "UPCOMING" &&
            !this.$agora.hostRemoteStream
          ) {
            this.$agora.displayHostStream();
            this.setSessionStatus("GREEN_ROOM");
          } else if (this.isHostHere && this.session.status === "STARTED") {
            this.$agora.displayHostStream();
          }
          if (startSharing) {
            this.$agora.publishStream();
          }
        });
      });
      this.streamInitializationReady = true;
    },
    async verifyConnectionConflict() {
      if (this.isFinishedState) {
        return;
      }
      try {
        this.isConnectionConflict = await this.$agora.isConnectionConflict();
      } catch (e) {
        return console.warn(`error verification`, e);
      }
    },
    async initChat() {
      if (this.isFinishedState || this.isConnectionConflict) {
        return;
      }
      await this.$agora.initChat();
      this.chatInitialized = true;
    },
    async initStreamSession() {
      if (this.isFinishedState || this.isConnectionConflict) {
        return;
      }
      this.streamInitialized = true;
      await this.$agora.joinStreamSession();
      this.$agora.getDevices();
    },
    updateStartTime() {
      this.untilStartTimeSec = this.$moment(this.session?.schedule).diff(
        this.$moment(),
        "seconds"
      );
      this.isStartAvailable = this.session && this.untilStartTimeSec <= 0;
      this.isChatAvailable = this.session && this.untilStartTimeSec <= 5 * 60;
      this.isGreenRoomAvailable =
        this.session && this.untilStartTimeSec <= 60 * 60;
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.updateStartTime();
        }, 5000);
      }
    },
    subscribeEvents() {
      this.$agora.on("devices-error", (event) => {
        let message = `Please enable pop-ups and access to your camera and microphone for 2GTHR.co in your browser settings.`;
        if (event.audio && event.video) {
          if (event.video.code === "NotAllowedError") {
            message = `Please enable access to your camera and microphone for 2GTHR.co in your browser settings.`;
          } else if (event.video.code === "NotReadableError") {
            message = `Your camera and microphone devices are busy with another application.`;
          } else if (
            event.video.code === "NotFoundError" ||
            event.video.code === "AbortError"
          ) {
            message = `Please enable your camera and microphone for 2GTHR.co.`;
          }
        } else if (event.audio) {
          if (event.audio.code === "NotAllowedError") {
            message = `Please enable access to your microphone for 2GTHR.co in your browser settings.`;
          } else if (event.audio.code === "NotReadableError") {
            message = `Your microphone device is busy with another application.`;
          } else if (
            event.audio.code === "NotFoundError" ||
            event.audio.code === "AbortError"
          ) {
            message = `Please enable your microphone for 2GTHR.co.`;
          }
        } else if (event.video) {
          if (event.video.code === "NotAllowedError") {
            message = `Please enable access to your camera for 2GTHR.co in your browser settings.`;
          } else if (event.video.code === "NotReadableError") {
            message = `Your camera device is busy with another application.`;
          } else if (
            event.video.code === "NotFoundError" ||
            event.video.code === "AbortError"
          ) {
            message = `Please enable your camera for 2GTHR.co.`;
          }
        }
        this.$alert.warn({
          classes: `center`,
          text: message,
        });
      });
      this.$agora.on("devices-found", () => {
        this.$alert.warn({
          classes: `center`,
          text: `Please enable pop-ups and access to your camera and microphone for 2GTHR.co in your browser settings.`,
        });
      });
      this.$agora.on("publish-stream-error", () => {
        this.$alert.warn({
          classes: `center`,
          text: `Please enable pop-ups and access to your camera and microphone for 2GTHR.co in your browser settings.`,
        });
      });
    },
    getCourseSession() {
      return this.initSession(this.courseId)
        .then((course) => {
          if (
            course.isSoundCheck &&
            !course.participants.includes(this.user.id)
          ) {
            this.setSession(null);
            return this.$notify({
              type: "error",
              text: `Session not found`,
            });
          }
          this.sessionInitStatus = course.status;
        })
        .catch((response) => {
          console.error(response);
        });
    },
    closeSessionClick() {
      this.$root.$emit("bv::show::modal", "confirmation-modal-end-session");
    },
    showStopSessionWarning() {
      this.$alert.warn({
        force: true,
        classes: `center top`,
        text: `Make sure click "End Session" button to stop session recording.`,
      });
    },
    leaveSession() {
      this.isClosing = true;
      if (this.isHostHere) {
        this.$agora.stopSession().finally(() => {
          this.isClosing = false;
          return this.$router.replace({name: 'catch-up'});
        });
      } else {
        this.isClosing = false;
        window.location.href = '/catch-up'
      }
    },
    sessionLeft() {
      window.onbeforeunload = null;
      this.$agora.handleDestroy();
      if (this.interval) {
        clearInterval(this.interval);
      }
      window.removeEventListener("unload", this.sessionLeft);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.courseId = to.params.courseId;
      vm.getCourseSession().then(async () => {
        if (!vm.session?.isFree) {
          let redirected = false;
          const redirectTo = topRoleOrSubscribedMiddleware();
          if (redirectTo) {
            if (typeof redirectTo.then === "function") {
              redirectTo.then((res) => {
                if (res) {
                  redirected = true;
                  vm.$router.push(res);
                }
              });
            } else {
              redirected = true;
              vm.$router.push(redirectTo);
            }
          }
          if (redirected) {
            return;
          }
        }
        window.onbeforeunload = function (event) {
          if (!vm.isConnectionConflict) {
            if (
              vm.$route.name === "session" &&
              vm.session.status === "STARTED" &&
              vm.isHostHere
            ) {
              event.preventDefault();
              event.returnValue = "";
              return "Are you sure to leave the session?";
            }
          }
        };
        window.addEventListener("unload", vm.sessionLeft);
        await vm.verifyConnectionConflict();
        vm.isUserSubscribedOrTopTole = isAllowedByTopRolesOrSubscribedDefault();
        vm.setProfileConfig();
        vm.updateStartTime();
        vm.isPageReady = true;
      });
    });
  },
  beforeDestroy() {
    this.sessionLeft();
  },
  beforeRouteLeave(to, from, next) {
    this.sessionLeft();
    next();
  },
  components: {
    StreamChat,
    StreamControl,
    StreamSettings,
    StreamState,
    StreamView,
    ConfirmationModal,
  },
};
</script>
