var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page session-page"},[(
      !_vm.isConnectionConflict &&
      !_vm.fullScreenMode &&
      _vm.session &&
      _vm.session.status === 'STARTED'
    )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPageReady),expression:"isPageReady"}],staticClass:"close-session",attrs:{"disabled":_vm.apiRequesting},on:{"click":_vm.closeSessionClick}}):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPageReady),expression:"isPageReady"}]},[_c('b-container',[_c('b-row',[(_vm.session)?_c('b-col',[_c('div',{staticClass:"stream-wrapper",class:{ 'full-screen inner': _vm.fullScreenMode },attrs:{"id":"stream-wrapper"}},[_c('div',{staticClass:"session-stream-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmptyState),expression:"isEmptyState"}],staticClass:"session-empty-state"},[(_vm.isFinishedState)?_c('div',{staticClass:"wrapper"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.sessionEndedMessage)}}),_c('b-button',{attrs:{"variant":"blue","to":{
                      name: 'catch-up-play',
                      params: { courseId: _vm.session.id },
                    }},domProps:{"innerHTML":_vm._s(_vm.sessionEndedBtnViewName)}}),_c('p',{staticClass:"mt-20"},[_vm._v(" Or, check out upcoming 2GTHR Sessions here ")]),_c('b-button',{attrs:{"variant":"blue","to":{ name: 'upcoming' }}},[_vm._v("Upcoming Sessions")])],1):_vm._e(),(!_vm.isConnectionConflict && _vm.session.status === 'UPCOMING')?_c('div',{staticClass:"wrapper"},[_c('p',[_vm._v(" Session starts "+_vm._s(_vm._f("moment")(_vm.session.schedule,"MMMM D, YYYY \\a\\t h:mm A"))+" ")])]):_vm._e(),(_vm.isConnectionConflict)?_c('div',{staticClass:"wrapper"},[_c('p',[_vm._v("Your 2GTHR account is in use on another device")]),_c('b-button',{attrs:{"variant":"blue","to":{ name: 'upcoming' }}},[_vm._v("Go Back")])],1):_vm._e()]),_c('stream-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmptyState),expression:"!isEmptyState"}]}),_c('stream-chat',{attrs:{"isChatAvailable":!this.isConnectionConflict &&
                  _vm.isChatAvailable &&
                  _vm.session.status !== 'ENDED',"canSendMessages":_vm.canSendMessages}})],1),_c('div',{staticClass:"session-control-bar"},[_c('div',[_c('stream-settings',{attrs:{"canUseSettings":_vm.canUseSettings,"streamProfile":_vm.streamProfile,"isGreenRoomAllowed":_vm.isGreenRoomAllowed}}),_c('stream-control',{attrs:{"isHostHere":_vm.isHostHere,"canUseControl":_vm.canUseControl}}),_c('stream-state',{attrs:{"isHostHere":_vm.isHostHere,"isGreenRoomAllowed":_vm.isGreenRoomAllowed,"isStartAvailable":_vm.isStartAvailable,"streamProfile":_vm.streamProfile}})],1),_c('div')])])]):_vm._e()],1)],1)],1),_c('confirmation-modal',{attrs:{"id":"end-session","message":"Are you sure you want to end this Session?","cancel-button-text":"Nevermind","confirm-button-text":"Yes, Leave Session","confirmed-cb":_vm.leaveSession}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }