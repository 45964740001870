export default function nameToChars(fullName) {
    if (!fullName) {
        return '';
    }
    let res = '';
    const chunks = fullName.split(' ');
    if (chunks[0]) {
        res += chunks[0][0].toString().toUpperCase();
    }
    if (chunks[1]) {
        res += chunks[1][0].toString().toUpperCase();
    }
    return res;
}
