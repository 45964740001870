<template>
    <div class="page catch-up-page">
        <section>
            <b-container>
                <b-row class="mb-100">
                    <b-col cols="12" sm="6">
                        <h1 class="text-stroke">Catch up on what<br/> you missed</h1>
                        <p>
                            Never miss out on seeing your favorite artists. Browse our library of past live performances.
                        </p>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <div class="filters-wrapper float-right">
                            <b-input-group>
                                <b-form-input placeholder="Search Sessions" v-on:keydown.enter="searchBy" v-model="search"></b-form-input>
                                <button v-bind:class="{'not-empty': search && search === lastSearch}" v-on:click="startSearch"></button>
                            </b-input-group>
                            <b-input-group v-if="false">
                                <b-form-select
                                        v-on:change="resetPagination"
                                        :options="categoriesList"
                                        text-field="title"
                                        value-field="id"
                                        v-model="category"
                                />
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div v-if="courses && courses.length" class="courses-wrapper text-center">
                            <div v-for="course in courses" v-bind:key="course.id">
                                <course :course="course"></course>
                            </div>
                        </div>
                        <infinite-loading :identifier="infiniteId" @infinite="requestCourses">
                            <div slot="spinner">Loading...</div>
                            <div slot="no-more" class="hide"></div>
                            <div slot="no-results">
                                <div class="empty-state empty-list text-center">
                                    <h3>No results, try another category</h3>
                                </div>
                            </div>
                        </infinite-loading>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <artist-modal :select-category-cb="selectCategory"></artist-modal>
        <session-player-modal/>
    </div>
</template>

<script>

    import {api} from "../../../services/api";
    import {mapActions, mapGetters} from "vuex";
    import ArtistModal from "../../shared/Modals/Artist";
    import Course from "../../shared/Content/Course";
    import SessionPlayerModal from "../../shared/Modals/SessionPlayer";

    export default {
        name: 'CatchUpPage',
        metaInfo: {
            title: 'Catch Up on Recorded Sessions | 2GTHR',
            meta: [
                {vmid: 'og:title', property: 'og:title', content: 'Catch Up on Recorded Sessions | 2GTHR'},
                {vmid: 'description', property: 'description', content: 'Catch up on the best online music. View our performance catalog of past sessions.'},
                {vmid: 'og:description', property: 'og:description', content: 'Catch up on the best online music. View our performance catalog of past sessions.'},
            ]
        },
        data () {
            return {
                page: 0,
                category: null,
                search: null,
                lastSearch: null,
                lastIndex: null,
                infiniteId: +new Date(),
                courses: [],
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'categories',
            ]),
            categoriesList() {
                if (!this.categories) {
                    return [];
                }
                return [...[{id: null, title: 'All'}]];
            },
            isInstructorHere() {
                return this.user && ['ADMIN', 'INSTRUCTOR'].includes(this.user.role)
            },
        },
        methods: {
            ...mapActions([
                'setArtist',
                'initCategories',
                'setRecording',
            ]),
            initSearch(query) {
                if (query.category) {
                    this.category = query.category;
                }
                if (query.search) {
                    this.search = query.search;
                }
            },
            selectCategory(category) {
                this.search = '';
                this.category = category.id;
                this.resetPagination();
            },
            startSearch () {
                if (this.search === this.lastSearch) {
                    this.clearSearch();
                } else {
                    this.searchBy();
                }
            },
            searchBy() {
                if (this.search.length >= 3 || !this.search.length) {
                    this.resetPagination();
                }
            },
            requestCourses($state) {
                const query = {
                    params: {
                        page: this.page,
                        search: this.search,
                        category: this.category,
                        lastIndex: this.lastIndex,
                        type: 'past',
                    }
                };
                if (this.isInstructorHere) {
                    query.params.addSoundChecks = true;
                }
                api.courses.list(query).then(({data}) => {
                    this.lastSearch = this.search;
                    if (data.items.length) {
                        this.page += 1;
                        this.courses.push(...data.items);
                        this.lastIndex = this.courses[this.courses.length - 1].utc
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
            },
            clearSearch() {
              this.search = '';
              this.resetPagination();
            },
            resetPagination() {
                this.page = 0;
                this.courses = [];
                this.lastIndex = undefined;
                this.infiniteId += 1;
                const data = {};
                if (this.category) {
                    data.category = this.category
                }
                if (this.search) {
                    data.search = this.search
                }
                const queryString = (new URLSearchParams(data)).toString();
                const query = Object.keys(data).length ? `?${queryString}` : ``;
                window.history.replaceState({}, 'Catch Up on Recorded Sessions | 2GTHR', `/catch-up${query}`);
            },
            showArtistModal(artist) {
                this.setArtist(artist).then(() => {
                    this.$root.$emit('bv::show::modal', 'artist-modal')
                });
            },
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                const courseId = to.params.courseId;
                if (courseId) {
                    api.courses.watch(courseId).then(({data}) => {
                        vm.setRecording(data);
                        vm.$root.$emit('bv::show::modal', 'session-player-modal')
                    });
                }
                vm.initCategories();
                vm.initSearch(to.query);
            });
        },
        components: {
            ArtistModal,
            SessionPlayerModal,
            Course,
        }
    }
</script>
