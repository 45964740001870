<template>
    <div class="schedule-badge text-left" :class="{'soundcheck': schedule.course.isSoundCheck}">
        <div class="date-wrapper">
            <div class="date-badge">
                <p v-if="!isToday" class="weekday">{{ schedule.course.schedule | moment('ddd') }}</p>
                <p v-if="isToday" class="weekday today">Today</p>
                <p class="month">{{ schedule.course.schedule | moment('MMMM') }}</p>
                <p class="day">{{ schedule.course.schedule | moment('D') }}</p>
            </div>
        </div>
        <div class="image-column">
            <div class="image-wrapper" v-bind:style="{backgroundImage: 'url(' + (schedule.course.imageUrl ? schedule.course.imageUrl : schedule.course.artist.imageUrl) + ')'}">
                <div class="chevron-down-btn" @click="showArtistModal(schedule.course.artist)"></div>
            </div>
        </div>
        <div class="info-wrapper">
            <a v-if="false && schedule.course.artist.category" class="category-badge mb-4">
                {{ schedule.course.artist.category.title }}
            </a>
            <h3><span v-if="schedule.course.isSoundCheck" class="soundcheck-title">Soundcheck</span> {{ schedule.course.title }}</h3>
            <p>{{ schedule.course.description }}</p>
            <p>
                <strong class="mr-4">{{ schedule.course.artist.firstName }} {{ schedule.course.artist.lastName }}</strong>
                <a v-if="!isRequesting" v-on:click="unSchedule"><small>Unschedule</small></a>
                <span v-if="isRequesting"><small>Unschedule</small></span>
            </p>
        </div>
        <div class="actions-wrapper">
            <p v-if="schedule.course.isFree" class="free-session">Free session!</p>
            <h4 class="mb-2">{{ schedule.course.schedule | moment('h:mm A') }}</h4>
            <p v-if="cta"><small>Session opens at {{ sessionOpenTime }}</small></p>
            <b-button v-if="cta && schedule.course.status === 'UPCOMING' || schedule.course.status === 'STARTED'"
                      :disabled="!isJoinSessionAvailable"
                      variant="blue"
                      size="lg"
                      class="semibold mt-3"
                      :class="{'green-room-btn': schedule.course.artistUserId === user.id}"
                      @click="joinMeeting">
                <span>{{ joinButtonCaption }}</span>
            </b-button>
        </div>
    </div>
</template>
<script>

    import {mapActions, mapGetters} from "vuex";
    import {api} from "../../../services/api";

    export default {
        name: 'Schedule',
        props: {
            schedule: {
                type: Object,
                required: true
            },
            cta: {
                type: Boolean,
                required: true
            },
            isToday: {
                type: Boolean,
                required: true
            },
            unScheduleCb: {
                type: Function,
                required: true
            },
        },
        data () {
            return {
                page: 0,
                lastIndex: null,
                infiniteId: +new Date(),
                schedules: [],
                untilStartTimeSec: null,
                isRequesting: false,
                isJoinSessionAvailable: false,
                interval: null,
            }
        },
        watch: {
            untilStartTimeSec: function (untilStartTimeSec) {
                let available;
                if (this.isGreenRoomAvailable) {
                    available = untilStartTimeSec <= 60 * 60
                } else {
                    available = untilStartTimeSec <= 5 * 60;
                }
                this.isJoinSessionAvailable = available;
                if (available && this.interval) {
                    clearInterval(this.interval);
                }
            },
        },
        computed: {
            ...mapGetters([
                'user'
            ]),
            isGreenRoomAvailable: function() {
                return this.user.id === this.schedule.course.artistUserId || (this.schedule.course.coHostsAllowed && ['INSTRUCTOR', 'ADMIN'].includes(this.user.role));
            },
            joinButtonCaption: function() {
                return this.user.id === this.schedule.course.artistUserId ? `Enter Green Room` : `Join Room`;
            },
            sessionOpenTime: function () {
                return this.$moment(this.schedule.course.schedule).add( -5 ,'minute').format('h:mm A')
            },
        },
        methods: {
            ...mapActions([
                'setArtist',
            ]),
            showArtistModal(artist) {
                this.setArtist(artist).then(() => {
                    this.$root.$emit('bv::show::modal', 'artist-modal')
                });
            },
            unSchedule() {
                this.isRequesting = true;
                api.schedules.delete(this.schedule.id).then(() => {
                    if (this.unScheduleCb) {
                        this.unScheduleCb(this.schedule);
                    }
                }).finally(() => {
                    this.isRequesting = false;
                })
            },
            joinMeeting() {
                const newWindow = window.open(`/session/${this.schedule.courseId}`);
                if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                    this.$notify({type: 'error', text: 'Please, enable blocked pop-up configuration for this website'});
                }
            },
            updateStartTime() {
                this.untilStartTimeSec = this.$moment(this.schedule.course.schedule).diff(this.$moment(), 'seconds');
            }
        },
        mounted() {
            this.updateStartTime();
            this.interval = setInterval(() => {
                if (this.isToday) {
                    this.updateStartTime();
                }
            }, 5000);
        },
        destroyed() {
            clearInterval(this.interval);
        },
    }
</script>
<style lang="scss" scoped>
  @import "~@/assets/styles/_color-variables.scss/";
  .free-session {
    color: $c-cyan;
    margin-bottom: 10px;
  }
</style>

