import {mapGetters} from "vuex";

export const permissions = {
    computed: {
        ...mapGetters([
            'isManagedSession',
            'canManageOnStage',
            'canBeArtist',
            'isHostHere',
            'canManageSessionFlow',
            'canFinishSession',
            'freeFromSubscription',
            'isGreenRoomAllowed',
        ])
    }
}
