export const Role = {
    ADMIN: 'ADMIN',
    INSTRUCTOR: 'INSTRUCTOR',
    LEARNER: 'LEARNER',
    MANAGER: 'MANAGER',
};

export const PlanName = {
    ANNUAL: 'annual',
    QUARTERLY: 'quarterly',
    MONTHLY: 'monthly',
};

export const SubscriptionName = {
    DEFAULT: 'default',
    PROMO: 'promo',
};

export const Permissions = {
    CAN_CREATE_COURSE: 'CAN_CREATE_COURSE',
    CAN_MANAGE_ON_STAGE: 'CAN_MANAGE_ON_STAGE',
    CAN_MANAGE_CO_HOST: 'CAN_MANAGE_CO_HOST',
    CAN_COMPLETE_SESSION: 'CAN_COMPLETE_SESSION',
    CAN_BE_CO_HOST: 'CAN_BE_CO_HOST',
    CAN_MUTE_ALL: 'CAN_MUTE_ALL',
    FREE_FROM_SUBSCRIPTION: 'FREE_FROM_SUBSCRIPTION',
    CAN_MANAGE_SESSION_FLOW: 'CAN_MANAGE_SESSION_FLOW',
    CAN_BE_ARTIST: 'CAN_BE_ARTIST',
    IS_GREEN_ROOM_ALLOWED: 'IS_GREEN_ROOM_ALLOWED',
};

export const ChannelEventType = {
    HAND_DOWN: 'HAND_DOWN',
    HAND_UP_IGNORED_CHANNEL: 'HAND_UP_IGNORED_CNANNEL',
    HAND_UP_ACCEPTED: 'HAND_UP_ACCEPTED',
    HAND_UP: 'HAND_UP',
    PING_MANAGERS: 'PING_MANAGERS',
    MAIN_VIEW_STREAMS: 'MAIN_VIEW_STREAMS',
    SESSION: 'SESSION',
    SESSION_ENDED: 'SESSION_ENDED',
    UNMUTE_ALL: 'UNMUTE_ALL',
    MUTE_ALL: 'MUTE_ALL',
    STOP_LEARNER_STREAM: 'STOP_LEARNER_STREAM',
    ROLLCALL: 'ROLLCALL',
    AUDIENCE: 'AUDIENCE',
    CHAT_MESSAGE: 'CHAT_MESSAGE',
    HAS_HAND_UP_CHANNEL: 'HAS_HAND_UP_CHANNEL',
};
