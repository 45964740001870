<template>
  <div class="session-view">
    <div v-if="!clickedToPlay" class="ready-to-play-wrapper">
      <div class="ready-to-play-content">
        <b-button
          variant="black"
          class="btn-play-session"
          @click="showStream"
        ></b-button>
        <p class="h3 bold">Session is Live</p>
        <p class="h4">Press play to start</p>
      </div>
    </div>
    <div
      class="session-view-wrapper"
      id="session-view-wrapper"
      :class="templateClass"
    >
      <div
        v-if="clickedToPlay && audioAutoplayFailed"
        id="autoplay-failed-wrapper"
        @click="resumeAutoPlayFailed"
      >
        <p class="h2">Click to play</p>
      </div>
      <div class="session-info-badge">
        <span v-if="session.status === 'STARTED' && session.durationMin"
          >{{ session.durationMin }} / {{ durationMin }} min.</span
        >
        {{ session.artist.firstName }} {{ session.artist.lastName }}
      </div>
      <div id="instructor-view"></div>
      <div class="learners-main-view-wrapper">
        <div
          class="learner-stream-main-view"
          v-for="stream in streamsMainView"
          :key="getStreamId(stream)"
        >
          <learner-stream-view :stream="stream" :isHostHere="isHostHere" />
        </div>
      </div>
      <div class="learners-stream-wrapper">
        <div
          class="learner-stream-view"
          v-for="stream in streamsSmallView"
          :key="getStreamId(stream)"
        >
          <learner-stream-view :stream="stream" :isHostHere="isHostHere" />
        </div>
      </div>
      <div
        v-if="fullScreenMode"
        class="fullscreen-exit-button"
        @click="fullScreenExit"
        title="Exit fullscreen mode"
      />
      <div
        v-if="!fullScreenMode"
        class="fullscreen-button"
        @click="fullScreen"
        title="Fullscreen mode"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LearnerStreamView from "./LearnerStreamView";
import { permissions } from "@/mixins/permissions";
import { slugifyUppercase } from "@/utils/slugifyUppercase";

export default {
  name: "StreamView",
  mixins: [permissions],
  data() {
    return {
      interval: null,
      durationMin: 0,
      clickedToPlay: false,
    };
  },
  computed: {
    ...mapGetters([
      "streams",
      "streamsOnMainView",
      "session",
      "fullScreenMode",
      "audioAutoplayFailed",
    ]),
    templateClass() {
      const type = this.$agora.getTemplateType();
      return `session-template-${type}`;
    },
    streamsSmallView() {
      return this.streams.filter(
        (s) => s.video && !this.streamsOnMainView.includes(s.getId())
      );
    },
    streamsMainView() {
      return this.streamsOnMainView
        .map((x) => this.streams.find((s) => s.getId() == x))
        .filter((s) => s && s.video);
    },
  },
  methods: {
    ...mapActions(["setFullscreenMode"]),
    getStreamId(stream) {
      return stream.getId();
    },
    fullScreenExit() {
      this.setFullscreenMode(false);
    },
    fullScreen() {
      this.setFullscreenMode(true);
    },
    updateDuration() {
      if (this.session && this.session.startedAt) {
        this.durationMin = this.$moment(this.$moment())
          .diff(this.session.startedAt, "minutes")
          .toString();
      } else {
        this.durationMin = 0;
      }
    },
    showStream() {
      this.$agora.playHostStreamForLearner();
      this.clickedToPlay = true;
      this.$analytics.dataLayerPush({
        event: "Live Session",
        category: "watchLive",
        action: `${this.session.schedule}-${slugifyUppercase(
          this.session.title
        )}`,
        label: this.session.artist
          ? `${this.session.artist.lastName}-${this.session.artist.firstName}`
          : ``,
      });
    },
    resumeAutoPlayFailed() {
      this.$agora.resumeAudioAutoplay();
    },
  },
  components: {
    LearnerStreamView,
  },
  mounted() {
    this.clickedToPlay = this.isGreenRoomAllowed;
    this.interval = setInterval(() => {
      this.updateDuration();
    }, 1000);
    this.$agora.on("host-video-added", () => {
      this.clickedToPlay = false;
    });
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    this.$agora.handleDestroy();
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
