<template>
  <div class="page subscription-success-page">
    <b-container>
      <section>
        <b-row class="mb-100">
          <b-col>
            <h1 class="text-stroke">You’re in!</h1>
          </b-col>
        </b-row>
      </section>
      <section>
        <b-row>
          <b-col>
            <div class="empty-state text-center">
              <div>
                <b-img class="mb-30" width="74px" src="/images/icons/check-bg.svg" alt="You're in"/>
              </div>
              <h2 class="mb-30">Let’s check out some upcoming sessions.</h2>
              <div>
                <b-button class="mb-3" size="lg" variant="blue" :to="{name: 'upcoming'}">Upcoming Sessions</b-button>
              </div>
              <div>
                <b-button size="sm" variant="black" :to="{name: 'account-subscription'}">View Account</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <modal :is-show="modalShow" />
  </div>
</template>

<script>
import {api, security} from "@/services";
import Modal from '@/components/shared/Common/Modal'

  export default {
    name: 'SubscriptionSuccess',
    data() {
      return {
        modalShow: false,
      }
    },
    methods: {
      async applyChanges(sessionId) {
        this.modalShow = true;
        await this.$router.replace({ query: {} });
        try {
          await api.accounts.createUserSources({ sessionId });
          await security.forceRequestUser();
        } catch (e) {
          return this.$router.replace({name: 'subscription-error'});
        }
        this.modalShow = false;
      }
    },
    async beforeRouteEnter(to, from, next) {
      next(async (vm) => {
        to.query.session_id && await vm.applyChanges(to.query.session_id);
      });
    },
    components: {
      Modal,
    }
  }
</script>
