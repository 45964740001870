<template>
  <div class="course-badge text-left" :class="{'soundcheck': course.isSoundCheck}">
    <div class="image-wrapper" v-bind:style="{backgroundImage: 'url(' + (course.imageUrl ? course.imageUrl : course.artist.imageUrl) + ')'}">
      <div class="chevron-down-btn" @click="showArtistModal(course.artist)"></div>
    </div>
    <div class="time-side">
      <div class="free-session">{{ course.isFree ? 'Free session!' : '' }}</div>
      <small>{{ course.schedule | moment('ddd MMM D') }}</small><br/>
      <small class="time">{{ course.schedule | moment('h:mm A') }}</small><br/>
      <div v-if="isUpcoming">
        <small class="countdown" v-bind:class="{ 'live-state': isLive }" v-html="this.countdown"></small><br/>
      </div>
      <p v-if="false && course.category" class="mt-2"><small>{{ course.category.title }}</small></p>
    </div>
    <div class="course-info">
      <div>
        <span v-if="course.isSoundCheck" class="soundcheck-title">Soundcheck</span>
        <vue-clamp :max-lines="2" tag="h4" class="semibold">{{ course.title }}</vue-clamp>
      </div>
      <vue-clamp :max-height="140" tag="p">{{ course.description }}</vue-clamp>
      <div class="badge-footer">
        <b-button v-if="!user" :to="{name: 'register'}" variant="blue" class="semibold">Sign Up To Watch</b-button>
        <div v-if="user">
          <div v-if="isUpcoming && !isJoinSessionAvailable">
            <b-button
                v-if="!course.isScheduled"
                variant="blue"
                class="semibold"
                @click="addToSchedule"
                :disabled="isRequesting"
            >
              Add to Schedule
            </b-button>
            <b-button v-if="course.isScheduled" disabled variant="blue" class="semibold">Scheduled</b-button>
          </div>
          <div v-if="isUpcoming && isJoinSessionAvailable">
            <b-button
                variant="cyan"
                class="semibold"
                @click="joinMeeting"
            >
              {{ isUserHostOfSession ? 'Enter Green Room' : 'Join Session'}}
            </b-button>
          </div>
          <div v-if="!isUpcoming">
            <b-button v-if="user" variant="blue" class="semibold" :disabled="isRequesting" @click="watchNow">Watch Now
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import {mapActions, mapGetters} from "vuex";
  import {api} from "@/services";
  import {slugifyUppercase} from "@/utils/slugifyUppercase";

  export default {
    name: 'Schedule',
    props: {
      course: {
        type: Object,
        required: true
      },
      isUpcoming: {
        type: Boolean
      },
    },
    data() {
      return {
        isRequesting: false,
        untilStartTimeSec: null,
        isJoinSessionAvailable: false,
        isLive: false,
        countdown: null,
        interval: null,
      }
    },
    watch: {
      untilStartTimeSec: function (untilStartTimeSec) {
        this.isJoinSessionAvailable = untilStartTimeSec <= 5 * 60;
        const stopInterval = untilStartTimeSec <= 0;
        if (stopInterval) {
          this.countdown = `<span>•</span> LIVE`;
          this.isLive = true;
        } else if (untilStartTimeSec <= 24 * 60 * 60 && untilStartTimeSec > 60) {
          const h = Math.floor(untilStartTimeSec / (60 * 60));
          const m = Math.floor((untilStartTimeSec - h * (60 * 60)) / 60);
          this.countdown = `Starts in ${h}:${m.toString().padStart(2, '0')}`;
        }
        if (stopInterval && this.interval) {
          clearInterval(this.interval);
        }
      },
    },
    computed: {
      ...mapGetters({
        user: "user",
      }),
      isUserHostOfSession() {
        return this.user && this.course && this.course.artistUserId === this.user.id;
      }
    },
    methods: {
      ...mapActions([
        'setArtist',
        'setRecording',
        'setSessionSharing'
      ]),
      showArtistModal(artist) {
        this.setArtist(artist).then(() => {
          this.$root.$emit('bv::show::modal', 'artist-modal')
        });
      },
      addToSchedule() {
        this.isRequesting = true;
        api.schedules.create({
          courseId: this.course.id,
        }).then(() => {
          this.course.isScheduled = true;
          this.showSessionSharingModal(this.course);
          this.$analytics.dataLayerPush({
            event: 'Add To Schedule',
            category: 'schedule',
            action: `${this.course.schedule}-${slugifyUppercase(this.course.title)}`,
            label: this.course.artist ? `${this.course.artist.lastName}-${this.course.artist.firstName}` : ``,
          });
        }).finally(() => {
          this.isRequesting = false;
        })
      },
      showSessionSharingModal(course) {
        this.setSessionSharing(course).then(() => {
          this.$root.$emit('bv::show::modal', 'session-sharing-modal')
        });
      },
      watchNow() {
        this.isRequesting = true;
        api.courses.watch(this.course.id).then(({data}) => {
          this.setRecording(data);
          this.$root.$emit('bv::show::modal', 'session-player-modal');
          this.$analytics.dataLayerPush({
            event: 'Watch Now',
            category: 'catchUp',
            action: `${this.course.schedule}-${slugifyUppercase(this.course.title)}`,
            label: this.course.artist ? `${this.course.artist.lastName}-${this.course.artist.firstName}` : ``,
          });
        }).finally(() => {
          this.isRequesting = false;
        })
      },
      updateStartTime() {
        this.untilStartTimeSec = this.$moment(this.course.schedule).diff(this.$moment(), 'seconds');
      },
      joinMeeting() {
        const newWindow = window.open(`/session/${this.course.id}`);
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
          this.$notify({type: 'error', text: 'Please, enable blocked pop-up configuration for this website'});
        }
      },
    },
    mounted() {
      if (this.isUpcoming) {
        this.updateStartTime();
        this.interval = setInterval(() => {
          this.updateStartTime();
        }, 5000);
      }
    },
  }
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/_color-variables.scss/";
.free-session {
  color: $c-cyan;
  height: 24px;
}
</style>
