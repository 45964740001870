import api from '../../configuration/api'

export const accounts = {
  getPromoCodeInfo: (promotionCode) => api.get(`promotion-codes/${promotionCode}`),
  updateBilling: (data) => api.post('accounts/billing', data),
  subscribe: (data) => api.post('accounts/subscribe', data),
  cancelSubscription: (data) => api.delete('accounts/subscription', { data }),
  subscribePromo: (data) => api.post('accounts/subscribe/promo', { promotionCode: data }),
  createCheckoutSession: (data) => api.post('accounts/checkout-session', data),
  getBilling: () => api.get('accounts/billing'),
  changePaymentMethod: () => api.get('accounts/payment-method'),
  createUserSources: (data) => api.post('accounts/user-sources', data),
  applyPaymentMethod: (data) => api.patch('accounts/payment-method', data),
};
