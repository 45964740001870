export const bag = {
    getSessionProfile() {
        return this.getItemJson('session-profile');
    },
    setSessionProfile(settings) {
        this.setItemJson('session-profile', settings);
    },
    removeSessionProfile() {
        localStorage.removeItem('session-profile');
    },
    getDevicesProfile() {
        return this.getItemJson('devices-profile');
    },
    setDevicesProfile(profile) {
        this.setItemJson('devices-profile', profile);
    },
    removeDevicesProfile() {
        localStorage.removeItem('devices-profile');
    },
    getItem(key) {
        const value = localStorage.getItem(key);
        if (!value) {
            return null;
        }
        return value;
    },
    setItem(key, value) {
        localStorage.setItem(key, value);
    },
    removeItem(key) {
        localStorage.removeItem(key);
    },
    setItemJson(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    getItemJson(key) {
        const data = localStorage.getItem(key);
        if (!data) {
            return null;
        }
        let json = null;
        try {
            json = JSON.parse(data);
        } catch (e) {
            localStorage.removeItem(key);
            return null;
        }
        return json;
    }
};
