import * as types from '../types'

const state = {
  artist: null,
};

const getters = {
  artist: state => state.artist,
};

const mutations = {
  [types.SET_ARTIST] (state, artist) {
    state.artist = artist;
  },
};

const actions = {
  setArtist: ({commit}, artist) => {
    commit(types.SET_ARTIST, artist);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}
