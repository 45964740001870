<template>
    <b-modal id="session-player-modal"
             @shown="onModalShown"
             @hide="onModalHide"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
             size="custom"
             :modal-class="{'fullscreen': fullScreenMode, 'expanded': chatDisplayed}"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div class="modal-body-wrapper">
            <b-button v-show="!started" variant="round" class="btn-blue icon-play" @click="playRecording"></b-button>
            <div v-show="!started" class="disabled-wrapper"></div>
            <div class="recording-player-wrapper">
                <div class="video-wrapper">
                    <video
                        v-show="recording && showPlayer"
                        id="recording-player"
                        class="recording-player"
                        ref="videoPlayer"
                        :poster="recording ? recording.posterUrl : ''"
                    ></video>
                    <div v-if="started" class="video-progress-wrapper" :style="{bottom: progressBottom}" :class="{'long-duration': longDuration}">
                        <div class="progress-control">
                            <b-button v-if="!isPlaying" variant="transparent" @click="togglePlaying" class="btn btn-play"/>
                            <b-button v-if="isPlaying" variant="transparent" @click="togglePlaying" class="btn btn-pause"/>
                            <div class="video-control-wrapper">
                                <input type="range"
                                       class="video-progress"
                                       min="0"
                                       :max="player.duration"
                                       :value="currentTime"
                                       step="0.0001"
                                       @mousedown="progressMouseDown"
                                       @mouseup="progressMouseUp"
                                       @change="progressChanged"
                                       @input="progressChanged"/>
                            </div>
                            <div class="time-wrapper">
                                {{ currentTimeFormat }} / {{ durationFormat }}
                            </div>
                            <div class="sound-wrapper" id="volume-wrapper-el">
                                <div class="volume-wrapper">
                                    <input
                                        type="range"
                                        class="volume-slider"
                                        max="100"
                                        :value="volumeLevel"
                                        @change="volumeChanged"
                                        @input="volumeChanged"
                                    />
                                </div>
                                <b-button v-if="!muted" @click="toggleMuted" variant="transparent" class="btn btn-sound"/>
                                <b-button v-if="muted" @click="toggleMuted" variant="transparent" class="btn btn-sound-muted"/>
                            </div>
                            <b-button v-if="!fullScreenMode" variant="transparent" @click="fullScreenEnter" class="btn btn-fullscreen-enter"/>
                            <b-button v-if="fullScreenMode" variant="transparent" @click="fullScreenExit" class="btn btn-fullscreen-exit"/>
                        </div>
                        <b-button v-show="started && chatAvailable" variant="gray" class="toggle-chat-btn" @click="switchChat">{{ chatButtonMessage }}</b-button>
                    </div>
                </div>
                <div class="session-chat" v-if="chatDisplayed" :style="{height: chatHeight}">
                    <div class="messages-wrapper" ref="messagesContainer">
                        <ul v-if="chat && chat.length" class="list-unstyled chat-messages">
                            <li v-for="item in chat" :key="item.utc + item.nid">
                                <div class="name-badge" :class="user.nid === item.nid ? 'my' : (item.nid === recording.course.artistUserNid ? 'artist' : '')">{{ item.fullName | nameToChars }}</div>
                                <div class="message">
                                    <p class="name">
                                        <span class="username">{{ item.fullName }}</span>
                                        <span v-if="item.nid === recording.course.artistUserNid" class="artist-badge">Artist</span>
                                    </p>
                                    <p class="text">{{ item.message }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import { mapGetters } from "vuex";
import Hls from "hls.js";
import moment from 'moment';

export default {
    name: 'SessionPlayerModal',
    data() {
        return {
            showPlayer: false,
            shown: false,
            readyState: false,
            started: false,
            chatTime: 0,
            currentTime: 0,
            playerTime: 0,
            videoRatio: 0,
            chatDisplayed: false,
            fullScreenMode: false,
            isPlaying: false,
            muted: false,
            volumeLevel: 100,
            volumeLevelBeforeMute: 100,
            chatHeight: 'unset',
            progressBottom: `20px`,
            isMousePlaying: false,
            prevState: {
                title: null
            },
            longDuration: false
        }
    },
    computed: {
        ...mapGetters([
            'user',
            'recording'
        ]),
        player() {
            if (this.readyState) {
                return this.$refs.videoPlayer;
            }
            return {
                currentTime: 0,
                duration: 0.1,
                readyState: 0,
                paused: true,
                ended: false,
            };
        },
        durationFormat() {
            if (isNaN(this.player.duration)) {
                return '0:00';
            }
            if (this.player.duration >= 60 * 60) {
                return moment.utc(moment.duration(this.player.duration, 'seconds').asMilliseconds()).format('H:mm:ss');
            }
            return moment.utc(moment.duration(this.player.duration, 'seconds').asMilliseconds()).format('m:ss');
        },
        currentTimeFormat() {
            if (this.player.duration >= 60 * 60) {
                return moment.utc(moment.duration(this.playerTime, 'seconds').asMilliseconds()).format('H:mm:ss');
            }
            return moment.utc(moment.duration(this.playerTime, 'seconds').asMilliseconds()).format('m:ss');
        },
        currentChatTime() {
            if (!this.chatAvailable) {
                return 0;
            }
            return +this.recording.course.startedTs + Math.round(this.chatTime * 1000);
        },
        chatAvailable() {
            return this.recording && this.recording.chat && this.recording.chat.length;
        },
        chat() {
            if (!this.chatAvailable) {
                return [];
            }
            return this.recording.chat.filter(x => x.utc <= this.currentChatTime)
        },
        chatButtonMessage() {
            return this.chatDisplayed ? `Hide chat` : `Show chat`;
        },
    },
    watch: {
        chat() {
            this.$nextTick(() => this.scrollToEnd());
        },
    },
    methods: {
        closeModal() {
            this.$root.$emit('bv::hide::modal', 'session-player-modal');
        },
        onModalHide() {
            this.shown = false;
            this.showPlayer = false;
            this.readyState = 0;
            this.fullScreenMode = false;
            this.chatDisplayed = false;
            window.history.replaceState({}, this.prevState.title, '/catch-up');
            this.$refs.videoPlayer.removeEventListener('timeupdate', this.timeUpdated);
            const self = this;
            this.$refs.videoPlayer.removeEventListener('loadedmetadata', function() {self.getAspectRatio(this)});
            window.removeEventListener('resize', this.calcResize);
            window.removeEventListener('mouseover', () => {});
        },
        onModalShown() {
            this.chatTime = 0;
            this.prevState.title = document.title;
            if (!this.recording) {
                this.closeModal();
            }
            setTimeout(() => {
                this.shown = true;
            }, 400)
            this.setupRecording();
        },
        fullScreenEnter() {
            this.fullScreenMode = true;
            this.calcResize();
        },
        fullScreenExit() {
            this.fullScreenMode = false;
            this.calcResize();
        },
        scrollToEnd() {
            const content = this.$refs.messagesContainer;
            if (content) {
                content.scrollTop = content.scrollHeight;
            }
        },
        playRecording() {
            this.blockDownload();
            this.started = true;
            this.isPlaying = true;
            const video = document.getElementById('recording-player');
            video.pause();
            setTimeout(() => {
                const el = document.getElementById('volume-wrapper-el');
                const ev = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel";
                window.addEventListener('mouseover', () => {
                    if (el) el.addEventListener(ev, this.moveVolumeSlider);
                });
            }, 1000);
            setTimeout(() => {
                video.play();
            }, 400);
        },
        progressMouseDown() {
            this.isMousePlaying = this.isPlaying;
            const video = document.getElementById('recording-player');
            video.pause();
        },
        progressMouseUp() {
            if (this.isMousePlaying) {
                const video = document.getElementById('recording-player');
                video.play()
            }
        },
        blockDownload() {
            document.getElementById('recording-player').addEventListener('contextmenu',(e) => {e.preventDefault(); return false;});
        },
        switchChat() {
            this.chatDisplayed = !this.chatDisplayed;
            this.calcResize();
        },
        setupRecording() {
            setTimeout(() => {
                window.history.replaceState({}, "2GTHR | " + this.recording.course.name, "/catch-up/" + this.recording.course.id);
            }, 400)
            this.started = false;
            this.isPlaying = false;
            this.readyState = 0;
            const video = document.getElementById('recording-player');
            if (!video) {
                return;
            }
            video.html = '';
            const videoSrc = this.recording.items[0];
            if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.pause();
                video.src = videoSrc;
                video.addEventListener('loadedmetadata', () => {
                    this.readyState = 1;
                });
            } else if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(videoSrc);
                video.pause();
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.readyState = 1;
                });
            }
            if (video) {
                video.addEventListener('timeupdate', this.timeUpdated);
                const self = this;
                video.addEventListener('loadedmetadata', function () {
                    self.getAspectRatio(this);
                    self.readyState = 2;
                });
            }
            window.addEventListener('resize', this.calcResize);
            this.showPlayer = true;
            this.longDuration = moment(this.recording.course.endedAt).diff(moment(this.recording.course.startedAt), 'minutes') >= 60;
        },
        calcResize() {
            const w = window.innerWidth;
            const h = window.innerHeight;
            const x = (w * 0.8 / this.videoRatio);
            if (this.chatDisplayed && this.fullScreenMode && this.videoRatio) {
                this.chatHeight = `${Math.round(x)}px`
                this.progressBottom = `${Math.round(h/2 - x/2) + 10}px`
            } else {
                this.chatHeight = w < 1200 ? '536px' : 'unset';
                this.progressBottom = `20px`
            }
            if (w < 1200) {
                this.chatTop = 0;
            }
        },
        getAspectRatio(el) {
            this.videoRatio = el.videoWidth / el.videoHeight;
        },
        timeUpdated(ev) {
            this.chatTime = this.$refs.videoPlayer.currentTime;
            this.updatePlayerTime(ev);
        },
        updatePlayerTime(ev) {
            const v = ev.target;
            document.documentElement.style.setProperty('--videoProgress', `${(v.currentTime / v.duration) * 100}%`);
            this.playerTime = v.currentTime;
        },
        progressChanged(ev) {
            this.player.currentTime = ev.target.value;
            document.documentElement.style.setProperty('--videoProgress', `${(this.player.currentTime / this.player.duration) * 100}%`);
        },
        togglePlaying() {
            const video = document.getElementById('recording-player');
            this.isPlaying ? video.pause() : video.play();
            this.isPlaying = !this.isPlaying;
        },
        volumeChanged(ev) {
            const video = document.getElementById('recording-player');
            if (!video) return;
            this.volumeLevel = +ev.target.value;
            video.volume = this.volumeLevel / 100;
            if (!this.volumeLevel) {
                this.muted = true;
                video.muted = this.muted;
            } else {
                this.muted = false;
                video.muted = this.muted;
            }
            document.documentElement.style.setProperty('--audioVolume', `${this.volumeLevel}%`);
        },
        toggleMuted() {
            const video = document.getElementById('recording-player');
            if (!video) return;
            this.muted = !this.muted;
            if (this.muted) {
                this.volumeLevelBeforeMute = +this.volumeLevel;
                this.volumeLevel = 0;
            } else {
                this.volumeLevel = this.volumeLevelBeforeMute;
            }
            this.volumeChanged({
                target: {
                    value: +this.volumeLevel
                }
            });
            video.muted = this.muted;
        },
        moveVolumeSlider(e) {
            const video = document.getElementById('recording-player');
            if (e.wheelDelta < 0 ) {
                this.volumeLevel = (this.volumeLevel >= 10) ? this.volumeLevel - 10 : 0;
                if (!this.volumeLevel) {
                    this.muted = true;
                    video.muted = this.muted;
                }
            } else {
                this.volumeLevel = (this.volumeLevel <= 90) ? this.volumeLevel + 10 : 100;
                if (this.muted) {
                    this.muted = false
                    video.muted = this.muted;
                }
            }
            this.volumeChanged({
                target: {
                    value: this.volumeLevel
                }
            });
            return false;
        },
    },
}
</script>
<style>
:root {
    --videoProgress: 1%;
    --audioVolume: 100%;
}
</style>