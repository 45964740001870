import store from "../store";
import User from "../utils/map/User";
import {api} from '@/services'
import firebase from 'firebase/app';
import 'firebase/auth';

export const security = {
  initUrl: false,
  initialized: false,
  init() {
    if (this.initialized) {
      return;
    }
    if (window.location.pathname !== '/') {
      this.setInitUri(window.location.pathname);
    }
    this.initialized = true;
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          this.forceRequestUser().then(() => {
            resolve();
          });
        } else {
          this.setUserResponse(null);
          resolve();
        }
      });
    })
  },
  getInitUri() {
    return this.initUri;
  },
  setInitUri(uri) {
    return this.initUri = uri;
  },
  updateUser(userData) {
    if (userData) {
      const user = User(userData);
      store.dispatch('setAuthUser', user);
    } else {
      this.signOut()
    }
  },
  async getApiToken() {
    if (!firebase.auth().currentUser) {
      return null;
    }
    return await firebase.auth().currentUser.getIdToken();
  },
  setUserResponse(data) {
    if (data) {
      const user = data.user ? User(data.user) : null;
      store.dispatch('setAuthUser', user);
      store.dispatch('setAuthBilling', user ? data.billing : null);
      store.dispatch('setAuthSubscriptions', user ? data.subscriptions : []);
      store.dispatch('setAuthTrialStarted', user ? data.trialStarted : false);
      store.dispatch('setInternalPromotionCodeInfo', user ? data.internalPromotionCodeInfo : null);
      store.dispatch('setPermissions', data?.permissions ?? []);
      return user;
    } else {
      this.signOut();
    }
    return null;
  },
  forceRequestUser() {
    return api.auth.me().then(({data}) => {
      this.setUserResponse(data);
    })
  },
  clearUser() {
    store.dispatch('setAuthUser', null);
    store.dispatch('setAuthBilling', null);
    store.dispatch('setAuthSubscriptions', []);
    store.dispatch('setAuthTrialStarted', false);
    store.dispatch('setInternalPromotionCodeInfo', null);
    store.dispatch('setPermissions', []);
  },
  signOut() {
    firebase.auth().signOut().then(() => {
      this.clearUser();
      console.log(`Logout`);
    });
  }
};
