<template>
  <b-modal
    v-model="isShow"
    centered
    hide-footer
    hide-header-close
    hide-header
    no-close-on-backdrop
  >
    <div class="d-block text-center p-2">
      <h3 class="mb-20">Some time is needed until we get response from stripe...</h3>
      <b-spinner></b-spinner>
    </div>
  </b-modal>
</template>
<script>
  export default {
    name: 'Modal',
    props: {
      isShow: {
        type: Boolean,
        required: false,
      },
    }
  }
</script>
