import * as types from '../types'

const state = {
  alerts: [],
};

const getters = {
  alerts: state => state.alerts,
  alertState: state => name => state.alerts.find(x => x.name === name)
};

const mutations = {
  [types.SET_ALERT] (state, name) {
    state.alerts.push({
      name: name,
      value: '1'
    })
  },
};

const actions = {
  setAlert: ({commit}, name) => {
    commit(types.SET_ALERT, name);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}
