import store from "../store";

let initialized = false;

export const browserStorage = {
  init() {
    if (initialized) {
      return;
    }
    for (let i = 0; i < localStorage.length; i++){
      const key = localStorage.key(i);
      if (/^alert-/.test(key)) {
        this.addItem(key);
      }
    }
    initialized = true;
  },
  addItem(name) {
    localStorage.setItem(name, '1');
    store.dispatch('setAlert', name);
  },
  hasItem(name) {
    return !localStorage.getItem(name);
  }
};
