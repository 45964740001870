<template>
  <div class="page">
    <section>
      <b-container>
        <b-row>
          <b-col>
            <h1 class="text-stroke mb-80">Blog</h1>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col v-for="post in posts" :key="post.id" lg="4" cols="12" class="mt-30">
            <blog-card :title="post.title" :thumb-url="post.thumbUrl" :post-id="post.id" />
          </b-col>
        </b-row>
      </b-container>
      <infinite-loading @infinite="fetchPosts">
        <div slot="spinner">Loading</div>
        <div slot="no-more" class="hide"></div>
        <div slot="no-results">
          <div class="empty-state empty-list text-center">
            <h3>There are no posts yet.</h3>
          </div>
        </div>
      </infinite-loading>
    </section>
  </div>
</template>

<script>
import BlogCard from "@/components/shared/Blog/BlogCard";
import {api} from "@/services";
import {defaultPostsCount} from "@/shared/constants";

export default {
  name: 'Blog',
  data() {
    return {
      pageIndex: 1,
      posts: [],
    }
  },
  methods: {
    async fetchPosts($state) {
      const {data} = await api.posts.list({
        params: {
          pageIndex: this.pageIndex,
          limit: defaultPostsCount,
        },
      });
      if (data.length) {
        this.pageIndex += 1;
        this.posts = this.posts.concat(data);
        $state.loaded();
      } else {
        $state.complete();
      }

      if (data.length < defaultPostsCount) $state.complete();
    }
  },
  components: {BlogCard},
}
</script>
