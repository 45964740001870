import * as types from '../types'

const state = {
  recording: null,
  sessionSharing: null
};

const getters = {
  recording: state => state.recording,
  sessionSharing: state => state.sessionSharing,
};

const mutations = {
  [types.SET_RECORDING] (state, recording) {
    state.recording = recording;
  },
  [types.SET_SESSION_SHARING] (state, course) {
    state.sessionSharing = course;
  },
};

const actions = {
  setRecording: ({commit}, recording) => {
    commit(types.SET_RECORDING, recording);
  },
  setSessionSharing: ({commit}, course) => {
    commit(types.SET_SESSION_SHARING, course);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}
