<template>
  <div class="page access-denied-page">
    <b-container>
      <section>
        <b-row class="mb-100">
          <b-col>
            <h1 class="text-stroke">Oops</h1>
          </b-col>
        </b-row>
      </section>
      <section>
        <b-row>
          <b-col>
            <div class="empty-state text-center">
              <div>
                <b-img class="mb-2" width="74px" src="/images/icons/x.svg" alt="Access denied"/>
              </div>
              <h3 class="mb-4 bold">Something is wrong</h3>
              <p class="h4">Reach out to us <a href="mailto:info@2gthr.co">info@2gthr.co</a></p>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CheckoutError',
}
</script>
