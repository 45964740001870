<template>
  <div class="page password-forgot-page">
    <div class="text-center mt-120 mb-120">
      <section v-if="sent">
        <b-container>
          <b-row>
            <b-col>
              <div class="form-wrapper mb-30">
                <h4 class="mt-80 mb-80">Please, check your email {{ email }} to reset password</h4>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <validation-observer ref="validationObserver" v-if="!sent">
        <form @submit.prevent="submitForm" novalidate>
          <section>
            <b-container>
              <b-row>
                <b-col>
                  <div class="form-wrapper mb-30">
                    <h4 class="mb-30">Enter your email address to reset password</h4>
                    <b-row>
                      <b-col cols="10" offset="1" lg="6" offset-lg="3">
                        <validation-provider rules="required|email" v-slot="{classes, errors }" mode="eager" name="Email Address">
                          <b-input
                                  v-model="email"
                                  id="billing-form-input-email"
                                  class="mb-4 mr-sm-2"
                                  :class="classes"
                          ></b-input>
                          <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-button size="lg" variant="blue" type="submit" class="mt-3 semibold" :disabled="isRequesting">Reset</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </section>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>

  import {api} from '@/services'

  export default {
    name: 'ForgotPassword',
    data() {
      return {
        sent: false,
        isRequesting: false,
        email: null
      }
    },
    methods: {
      async submitForm() {
        const isValid = await this.$refs.validationObserver.validate();
        if (!isValid) {
          this.cardError = `Please, check input fields`;
          return;
        }
        this.isRequesting = true;
        api.auth.passwordForgot({
          email:this.email
        }).then(() => {
          this.sent = true;
        }).finally(() => {
          this.isRequesting = false;
        });
      }
    }
  }
</script>
