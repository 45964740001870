<template>
  <div class="page settings-page">
    <validation-observer ref="validationEmailObserver" >
      <form @submit.prevent="submitEmail" novalidate>
        <section>
          <b-container>
            <b-row>
              <b-col>
                <div class="form-wrapper mb-30">
                  <b-row>
                    <b-col>
                      <h3 class="mb-30"><strong>Update Your Email Address</strong></h3>
                    </b-col>
                    <b-col>
                      <p v-if="emailUpdated" class="mb-30 text-right f-17">Email Updated <img class="inline-check" src="/images/icons/check-bg.svg"/></p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider rules="required|email" v-slot="{classes, errors}" mode="passive" name="Current Email">
                        <label for="form-input-current-email">Current Email Address</label>
                        <b-input
                                v-model="emailCurrent"
                                id="form-input-current-email"
                                class="mb-4 mr-sm-2"
                                :class="classes"
                        ></b-input>
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider rules="required|email" v-slot="{classes, errors}" mode="passive" name="New Email" vid="newEmail">
                        <label for="form-input-new-email">New Email Address</label>
                        <b-input
                                v-model="emailNew"
                                id="form-input-new-email"
                                class="mb-4 mr-sm-2"
                                :class="classes"
                        ></b-input>
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider rules="required|email|confirmed:newEmail" v-slot="{classes, errors}" mode="passive" name="New Email Confirmation">
                        <label for="form-input-new-email-confirmation">Confirm New Email Address</label>
                        <b-input
                                v-model="emailNewConfirmation"
                                id="form-input-new-email-confirmation"
                                class="mb-4 mr-sm-2"
                                :class="classes"
                        ></b-input>
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <p v-show="emailError" class="mt-2 error-text">{{ emailError }}</p>
                  <b-button size="lg" variant="blue" type="submit" class="mt-3 semibold" :disabled="apiRequesting">Submit</b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </form>
    </validation-observer>
    <validation-observer ref="validationPasswordObserver" >
      <form @submit.prevent="submitPassword" novalidate>
        <section>
          <b-container>
            <b-row>
              <b-col>
                <div class="form-wrapper mb-30">
                  <b-row>
                    <b-col>
                      <h3 class="mb-30"><strong>Update Your Password</strong></h3>
                    </b-col>
                    <b-col>
                      <p v-if="passwordUpdated" class="mb-30 text-right f-17">Password Updated <img class="inline-check" src="/images/icons/check-bg.svg"/></p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider rules="required" v-slot="{classes, errors}" mode="passive" name="Current Password">
                        <label for="form-input-current-password">Current Password</label>
                        <b-input
                                v-model="passwordCurrent"
                                id="form-input-current-password"
                                class="mb-4 mr-sm-2"
                                type="password"
                                :class="classes"
                        ></b-input>
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider rules="required" v-slot="{classes, errors}" mode="passive" name="New Password" vid="newPassword">
                        <label for="form-input-new-password">New Password</label>
                        <b-input
                                v-model="passwordNew"
                                id="form-input-new-password"
                                class="mb-4 mr-sm-2"
                                type="password"
                                :class="classes"
                        ></b-input>
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider rules="required|confirmed:newPassword" v-slot="{classes, errors}" mode="passive" name="New Password Confirmation">
                        <label for="form-input-new-password-confirmation">Confirm New Password</label>
                        <b-input
                                v-model="passwordNewConfirmation"
                                id="form-input-new-password-confirmation"
                                class="mb-4 mr-sm-2"
                                type="password"
                                :class="classes"
                        ></b-input>
                        <p class="error-msg" :state="errors.length == 0">{{ errors[0] }}</p>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <p v-show="passwordError" class="mt-2 error-text">{{ passwordError }}</p>
                  <b-button size="lg" variant="blue" type="submit" class="mt-3 semibold" :disabled="apiRequesting">Submit</b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </form>
    </validation-observer>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import {api} from '@/services'
  import {security} from "../../../services";

  export default {
    name: 'AccountSettings',
    data () {
      return {
        emailError: null,
        emailCurrent: null,
        emailNew: null,
        emailNewConfirmation: null,
        emailUpdated: false,
        passwordError: null,
        passwordCurrent: null,
        passwordNew: null,
        passwordNewConfirmation: null,
        passwordUpdated: false,
      }
    },
    computed: {
      ...mapGetters([
        'apiRequesting',
        'user',
      ]),
    },
    methods: {
      setInitValues() {
        this.emailCurrent = this.user.email;
      },
      async submitEmail() {
        this.emailError = null;
        const isValid = await this.$refs.validationEmailObserver.validate();
        if (!isValid) {
          this.emailError = `Please, check input fields`;
          return;
        }
        if (this.emailCurrent.toString().toLowerCase() !== this.user.email.toString().toLowerCase()) {
          return this.$notify({type: 'error', text: `Current email doesn't match`});
        }
        api.auth.updateUser({email: this.emailNew}).then(({data}) => {
          this.emailUpdated = true;
          security.updateUser(data?.user);
          this.emailNew = null;
          this.emailNewConfirmation = null;
          this.emailCurrent = data.user.email;
          this.$nextTick(() => {
            this.$refs.validationEmailObserver.reset();
          });
        })
      },
      async submitPassword() {
        this.passwordError = null;
        const isValid = await this.$refs.validationPasswordObserver.validate();
        if (!isValid) {
          this.passwordError = `Please, check input fields`;
          return;
        }
        api.auth.updateUser({currentPassword: this.passwordCurrent, password: this.passwordNew}).then(({data}) => {
          this.passwordUpdated = true;
          security.updateUser(data?.user);
          this.passwordNew = null;
          this.passwordNewConfirmation = null;
          this.passwordCurrent = null;
          this.$nextTick(() => {
            this.$refs.validationPasswordObserver.reset();
          });
        })
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.setInitValues();
      });
    },
  }
</script>
