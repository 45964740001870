import * as types from '../types'
import {api} from "@/services";

const state = {
  apiRequesting: false,
  categories: false,
  featuredArtists: false,
  featuredArticles: false,
};

const getters = {
  apiRequesting: state => state.apiRequesting,
  categories: state => state.categories,
  featuredArtists: state => state.featuredArtists,
  featuredArticles: state => state.featuredArticles,
};

const mutations = {
  [types.API_REQUESTING] (state, value) {
    state.apiRequesting = value;
  },
  [types.SET_CATEGORIES] (state, value) {
    state.categories = value;
  },
  [types.SET_FEATURED_ARTIST] (state, value) {
    state.featuredArtists = value;
  },
  [types.SET_FEATURED_ARTICLES] (state, value) {
    state.featuredArticles = value;
  },
};

const actions = {
  setApiRequesting: ({commit}, value) => {
    commit(types.API_REQUESTING, value);
  },
  initCategories: ({commit}) => {
    if (state.categories) {
      return;
    }
    api.categories.list().then(({data}) => {
      commit(types.SET_CATEGORIES, data.items);
    })
  },
  initFeaturedArtists: ({commit}) => {
    if (state.featuredArtists) {
      return state.featuredArtists;
    }
    return api.settings.getOne('featured-artists').then(({data}) => {
      commit(types.SET_FEATURED_ARTIST, data.item.data);
      return data.item.data;
    })
  },
  setFeaturedArtists: ({commit}, value) => {
    commit(types.SET_FEATURED_ARTIST, value);
  },
  initFeaturedArticles: ({commit}) => {
    if (state.featuredArticles) {
      return state.featuredArticles;
    }
    return api.articles.list().then(({data}) => {
      commit(types.SET_FEATURED_ARTICLES, data.items);
    })
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}
