<template>
  <div>
    <div class="global-message" v-on:click="showPopup">
      A message to the 2GTHR community >
    </div>
    <global-message-modal></global-message-modal>
  </div>
</template>

<script>

import GlobalMessageModal from "../Modals/GlobalMessage";

export default {
  name: "GlobalMessage",
  components: {GlobalMessageModal},
  methods: {
    showPopup() {
      this.$root.$emit('bv::show::modal', 'global-message-modal')
    }
  }
}
</script>
