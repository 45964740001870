<template>
    <b-modal id="session-tips-modal"
             @hide="onModalHide"
             @shown="shownModal"
             :hide-header="true"
             :hide-footer="true"
             :hide-header-close="true"
             :centered="true"
    >
        <div class="close-modal-btn" :class="{'d-none': !shown}" @click="closeModal"></div>
        <div class="modal-body-wrapper">
            <b-row>
                <b-col>
                    <p class="h2 mt-40">Session Tips</p>
                </b-col>
            </b-row>
            <div class="body-content">
                <b-row class="paragraph">
                    <b-col sm="2" cols="12" class="number-col">
                        <div class="number-wrapper">
                            <div class="number-badge">1</div>
                        </div>
                    </b-col>
                    <b-col sm="8" cols="12">
                        <p class="h3">2GTHR recommends Google Chrome for the best experience.</p>
                        <b-button variant="blue" size="sm" class="semibold mb-2" href="https://www.google.com/chrome/" target="_blank">Download Chrome</b-button>
                    </b-col>
                    <b-col sm="2" cols="12" class="text-right">
                        <img class="img-p-1" src="/images/modals/session-tips/google-icon.png">
                    </b-col>
                </b-row>
                <b-row class="paragraph">
                    <b-col sm="2" cols="12" class="number-col">
                        <div class="number-wrapper">
                            <div class="number-badge">2</div>
                        </div>
                    </b-col>
                    <b-col sm="5" cols="12">
                        <p class="h3">Check to make sure Chrome has permission to access your camera and microphone.</p>
                        <p class="note">Click on the lock or “i” icon.</p>
                    </b-col>
                    <b-col sm="3" cols="12" class="text-right">
                        <img class="img-p-2" src="/images/modals/session-tips/ui-2-2.png">
                    </b-col>
                </b-row>
                <b-row class="paragraph">
                    <b-col sm="2" cols="12" class="number-col">
                        <div class="number-wrapper">
                            <div class="number-badge">3</div>
                        </div>
                    </b-col>
                    <b-col sm="5" cols="12">
                        <p class="h3">Make sure Camera and Microphone are set to “Allow”.</p>
                        <p class="note">After changing these settings, you’ll need to reload this page.</p>
                    </b-col>
                    <b-col sm="3" cols="12" class="text-right">
                        <img class="img-p-2" src="/images/modals/session-tips/ui-3.png">
                    </b-col>
                </b-row>
                <b-row class="paragraph">
                    <b-col sm="2" cols="12" class="number-col">
                        <div class="number-wrapper">
                            <div class="number-badge">4</div>
                        </div>
                    </b-col>
                    <b-col sm="5" cols="12">
                        <p class="h3">Check that your Mic and Camera are On.</p>
                        <p class="note">If you see this, you’re good.</p>
                    </b-col>
                    <b-col sm="3" cols="12" class="text-right">
                        <img class="img-p-2" src="/images/modals/session-tips/ui-4.png">
                    </b-col>
                </b-row>
                <b-row class="paragraph">
                    <b-col sm="2" cols="12" class="number-col">
                        <div class="number-wrapper">
                            <div class="number-badge">5</div>
                        </div>
                    </b-col>
                    <b-col sm="5" cols="12">
                        <p class="h3">If you see this, your camera and mic are either muted, or Chrome doesn’t have permission to use them.</p>
                    </b-col>
                    <b-col sm="3" cols="12" class="text-right">
                        <img class="img-p-2" src="/images/modals/session-tips/ui-5.png">
                    </b-col>
                </b-row>
                <b-row class="paragraph">
                    <b-col>
                        <p class="h4 text-center sub-note">	If you have difficulties during the session, try reloading this page – most playback issues can be corrected that way. Tech support is available through the intercom button in the lower right corner of this page.</p>
                    </b-col>
                </b-row>
            </div>

        </div>
    </b-modal>
</template>

<script>

    import env from '../../../configuration/env'

    export default {
        name: 'SessionTipsModal',
        data() {
            return {
                shown: false,
                env: env,
            }
        },
        methods: {
            shownModal() {
                setTimeout(() => {
                    this.shown = true;
                }, 400);
            },
            onModalHide() {
                this.shown = false;
            },
            closeModal() {
                this.$root.$emit('bv::hide::modal', 'session-tips-modal')
            },
        },
    }
</script>