import * as types from '../types'
import { api } from "@/services";

const state = {
  session: null,
  streams: [],
  removeStreams: [],
  streamsOnMainView: [],
  chat: [],
  handUp: [],
  audience: [],
  audioAutoplayFailed: [],
  fullScreenMode: false,
  initStreamProfile: false,
};

const getters = {
  session: state => state.session,
  chat: state => state.chat,
  handUp: state => state.handUp,
  streams: state => state.streams,
  removeStreams: state => state.removeStreams,
  audience: state => state.audience,
  audienceByUid: state => (uid) => { return state.audience.find(item => item.uid == uid); },
  audioAutoplayFailed: state => !!state.audioAutoplayFailed.length,
  isStreamLive: state => ['STARTED'].includes(state.session.status),
  fullScreenMode: state => state.fullScreenMode,
  initStreamProfile: state => state.initStreamProfile,
  streamsOnMainView: state => state.streamsOnMainView,
  isStreamOnMainView: state => (uid) => { return state.streamsOnMainView.find(x => x == uid); },
};

const mutations = {
  [types.SET_SESSION](state, value) {
    state.session = value;
  },
  [types.SET_SESSION_STATUS](state, value) {
    state.session.status = value;
  },
  [types.ADD_CHAT_MESSAGE](state, msg) {
    state.chat.push(msg);
  },
  [types.ADD_HAND_UP](state, msg) {
    const foundIdx = state.handUp.findIndex(x => x.uid === msg.uid);
    if (foundIdx !== -1) {
      return;
    }
    state.handUp.push(msg);
  },
  [types.REMOVE_HAND_UP](state, msg) {
    const foundIdx = state.handUp.findIndex(x => x.uid === msg.uid);
    if (foundIdx === -1) {
      return;
    }
    state.handUp.splice(foundIdx, 1);
  },
  [types.CLEAR_HAND_UP](state) {
    state.handUp = [];
  },
  [types.ADD_STREAM](state, stream) {
    state.streams.push(stream);
  },
  [types.REMOVE_STREAM](state, uid) {
    const foundIdx = state.streams.findIndex(x => x.getId() === uid);
    if (foundIdx === -1) {
      return;
    }
    state.streams.splice(foundIdx, 1);
  },
  [types.ADD_STREAM_TO_REMOVE](state, uid) {
    state.removeStreams.push(uid);
  },
  [types.REMOVE_STREAM_FROM_REMOVE_STREAMS](state, uid) {
    const foundIdx = state.removeStreams.findIndex(x => x === uid);
    if (foundIdx === -1) {
      return;
    }
    state.removeStreams.splice(foundIdx, 1);
  },
  [types.CLEAR_STREAMS](state) {
    state.removeStreams = state.streams.map(x => x.getId());
    state.streams = [];
  },
  [types.ADD_STREAM_TO_MAIN_VIEW](state, uid) {
    state.streamsOnMainView.push(uid);
  },
  [types.REMOVE_STREAM_FROM_MAIN_VIEW](state, uid) {
    const foundIdx = state.streamsOnMainView.findIndex(x => x === uid);
    if (foundIdx === -1) {
      return;
    }
    state.streamsOnMainView.splice(foundIdx, 1);
  },
  [types.CLEAR_STREAMS_ON_MAIN_VIEW](state) {
    state.streamsOnMainView = [];
  },
  [types.SET_STREAMS_ON_MAIN_VIEW](state, items) {
    state.streamsOnMainView = items;
  },
  [types.ADD_AUDIENCE](state, user) {
    const foundIdx = state.audience.findIndex(x => x.uid == user.uid);
    if (foundIdx === -1) {
      state.audience.push(user);
    }
  },
  [types.SET_FULLSCREEN_MODE](state, mode) {
    state.fullScreenMode = mode;
  },
  [types.SET_INIT_STREAM_PROFILE](state, config) {
    state.initStreamProfile = config;
  },
  [types.ADD_AUDIO_AUTOPLAY_FAILED](state, uid) {
    const foundIdx = state.audioAutoplayFailed.findIndex(x => x == uid);
    if (foundIdx === -1) {
      state.audioAutoplayFailed.push(uid);
    }
  },
  [types.REMOVE_AUDIO_AUTOPLAY_FAILED](state, uid) {
    state.audioAutoplayFailed = state.audioAutoplayFailed.filter(x => x != uid);
  },
  [types.CLEAR_AUDIO_AUTOPLAY_FAILED](state) {
    state.audioAutoplayFailed = [];
  },
  [types.SET_HAND_UP_LIST](state, payload) {
    state.handUp = payload;
  },
};

const actions = {
  initSession: ({ commit }, courseId) => {
    return api.courses.getOne(courseId).then(({ data }) => {
      commit(types.SET_SESSION, data.item);
      return data.item;
    })
  },
  setSession: ({ commit }, course) => {
    commit(types.SET_SESSION, course);
  },
  setSessionStatus: ({ commit }, status) => {
    commit(types.SET_SESSION_STATUS, status);
  },
  addChatMessage: ({ commit }, msg) => {
    commit(types.ADD_CHAT_MESSAGE, msg);
  },
  addHandUp: ({ commit }, msg) => {
    commit(types.ADD_HAND_UP, msg);
  },
  setHandUpList: ({ commit }, payload) => {
    commit(types.SET_HAND_UP_LIST, payload);
  },
  removeHandUp: ({ commit }, msg) => {
    commit(types.REMOVE_HAND_UP, msg);
  },
  addStream: ({ commit, state }, stream) => {
    const existingIndex = state.streams.findIndex(s => s.getId() === stream.getId());
    if (existingIndex !== -1) {
      state.streams[existingIndex] = state.streams[existingIndex].merge(stream);
    } else {
      commit(types.ADD_STREAM, stream);
    }
  },
  removeStream: ({ commit }, uid) => {
    commit(types.REMOVE_STREAM, uid);
    commit(types.ADD_STREAM_TO_REMOVE, uid);
    commit(types.REMOVE_HAND_UP, { uid: uid });
    commit(types.REMOVE_STREAM_FROM_MAIN_VIEW, uid);
  },
  removeStreamsFromRemove: ({ commit }, uids) => {
    for (let uid of uids) {
      commit(types.REMOVE_STREAM_FROM_REMOVE_STREAMS, uid);
    }
  },
  clearStreams: ({ commit }) => {
    commit(types.CLEAR_STREAMS);
    commit(types.CLEAR_HAND_UP);
    commit(types.CLEAR_STREAMS_ON_MAIN_VIEW);
  },
  addStreamToMainView: ({ commit }, uid) => {
    commit(types.ADD_STREAM_TO_MAIN_VIEW, uid);
  },
  removeStreamFromMainView: ({ commit }, uid) => {
    commit(types.REMOVE_STREAM_FROM_MAIN_VIEW, uid);
  },
  clearStreamsOnMainView: ({ commit }) => {
    commit(types.CLEAR_STREAMS_ON_MAIN_VIEW);
  },
  setStreamsOnMainView: ({ commit }, items) => {
    commit(types.SET_STREAMS_ON_MAIN_VIEW, items);
  },
  addAudience: ({ commit }, user) => {
    commit(types.ADD_AUDIENCE, user);
  },
  setFullscreenMode: ({ commit }, value) => {
    commit(types.SET_FULLSCREEN_MODE, value);
  },
  setInitStreamProfile: ({ commit }, config) => {
    commit(types.SET_INIT_STREAM_PROFILE, config);
  },
  addAudioAutoplayFailed: ({ commit }, uid) => {
    commit(types.ADD_AUDIO_AUTOPLAY_FAILED, uid);
  },
  removeAudioAutoplayFailed: ({ commit }, uid) => {
    commit(types.REMOVE_AUDIO_AUTOPLAY_FAILED, uid);
  },
  clearAudioAutoplayFailed: ({ commit }) => {
    commit(types.CLEAR_AUDIO_AUTOPLAY_FAILED);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}
