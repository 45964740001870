import {
    isAllowedByRoles,
    isAllowedByTopRolesOrSubscribedDefault,
    isAnonymous,
    isAuthenticated, isDeniedForInstructors,
    isEmailVerified,
} from "./conditions";
import {api, security} from "../../services";
import Vue from "vue";

export const allowAnonymous = () => {
    return isAnonymous() ? null : {name: 'home'};
};

export const allowAuthenticated = () => {
    return isAuthenticated() ? null : {name: 'login'};
};

export const allowEmailVerified = () => {
    if (isEmailVerified()) {
        return null;
    }
    return recheckEmailVerified().then((path) => {
        return path;
    }).catch(() => {
        return {name: 'verification-email'};
    })
};

export const allowByRoles = (roles) => {
    return isAllowedByRoles(roles) ? null : {name: 'access-denied'};
};

export const allowByTopRolesOrSubscribed = () => {
    return isAllowedByTopRolesOrSubscribedDefault() ? null : {name: 'get-started'};
};
export const denyForInstructors= () => {
    return isDeniedForInstructors() ? {name: 'account-settings'} : null;
};

const recheckEmailVerified = () => {
    return api.auth.me().then(({data}) => {
        const user = security.setUserResponse(data);
        if (user && user.isEmailVerified) {
            Vue.notify({type: 'success', text: `Email verified successfully`});
            return {name: 'upcoming'};
        } else {
            return {name: 'verification-email'};
        }
    });
}
