<template>
  <div class="verification-email">
    <div v-if="showBanner">
      <div class="text-center mt-120 mb-120">
        <p class="h4">Please, check your email and verify your address. If you don’t receive the verification email within a few minutes, please check your spam folder. If necessary, click <a v-on:click="resendVerification"><u>here</u></a> to resend the email</p>
      </div>
    </div>
  </div>
</template>

<script>

  import {api} from '@/services'
  import {mapGetters} from "vuex";
  import {security} from "../../../services";

  export default {
    name: 'VerificationEmail',
    data() {
      return {
        showBanner: false
      };
    },
    computed: {
      ...mapGetters([
        'user',
      ]),
    },
    methods: {
      verifyEmail(email, token) {
        if (!email || !token) {
          if (this.user) {
            if (!this.user.isEmailVerified) {
              this.showBanner = true;
            } else {
              this.$router.replace({name: 'home'});
            }
          } else {
            this.$router.replace({name: 'login'});
          }
          return;
        }
        email = decodeURI(email);
        api.verification.email({email, token}).then(() => {
          this.$notify({type: 'success', text: `Email verified successfully`});
          if (this.user) {
            api.auth.me().then(({data}) => {
              security.setUserResponse(data);
              this.$router.replace({name: 'upcoming'});
            })
          } else {
            return this.$router.replace({name: 'login'});
          }
        }).finally(() => {
          if (this.user) {
            this.showBanner = true;
          } else {
            return this.$router.replace({name: 'home'});
          }
        })
      },
      resendVerification() {
        if (!this.user) {
          return;
        }
        api.verification.emailResend().then(() => {
          this.$notify({type: 'success', text: `Verification email sent`});
        });
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.verifyEmail(to.query.email, to.query.token);
      });
    }
  }
</script>
